import { doc, onSnapshot } from "firebase/firestore";
import React, { useRef, useContext, useEffect, useState } from "react";
import { ChatContext } from "../../context/ChatContext";
import { db } from "../../firebase";
import Message from "./Message";
import {AnswerRequesting} from "./Input"
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { createChatId } from "../../helpers";
const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [chatID, setChatID] = useState("");
  const { requesting, setRequesting } = useContext(ChatContext);
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const char = queryParams.get("char");
  const messagesEndRef = useRef(null);
  const typingWrapperRef = useRef(null);
  // Get sessionId; it will either be currentUser's UID or tempSessionId
  const sessionId = currentUser ? currentUser.uid : localStorage.getItem('tempSessionId');
// Helper function to format the date
// Helper function to format the date
// Helper function to convert Firebase Timestamp to JavaScript Date object
const timestampToDate = (timestamp) => {
  return timestamp.toDate();
};
useEffect(() => {
  if (requesting) {
    typingWrapperRef.current?.scrollIntoView({ behavior: "smooth" });
  }
}, [requesting]);
const formatDate = (firebaseTimestamp) => {
  const date = timestampToDate(firebaseTimestamp);
  const now = new Date();
  let formattedDate = date.toLocaleDateString();

  // Optionally, format today's and yesterday's dates differently
  if (formattedDate === now.toLocaleDateString()) {
    formattedDate = 'Today';
  } else {
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);

    if (formattedDate === yesterday.toLocaleDateString()) {
      formattedDate = 'Yesterday';
    }
  }

  return formattedDate;
};

// Function to determine if the current message is the first message of a new day
const isFirstMessageOfDay = (currentMessage, previousMessage) => {
  if (!previousMessage) return true; // If there's no previous message, this is the first message

  const currentDate = timestampToDate(currentMessage.date).setHours(0, 0, 0, 0);
  const previousDate = previousMessage ? timestampToDate(previousMessage.date).setHours(0, 0, 0, 0) : 0;

  return currentDate > previousDate;
};

  useEffect(() => {
    if (char && sessionId) {  // Use sessionId instead of currentUser
      console.log(char);
      const combinedId = createChatId(sessionId, char)
      setChatID(combinedId);

      const unSub = onSnapshot(doc(db, "chats", combinedId), (doc) => {
        doc.exists() && setMessages(doc.data().messages);
      });

      return () => {
        unSub();
      };
    }
  }, [char, requesting, sessionId]);  // Use sessionId instead of currentUser

  return (
    <>
      <div className="messages" ref={messagesEndRef}>
      {messages.map((m, index) => {
  const showDate = isFirstMessageOfDay(m, messages[index - 1]);
  const showTopic = !!m.topic; // The double-bang (!!) converts a truthy or falsy value to a boolean
  const isLastMessage = index === messages.length - 1; // Check if the message is the last one

  return (
    <React.Fragment key={m.id}> {/* React.Fragment with a key */}
      {showDate && (
        <div className="date-divider">
          <span className="date-text">{formatDate(m.date)}</span>
        </div>
      )}
      {showTopic && (
        <div className="topic-divider">
          <span className="topic-text">{m.topic}</span>
        </div>
      )}
      <Message 
        message={m} 
        key={m.id} 
        chatID={chatID} 
        character={char} 
        isLastMessage={isLastMessage}
      />
    </React.Fragment>
  );
})}
        {requesting ? (
          <div className="typingWrapper" ref={typingWrapperRef}>
  <div className="typingLoader"></div>
</div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default Messages;
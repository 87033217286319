import React, { useState, useContext, useEffect } from 'react';
import '../styles/EditYourWaifu.scss';
import { Select, Spin, message } from 'antd';
import { Modal, Button } from 'antd';
import { useNavigate,  Redirect, useParams, useLocation} from "react-router-dom";
import { getFirestore, doc, setDoc, updateDoc, getDoc } from "firebase/firestore"
import { logEvent, db, AuthErrors, logGemPurchase } from "../firebase";
import { v4 as uuid } from "uuid";
import { getStableDiffusionImage } from '../stableDiffusionConnector';
import { AuthContext } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import gem from "../img/gem.png";
import { ChatContext } from '../context/ChatContext';
import NoGemsModal from "../components/NoGemsModal";
import GemCounter from '../components/Common/GemCounter';
import SubscriptionModal from '../components/Chat/SubscriptionModal';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;

const keywordGroups = [
    {
        title: "Base",
        keywords:     [
            { title: 'woman', tagId: 'base_woman_default' },
            { title: 'model', tagId: 'base_model' },
            { title: 'miss universe model', tagId: 'base_miss_universe' },
            { title: 'milf', tagId: 'base_milf' },
            { title: 'celebrity', tagId: 'base_celebrity' },
            { title: 'lingerie model', tagId: 'base_lingerie_model', subscription: true, gemPrice: 2500, locked: true },
            { title: 'athlete', tagId: 'base_athlete' },
            { title: 'bodybuilder', tagId: 'base_bodybuilder', subscription: true, gemPrice: 3000 },
            { title: 'cyborg', tagId: 'base_cyborg', subscription: true, gemPrice: 7000 },
            { title: 'sorority', tagId: 'base_sorority', subscription: true, gemPrice: 3900 },
            { title: 'bimbo', tagId: 'base_bimbo', subscription: true, gemPrice: 2700 },
            { title: 'woman + man', tagId: 'base_man_woman', subscription: true, gemPrice: 6300 },
            { title: 'man', tagId: 'base_man', subscription: true, gemPrice: 3500 },
            { title: 'femboy', tagId: 'base_young_boy', subscription: true, gemPrice: 7500 },
            { title: 'furry', tagId: 'base_furry', subscription: true, gemPrice: 9500 },
        ],
    },
    {
        title: "Body",
        keywords: [
            { title: 'busty', tagId: 'tags_busty', subscription: true, gemPrice: 2500 },
            { title: 'huge boobs', subscription: true, gemPrice: 2000 },
            { title: 'perfect boobs', tagId: 'tags_perfect_boobs', subscription: true, gemPrice: 4000, locked: true },
            { title: 'small tits', tagId: 'tags_small_tits', subscription: true, gemPrice: 2000 },
            { title: 'beautiful', tagId: 'tags_beautiful' },
            { title: 'glasses', tagId: 'tags_glasses' },
            { title: 'catears', tagId: 'base_catears', subscription: true, gemPrice: 5000 },
            { title: 'sunglasses', tagId: 'tags_sunglasses' },
            { title: 'tattoos', tagId: 'tags_tattoo', subscription: true, gemPrice: 3000 },
            { title: 'lipstick', tagId: 'tags_lipstick' },
            { title: 'muscular', subscription: true },
            { title: 'big ass', tagId: 'tags_big_ass', subscription: true, gemPrice: 2000 },
            { title: 'small ass', tagId: 'tags_small_ass', subscription: true, gemPrice: 2000 },
            { title: 'skinny', tagId: 'tags_skinny' },
            { title: 'abs', tagId: 'tags_abs', subscription: true, gemPrice: 2900 },
            { title: 'thick', tagId: 'tags_thick', subscription: true, gemPrice: 2500 },
            { title: 'chubby', tagId: 'tags_chubby', subscription: true, gemPrice: 2000 },
            { title: 'fat', tagId: 'tags_fat', subscription: true, gemPrice: 3900 },
            { title: 'big hips', tagId: 'tags_big_hips' },
            { title: 'long legs', tagId: 'tags_long_legs' },
            { title: 'short', tagId: 'tags_short' },
            { title: 'tall', tagId: 'tags_tall' },
            { title: 'perfect body', subscription: true, gemPrice: 4500 },
            { title: 'pubic hair', tagId: 'tags_pubic_hair', subscription: true, gemPrice: 3000, locked: true },
            { title: 'short hair', tagId: 'tags_short_hair' },
            { title: 'long hair', tagId: 'tags_long_hair' },
            { title: 'curly hair', tagId: 'tags_curly_hair' },
            { title: 'pregnant', tagId: 'tags_pregnant', subscription: true, gemPrice: 7800 },
            { title: 'tanned skin', tagId: 'tags_dark_skin' },
            { title: 'fairer skin', tagId: 'tags_fair_skin' },
            { title: 'dark skin', tagId: 'tags_darker_skin' },
            { title: 'oiled body', tagId: 'tags_oiled_body', subscription: true, gemPrice: 4500 },
        ],
    },
    {
        title: "Age",
        keywords:     [
            { title: '18', tagId: 'age_18', subscription: true, gemPrice: 3500 },
            { title: '20s', tagId: 'age_20s' },
            { title: '30s', tagId: 'age_30s' },
            { title: '40s', tagId: 'age_40s' },
            { title: '50s', tagId: 'age_50s', subscription: true, gemPrice: 1000 },
            { title: '60s', tagId: 'age_60s' },
            { title: '70s', tagId: 'age_70s', subscription: true, gemPrice: 2500 },
            { title: '80s', tagId: 'age_80s', subscription: true, gemPrice: 4000 },
        ],
    },
    {
        title: "Face",
        keywords:     [
            { title: 'happy', tagId: 'face_happy' },
            { title: 'sad', tagId: 'face_sad' },
            { title: 'serious', tagId: 'face_serious' },
            { title: 'laughing', tagId: 'face_laughing' },
            { title: 'orgasm', tagId: 'face_orgasm', subscription: true, gemPrice: 3500, locked: true },
            { title: 'seductive', tagId: 'face_seductive', subscription: true, gemPrice: 2000 },
            { title: 'pouting lips', tagId: 'face_pouting' },
            { title: 'shocked', tagId: 'face_shocked' },
            { title: 'angry', tagId: 'face_angry' },
            { title: 'ahegao', tagId: 'face_ahegao', subscription: true, gemPrice: 4000 },
        ],
    },
    {
        title: "Hair Color",
        keywords:     [
            { title: 'blonde', tagId: 'tags_blonde' },
            { title: 'brunette', tagId: 'tags_brunette' },
            { title: 'ginger', tagId: 'tags_ginger' },
            { title: 'white hair', tagId: 'tags_white_hair' },
            { title: 'black hair', tagId: 'tags_black_hair' },
            { title: 'blue hair', tagId: 'tags_blue_hair', subscription: true, gemPrice: 700 },
            { title: 'green hair', tagId: 'tags_green_hair', subscription: true, gemPrice: 700 },
            { title: 'purple hair', tagId: 'tags_purple_hair', subscription: true, gemPrice: 700 },
            { title: 'pink hair', tagId: 'tags_pink_hair', subscription: true, gemPrice: 700 },
        ],
    },
    {
        title: "Hair Style",
        keywords:     [
            { title: 'bobcut', tagId: 'tags_bob_haircut' },
            { title: 'pigtails', tagId: 'tags_pigtails_haircut' },
            { title: 'hair bun', tagId: 'tags_hair_bun' },
            { title: 'pixie', tagId: 'tags_pixie_haircut' },
            { title: 'ponytail', tagId: 'tags_ponytail_haircut' },
            { title: 'messy', tagId: 'tags_messy_hair' },
            { title: 'bangs', tagId: 'tags_bangs_hair' },
            { title: 'braided', tagId: 'tags_braided_hair' },
            { title: 'slicked', tagId: 'tags_slick_haircut' },
            { title: 'straight', tagId: 'tags_straight_hair' },
        ],
    },
    {
        title: "Ethnicity",
        keywords:     [
            { title: 'african', tagId: 'tags_african' },
            { title: 'arabic', tagId: 'tags_arabic' },
            { title: 'asian', tagId: 'tags_asian' },
            { title: 'black', tagId: 'tags_black' },
            { title: 'brazilian', tagId: 'tags_brazilian' },
            { title: 'british', tagId: 'tags_british' },
            { title: 'chinese', tagId: 'tags_chinese' },
            { title: 'czech', tagId: 'tags_czech' },
            { title: 'dutch', tagId: 'tags_dutch' },
            { title: 'egyptian', tagId: 'tags_egyptian' },
            { title: 'ethiopian', tagId: 'tags_ethiopian' },
            { title: 'filipina', tagId: 'tags_filipina' },
            { title: 'french', tagId: 'tags_french' },
            { title: 'german', tagId: 'tags_german' },
            { title: 'greek', tagId: 'tags_greek' },
            { title: 'hungarian', tagId: 'tags_hungarian' },
            { title: 'indian', tagId: 'tags_indian' },
            { title: 'indonesian', tagId: 'tags_indonesian' },
            { title: 'irish', tagId: 'tags_irish' },
            { title: 'italian', tagId: 'tags_italian' },
            { title: 'japanese', tagId: 'tags_japanese' },
            { title: 'jewish', tagId: 'tags_jewish' },
            { title: 'korean', tagId: 'tags_korean' },
            { title: 'latina', tagId: 'tags_mexican' },
            { title: 'malaysian', tagId: 'tags_malaysian' },
            { title: 'middle eastern', tagId: 'tags_middle_eastern' },
            { title: 'mongolian', tagId: 'tags_mongolian' },
            { title: 'native american', tagId: 'tags_native_american' },
            { title: 'nigerian', tagId: 'tags_nigerian' },
            { title: 'nilotic', tagId: 'tags_nilotic' },
            { title: 'persian', tagId: 'tags_persian' },
            { title: 'polynesian', tagId: 'tags_polynesian' },
            { title: 'portuguese', tagId: 'tags_portuguese' },
            { title: 'russian', tagId: 'tags_russian' },
            { title: 'scandinavian', tagId: 'tags_scandinavian' },
            { title: 'spanish', tagId: 'tags_spanish' },
            { title: 'swedish', tagId: 'tags_swedish' },
            { title: 'thai', tagId: 'tags_thai' },
            { title: 'turkish', tagId: 'tags_turkish' },
            { title: 'vietnamese', tagId: 'tags_vietnamese' },
            { title: 'white', tagId: 'tags_white' }
        ],
    },
    {
        title: "Style",
        keywords:     [
            { title: 'mirror selfie', tagId: 'style_mirror_selfie' },
            { title: 'painting', tagId: 'style_artistic' },
            { title: 'black and white', tagId: 'style_black_and_white' },
            { title: 'vintage', tagId: 'style_vintage' },
            { title: 'film photo', tagId: 'style_film' },
            { title: 'soft anime', tagId: 'style_anime' },
            { title: 'crisp anime', tagId: 'style_crisp_anime' },
            { title: 'soft + warm', tagId: 'style_soft_warm' },
            { title: 'illustration', tagId: 'style_illustration' },
            { title: 'dark fantasy', tagId: 'style_fantasy' },
            { title: 'warm anime', tagId: 'style_warm_anime' },
            { title: 'cyberpunk', tagId: 'style_cyberpunk', subscription: true, gemPrice: 1500 },
            { title: 'skin detail (beta)', tagId: 'style_skin_detail' },
            { title: 'charcoal', tagId: 'style_charcoal' },
            { title: '3d', tagId: 'style_3d', subscription: true, gemPrice: 1000 },
            { title: 'watercolor', tagId: 'style_watercolor' },
            { title: 'comic', tagId: 'style_comic', subscription: true, gemPrice: 2000 }
        ],
    },
    {
        title: "Setting",
        keywords:     [
            { title: 'bar', tagId: 'env_bar' },
            { title: 'bathroom', tagId: 'env_bathroom', subscription: true, gemPrice: 1000 },
            { title: 'beach', tagId: 'env_beach' },
            { title: 'bedroom', tagId: 'env_bedroom' },
            { title: 'bus', tagId: 'env_bus' },
            { title: 'cafe', tagId: 'env_cafe' },
            { title: 'car', tagId: 'env_car' },
            { title: 'casino', tagId: 'env_casino' },
            { title: 'cave', tagId: 'env_cave' },
            { title: 'changing room', tagId: 'env_changing_room', subscription: true, gemPrice: 1500 },
            { title: 'church', tagId: 'env_church', subscription: true, gemPrice: 2500 },
            { title: 'club', tagId: 'env_club' },
            { title: 'couch', tagId: 'env_couch' },
            { title: 'desert', tagId: 'env_desert' },
            { title: 'forest', tagId: 'env_forest', subscription: true, gemPrice: 1000 },
            { title: 'grocery', tagId: 'env_grocery' },
            { title: 'gym', tagId: 'env_gym' },
            { title: 'hospital', tagId: 'env_hospital', subscription: true, gemPrice: 3000 },
            { title: 'hot tub', tagId: 'env_hot_tub', subscription: true, gemPrice: 3500 },
            { title: 'jungle', tagId: 'env_jungle' },
            { title: 'kitchen', tagId: 'env_kitchen' },
            { title: 'lake', tagId: 'env_lake' },
            { title: 'locker room', tagId: 'env_locker_room', subscription: true, gemPrice: 1500 },
            { title: 'mall', tagId: 'env_mall' },
            { title: 'meadow', tagId: 'env_meadow' },
            { title: 'moon', tagId: 'env_space' },
            { title: 'mountains', tagId: 'env_mountains' },
            { title: 'oasis', tagId: 'env_oasis' },
            { title: 'office', tagId: 'env_office' },
            { title: 'onsen', tagId: 'env_onsen', subscription: true, gemPrice: 3500 },
            { title: 'party', tagId: 'env_party' },
            { title: 'pool', tagId: 'env_pool' },
            { title: 'prison', tagId: 'env_prison', subscription: true, gemPrice: 4000 },
            { title: 'restaurant', tagId: 'env_restaurant' },
            { title: 'sauna', tagId: 'env_sauna', subscription: true, gemPrice: 3000 },
            { title: 'shower', tagId: 'env_shower', subscription: true, gemPrice: 2500 },
            { title: 'snow', tagId: 'env_snow' },
            { title: 'stage', tagId: 'env_stage' },
            { title: 'street', tagId: 'env_street' },
            { title: 'strip club', tagId: 'env_strip_club', subscription: true, gemPrice: 4500 },
            { title: 'tent', tagId: 'env_tent' },
            { title: 'train', tagId: 'env_train' },
            { title: 'underwater', tagId: 'env_underwater' },
            { title: 'wedding', tagId: 'env_wedding', subscription: true, gemPrice: 8000 },
            { title: 'yacht', tagId: 'env_yacht', subscription: true, gemPrice: 9000 }
        ],
    },
    {
        title: "View",
        keywords:     [
            { title: 'front view', tagId: 'view_front' },
            { title: 'side view', tagId: 'view_side' },
            { title: 'back view', tagId: 'view_back' },
            { title: 'close-up view', tagId: 'view_close' }
        ],
    },
    {
        title: "Action",
        keywords:     [
            { title: 'yoga', tagId: 'action_yoga' },
            { title: 'sleeping', tagId: 'action_sleeping' },
            { title: 'squatting', tagId: 'action_squatting' },
            { title: 'cooking', tagId: 'action_cooking' },
            { title: 'eating', tagId: 'action_eating' },
            { title: 'jumping', tagId: 'action_jumping' },
            { title: 'working out', tagId: 'action_exercise' },
            { title: 't-pose', tagId: 'action_t_pose' },
            { title: 'bathing', tagId: 'action_bathing' },
            { title: 'gaming', tagId: 'action_video_games' },
            { title: 'plank', tagId: 'action_plank' },
            { title: 'massage', tagId: 'action_massage', subscription: true, gemPrice: 5000 },
            { title: 'bending over', tagId: 'action_bending_over', subscription: true, gemPrice: 4500 },
            { title: 'spreading legs', tagId: 'action_spreading_legs', subscription: true, gemPrice: 4500, locked: true },
            { title: 'cumshot', tagId: 'action_cumshot', subscription: true, gemPrice: 5500, locked: true },
            { title: 'on back', tagId: 'action_on_back', subscription: true, gemPrice: 5000 },
            { title: 'straddling', tagId: 'action_straddling', subscription: true, gemPrice: 6500 },
            { title: 'wet', tagId: 'wet_body', subscription: true, gemPrice: 3500 },
            { title: 'wet pussy', tagId: 'dripping_pussy', subscription: true, gemPrice: 5500, locked: true },
            { title: 'lactation', tagId: 'lactation_breasts', subscription: true, gemPrice: 9500 },
            { title: 'thigh-highs', tagId: 'apparel_thigh_highs', subscription: true, gemPrice: 2000 },
{ title: 'garter belt', tagId: 'apparel_garter_belt', subscription: true, gemPrice: 4500 },
{ title: 'riding crop in hand', tagId: 'prop_riding_crop', subscription: true, gemPrice: 5500 },
{ title: 'nipple clamps', tagId: 'accessory_nipple_clamps', subscription: true, gemPrice: 6000, locked: true },
{ title: 'tied up', tagId: 'action_tied_up', subscription: true, gemPrice: 4000 },
{ title: 'collared', tagId: 'apparel_collar', subscription: true, gemPrice: 3500 },
{ title: 'piercings', tagId: 'accessory_piercings', subscription: true, gemPrice: 5000 },
{ title: 'blindfolded', tagId: 'apparel_blindfold', subscription: true, gemPrice: 2500 },
{ title: 'corset', tagId: 'apparel_corset', subscription: true, gemPrice: 4000 },
{ title: 'shibari ropes', tagId: 'apparel_shibari', subscription: true, gemPrice: 3500 },
{ title: 'cuffed wrists', tagId: 'action_cuffed', subscription: true, gemPrice: 5000 },
{ title: 'buttplug', tagId: 'accessory_buttplug', subscription: true, gemPrice: 7000, locked: true },
{ title: 'ball gag', tagId: 'accessory_ball_gag', subscription: true, gemPrice: 7500, locked: true },
{ title: 'fishnet stockings', tagId: 'apparel_fishnets', subscription: true, gemPrice: 4500 },
{ title: 'deep cleavage', tagId: 'trait_deep_cleavage', subscription: true, gemPrice: 3000 },
{ title: 'thong', tagId: 'apparel_thong', subscription: true, gemPrice: 3500, locked: true },
{ title: 'whipped cream', tagId: 'prop_whipped_cream', subscription: true, gemPrice: 4000 }

        ],
    },
    {
        title: "Clothing",
        keywords:     [
            { title: 'nude', tagId: 'clothes_nude_default', subscription: true, gemPrice: 4000, locked: true },
            { title: '60s', tagId: 'clothes_60s', subscription: true, gemPrice: 3500 },
            { title: '70s', tagId: 'clothes_70s' },
            { title: '80s', tagId: 'clothes_80s' },
            { title: '90s', tagId: 'clothes_90s' },
            { title: 'angel', tagId: 'clothes_angel' },
            { title: 'apron', tagId: 'clothes_apron' },
            { title: 'basketball', tagId: 'clothes_basketball' },
            { title: 'bathrobe', tagId: 'clothes_bathrobe' },
            { title: 'bdsm', tagId: 'clothes_bdsm', subscription: true, gemPrice: 4000, locked: true },
            { title: 'halloween', tagId: 'clothes_halloween_outfit' },
            { title: 'harlequin', tagId: 'clothes_harlequin', subscription: true, gemPrice: 4000 },
            { title: 'hat', tagId: 'clothes_hat' },
            { title: 'high heels', tagId: 'clothes_high_heels' },
            { title: 'high socks', tagId: 'clothes_high_socks' },
            { title: 'hijab', tagId: 'clothes_hijab', subscription: true, gemPrice: 4500 },
            { title: 'hip hop', tagId: 'clothes_hip_hop' },
            { title: 'jacket', tagId: 'clothes_jacket' },
            { title: 'jeans', tagId: 'clothes_jeans' },
            { title: 'jumpsuit', tagId: 'clothes_jumpsuit' },
            { title: 'kilt', tagId: 'clothes_kilt' },
            { title: 'kimono', tagId: 'clothes_kimono' },
            { title: 'lab coat', tagId: 'clothes_lab_coat' },
            { title: 'latex', tagId: 'clothes_latex', subscription: true, gemPrice: 3000 },
            { title: 'leather', tagId: 'clothes_leather' },
            { title: 'lingerie', tagId: 'clothes_lingerie', subscription: true, gemPrice: 1500, locked: true },
            { title: 'long skirt', tagId: 'clothes_long_skirt' },
            { title: 'lumberjack', tagId: 'clothes_lumberjack' },
            { title: 'maid', tagId: 'clothes_maid', subscription: true, gemPrice: 2500 },
        ],
    },
    {
        title: "Clothing Modifiers",
        keywords:     [
            { title: 'cleavage', tagId: 'clothes_cleavage' },
            { title: 'partially nude', tagId: 'clothes_partially_nude', subscription: true, gemPrice: 2500, locked: true },
            { title: 'topless', tagId: 'clothes_topless', subscription: true, gemPrice: 3000, locked: true },
            { title: 'transparent', tagId: 'clothes_transparent', subscription: true, gemPrice: 3500, locked: true }
        ],
    },
    {
        title: "Accessories/ Effects",
        keywords:     [
            { title: 'beer', tagId: 'tags_beer', subscription: true, gemPrice: 1000 },
            { title: 'diamond jewelry', tagId: 'tags_diamond_jewelry' },
            { title: 'gold jewelry', tagId: 'tags_gold_jewelry' },
            { title: 'jewelry', tagId: 'tags_jewelry' },
            { title: 'pearl jewelry', tagId: 'tags_pearl_jewelry' },
            { title: 'wine', tagId: 'tags_wine', subscription: true, gemPrice: 1000 }
        ],
    },
    {
        title: "Advanced Style",
        keywords:     [
            { title: 'beer', tagId: 'tags_beer', subscription: true, gemPrice: 1000 },
            { title: 'diamond jewelry', tagId: 'tags_diamond_jewelry' },
            { title: 'gold jewelry', tagId: 'tags_gold_jewelry' },
            { title: 'jewelry', tagId: 'tags_jewelry' },
            { title: 'pearl jewelry', tagId: 'tags_pearl_jewelry' },
            { title: 'wine', tagId: 'tags_wine', subscription: true, gemPrice: 1000 }
        ],
    },
    {
        title: "Premium Body",
        keywords: [
            { title: 'diamond skin', tagId: 'tags_diamond_skin', subscription: true, gemPrice: 9500 },
            { title: 'reptile skin', tagId: 'tags_reptile_skin', subscription: true, gemPrice: 9500 },
            { title: 'alien skin', tagId: 'tags_alien_skin', subscription: true, gemPrice: 9500 },
            { title: 'golden hair', tagId: 'tags_golden_hair', subscription: true, gemPrice: 9500 },
            { title: 'golden skin', tagId: 'tags_golden_skin', subscription: true, gemPrice: 9500 },
            { title: 'ruby hair', tagId: 'tags_ruby_hair', subscription: true, gemPrice: 9500 },
            { title: 'ruby skin', tagId: 'tags_ruby_skin', subscription: true, gemPrice: 9500 },
            { title: 'emerald hair', tagId: 'tags_emerald_hair', subscription: true, gemPrice: 9500 },
            { title: 'emerald skin', tagId: 'tags_emerald_skin', subscription: true, gemPrice: 9500 },
            { title: 'saphire hair', tagId: 'tags_saphire_hair', subscription: true, gemPrice: 9500 },
            { title: 'saphire skin', tagId: 'tags_saphire_skin', subscription: true, gemPrice: 9500 },
            // ... more premium attributes
        ],
    }
];




const EditYourWaifu = () => {
    const [energyLevel, setEnergyLevel] = useState(10); // Default energy level
    const maxEnergy = 10; // Maximum energy level    
    const [imageURL, setImageURL] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [unlockedKeywords, setUnlockedKeywords] = useState([]);
    const [containerVisible, setContainerVisible] = useState(false);
    const [activeTab, setActiveTab] = useState("All")
    const [selectedStyle, setSelectedStyle] = useState("anime");
    const {currentUser, userData} = useContext(AuthContext)
    const {showMessageModal, setShowMessageModal} = useContext(ChatContext)
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectPage = queryParams.get("redirectPage");
    const [availableGenerations, setAvailableGenerations] = useState(0); // Default value
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [selectedPurchaseItem, setSelectedPurchaseItem] = useState(null);
    const KeywordGroup = ({ group, handleKeywordClick, selectedKeywords, activeGroup, unlockedKeywords }) => {
        return (
            <div id={group.title} className="keyword-group">
                <div 
                    className={`group-title ${activeGroup === group.title ? 'active-group-title' : ''} ${["Clothing Modifiers", "Accessories/ Effects", "Advanced Style"].includes(group.title) ? 'custom-title-position' : ''}`} 
                >
                    {group.title}
                </div>
                <div className="keywords-container">
                    {group.keywords.map((keyword, index) => {
                        const isSelected = selectedKeywords ? (selectedKeywords.some(item => item.title === keyword.title)) : (false);
                        const isUnlocked = unlockedKeywords.includes(keyword.title);
                        return (
                            <div 
                                key={index}
                                className={`text-base px-4 py-2 m-2 border rounded-lg text-white select-none relative cursor-pointer PPChip 
                                            ${isSelected ? 'bg-green-700' : ''} 
                                            ${isUnlocked ? 'bg-blue-700' : ''}`} // Different background for unlocked keywords
                                data-tagid={keyword.tagId}
                                onClick={() => handleKeywordClick(keyword)}
                            >
                            {keyword.locked  && userData && userData.subscription === "None" && (<FontAwesomeIcon icon={faLock} className='lockTag' />) }
                              { 
     /*
      keyword.gemPrice ? (
        <>
          <FontAwesomeIcon icon={faLock} className='lockTag' />
          <span> 
            {keyword.gemPrice} 
            <img src={gem} alt="" className='gemIconTag' />
          </span>
        </>
      ) :  null
    )*/ }
     {keyword.title} 
                        </div>
                    )})}
                </div>
            </div>
        );
    }

    useEffect(() => {
        if(!currentUser) return;
        const fetchUserGenerations = async () => {
            try {
                const userDoc = await getDoc(doc(db, "users", currentUser.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    // Set default generations based on subscription status
                    const defaultGenerations = userData.subscription && userData.subscription !== "None" ? 20 : 10;
                    setAvailableGenerations(userData.availableGenerations === undefined? defaultGenerations : userData.availableGenerations);
                } else {
                    console.log("No such user document!");
                }
            } catch (error) {
                console.error("Error fetching user data: ", error);
            }
        };
    
        fetchUserGenerations();
    }, [currentUser]);
// Inside your EditYourWaifu component
const styles = [
    { value: "anime", label: "Anime", cost: 0 },
    { value: "realistic", label: "Realistic", cost: 0 },
    { value: "anime-nostalgic", label: "Ultimate Anime", preview: "https://firebasestorage.googleapis.com/v0/b/oniichat-2c310.appspot.com/o/customstyleThumbnails%2FProfile%20Oniichat%20(22).png?alt=media&token=e9fb37ce-aeaf-409b-85fb-cb18dfc17a27", cost: 9500 },
    { value: "anime-cartoon-fusion", label: "Anime-Cartoon Fusion", preview: "https://firebasestorage.googleapis.com/v0/b/oniichat-2c310.appspot.com/o/customstyleThumbnails%2FProfile%20Oniichat%20(19).png?alt=media&token=091812a7-399f-4d85-b5df-b1d45b5cc9e7", cost: 9500 },
    { value: "cartoon", label: "Cartoon", preview: "https://firebasestorage.googleapis.com/v0/b/oniichat-2c310.appspot.com/o/customstyleThumbnails%2FProfile%20Oniichat%20(21).png?alt=media&token=d05901f6-7fc2-4b69-9f9a-ddf4d0328eea", cost: 9500 },
    { value: "ultra-realistic", label: "Ultimate Realistic", preview: "https://firebasestorage.googleapis.com/v0/b/oniichat-2c310.appspot.com/o/customstyleThumbnails%2FProfile%20Oniichat%20(23).png?alt=media&token=827038c8-e576-4d4c-af25-eff9a490d816", cost: 9500 }
];
const handleStyleChange = async (value) => {
    const selectedStyle = styles.find(style => style.value === value);
    if (!selectedStyle) return;

    // Check if the style is free or already unlocked
    if (selectedStyle.cost === 0 || userData.unlockedStyles?.includes(value)) {
        setSelectedStyle(value);
        return;
    }

    // Handle gem deduction and style unlocking
    if (userData.gems >= selectedStyle.cost) {
        try {
            // Deduct gems and unlock the style
            const updatedGems = userData.gems - selectedStyle.cost;
            const updatedUnlockedStyles = [
                ...((userData && userData.unlockedStyles) ? userData.unlockedStyles : []), 
                value
            ];
            setSelectedStyle(value);
            // Update user data in Firestore (pseudo-code, replace with your Firestore update logic)
            await updateUserGemsAndStyles(currentUser.uid, updatedGems, updatedUnlockedStyles);

            message.success("Successfully purchased", value)
            // Update local state
            // Update userData state with new gems count and unlocked styles
        } catch (error) {
            console.error("Error purchasing style:", error);
            message.error("Error purchasing style:", error)
            // Handle error (e.g., show a message to the user)
        }
    } else {
        // Handle case where user doesn't have enough gems
        // Show a message or notification to the user
        console.log("You don't have enough gems. ")
        setShowWarning(true)
    }
};
async function updateUserGemsAndStyles(userId, updatedGems, updatedUnlockedStyles) {
    const userRef = doc(db, "users", userId);

    try {
        await updateDoc(userRef, {
            gems: updatedGems,
            unlockedStyles: updatedUnlockedStyles
        });
        console.log("User data updated successfully");
    } catch (error) {
        console.error("Error updating user data:", error);
        throw error; // Re-throw the error to be handled by the caller
    }
}
const StyleSelector = () => {
    return (
        <>
        {styles && (
            <div className="style-selector-container">
            <label htmlFor="style-selector" className="style-selector-label">Choose Your Style</label>
            <Select 
    id="style-selector"
    value={selectedStyle}
    onChange={handleStyleChange}
    style={{ /* Add styles for the Select component here */ }}
    className="style-selector"
>
    {styles.map(style => (
        <Option 
            key={style.value} 
            value={style.value} 
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '8px',
                borderBottom: '1px solid #eee',
                overflow: 'hidden',
                // Add any additional styles for Option here
            }}
        >
        {style.preview && (
            <img 
                src={style.preview} 
                alt={style.label} 
                style={{ 
                    width: '30px', 
                    height: '30px', 
                    objectFit: 'cover',
                    marginRight: '10px',
                    borderRadius: '4px',
                    // Add any additional styles for Image here
                }} 
            />)}
            <span 
                style={{ 
                    flexGrow: 1,
                    fontSize: '14px',
                    color: '#333',
                    // Add any additional styles for Label here
                }}
            >
                {style.label}
            </span>
            {userData && userData.unlockedStyles && userData.unlockedStyles.includes(style.value) ? 
                <span 
                    style={{
                        fontSize: '12px',
                        marginLeft: '10px',
                        padding: '2px 6px',
                        borderRadius: '12px',
                        color: 'white',
                        backgroundColor: '#4caf50',
                        // Add any additional styles for Unlocked Span here
                    }}
                >
                    Unlocked
                </span> : 
                (style.cost > 0 ? 
                    <span 
                        style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            padding: '2px 6px',
                            borderRadius: '12px',
                            color: 'white',
                            backgroundColor: '#f44336',
                            // Add any additional styles for Cost Span here
                        }}
                    >
                        {style.cost} gems
                    </span> : ""
                )
            }
        </Option>
    ))}
</Select>
        </div>
        )}
        </>
    );
};

    useEffect(() => {
        LoadSavedData()
        console.log("update data")
    },[userData])
    const handlePurchase = async (keyword) => {
        if (!keyword) return;
    
        // Check if the user has enough gems
        if (userData.gems >= keyword.gemPrice) {
            // Deduct the gem cost from the user's total gems
            const updatedUserGems = userData.gems - keyword.gemPrice;
    
            try {
                // Update the user's gem count in the database
                await updateDoc(doc(db, "users", currentUser.uid), {
                    gems: updatedUserGems,
                });
                logGemPurchase(currentUser.uid, keyword.gemPrice, "edit_your_waifu", keyword.title)
                // Add the keyword to the unlocked keywords
                const updatedUnlockedKeywords = userData.unlockedKeywords ? [...userData.unlockedKeywords, keyword.title] : [keyword.title];
                await updateDoc(doc(db, "users", currentUser.uid), {
                    unlockedKeywords: updatedUnlockedKeywords,
                });
    
                // Initialize selectedKeywords if it's not an array
                if (!Array.isArray(selectedKeywords)) {
                    setSelectedKeywords([keyword]);
                } else {
                    // Update the selected keywords
                    const updatedKeywords = [...selectedKeywords, keyword];
                    setSelectedKeywords(updatedKeywords);
                }
                setContainerVisible(true);
    
                // Show success message
                message.success(`Purchased "${keyword.title}" successfully`);
    
                // Close the modal
                setShowPurchaseModal(false);
    
            } catch (error) {
                // Handle any errors during the purchase process
                console.error("Error during purchase:", error);
                message.error("Error during purchase. Please try again.");
            }
        } else {
            // Handle the case where user doesn't have enough gems
            setShowWarning(true)
        }
    };
    const handleKeywordClick = async (keyword) => {
        const isKeywordSelected = selectedKeywords ? (selectedKeywords.some(item => item.title === keyword.title)) : (false);
        const isUnlocked = unlockedKeywords.includes(keyword.title);
        console.log(keyword.gemPrice)
        // If the keyword is already selected, remove it without any gem considerations
        if (isKeywordSelected) {
            const updatedKeywords = selectedKeywords.filter(item => item.title !== keyword.title);
            setSelectedKeywords(updatedKeywords);
            setContainerVisible(updatedKeywords.length > 0);
            return;
        }
        if (keyword.locked && userData.subscription === "None"){
            setShowSubscriptionModal(true)
            return
        }
        // If the keyword is not selected but has a gem price, check if the user has enough gems
        if (keyword.gemPrice && !isUnlocked && false) {
            if (userData.gems >= keyword.gemPrice) {
                setSelectedPurchaseItem(keyword);
                setShowPurchaseModal(true);
            } else {
                // Inform the user they don't have enough gems
                // This could be a modal, toast, or any other form of notification
                setShowWarning(true)
                console.log("Not enough gems to select this keyword.");
            }
        } else {
            // If the keyword doesn't have a gem price, add it to the selected keywords
            const updatedKeywords = [...(selectedKeywords || []), keyword];
            setSelectedKeywords(updatedKeywords);
            setContainerVisible(updatedKeywords.length > 0);
        }
    };
    
    const LoadSavedData = async () => {
        try {
          console.log(userData);
          if(!userData)
          {
            return
          }
          if (userData.unlockedKeywords) {
            // Assume setUnlockedKeywords is a state setter function
            setUnlockedKeywords(userData.unlockedKeywords);
          }
          if (!userData.customCharacter) {
            console.error("No custom character data available.");
            // You could show a user-friendly message here
            // or set some default states, then return.
            return;
          }
          
          const customCharacterDoc = await getDoc(doc(db, "customCharacters", userData.customCharacter));
          
          if (!customCharacterDoc.exists) {
            console.error("Custom character document does not exist.");
            // Handle this case similarly to above.
            return;
          }
          
          const customCharacter = customCharacterDoc.data();
          
          // Check if the customCharacter data is actually present
          if (!customCharacter) {
            console.error("Incomplete custom character data.");
            // Handle this case as well.
            return;
          }
          console.log("custom character", customCharacter)    
          // If you reached here, it's safe to assume that the data is complete.
          setSelectedKeywords(customCharacter.selectedKeywords);
          setSelectedStyle(customCharacter.selectedStyle ? (customCharacter.selectedStyle) : ("anime") )
          setImageURL(customCharacter.imageURL);
          setContainerVisible(true);
          
        } catch (error) {
          console.error("Error fetching custom character data:", error);
          // Handle the error appropriately here, perhaps by showing an error message to the user.
        }
      };
    const saveCharacterToFirebase = async (imageLink) => {
        console.log("======> imageURL", imageLink)
        const transformedSelectedKeywords = selectedKeywords.map(keyword => keyword.title);
        const characterData = {
          photoURL: imageLink,
          imageURL: imageLink,
          transformedSelectedKeywords: transformedSelectedKeywords,                                                                                                          
          selectedKeywords: selectedKeywords,
          selectedStyle: selectedStyle,
          // Add any other fields you want to save
        };
       console.log(characterData)
          const characterUID = userData.customCharacter ? (userData.customCharacter) : (uuid())
          try {
            await setDoc(doc(db, "customCharacters", characterUID), characterData, {merge: true});
            if(!userData.customCharacter)
            {
                await updateDoc(doc(db, "users", currentUser.uid), {customCharacter: characterUID});
            }
            console.log("Character data part successfully saved!");
            return Promise.resolve("Data saved successfully");
          } catch (error) {
            console.error("Error saving character data: ", error);
            return Promise.reject(error);
          }
      };
    const handleSaveOrContinue = async () => {
        try {
            await saveCharacterToFirebase(imageURL); // Wait for this to complete
            console.log("Image URL saved successfully.");

            if (redirectPage) {
                // If 'save' param is true, redirect to the URL specified in 'redirectPage' param
                navigate(`/${redirectPage}`);
            } else {
                // Otherwise, continue to the default next page
                navigate("/CreateWaifuPersonality");
            }
        } catch (error) {
            console.error("Error saving image URL:", error);
        }
    };
    const handleGenerate = async () => {
        if(isLoading) return;
        if(availableGenerations <= 0 && userData.subscription === "None")
        {
            setShowSubscriptionModal(true)
            return
        }
        if(!selectedKeywords || selectedKeywords.length === 0 )
        {
            message.warning("Please choose your desired traits to proceed!")
            return
        }
        setIsLoading(true); // Set loading state to true before generating the image
        console.log('Generating waifu with keywords:', selectedKeywords);
        // Extract the 'title' property from each keyword object and join them into a string
        const keywordString = selectedKeywords.map(keyword => keyword.title).join(", ");
        console.log(keywordString);
    
        try {
            console.log("selected style:", selectedStyle)
            const imageLink = await getStableDiffusionImage(keywordString, selectedStyle);
            setImageURL(imageLink); // Update the state with the new image URL
            await saveCharacterToFirebase(imageLink); // Wait for this to complete
            console.log("image link", imageLink)
        } catch (error) {
            console.error('An error occurred while generating the image:', error);
        }
        const newGenerationCount = availableGenerations - 1;
        setAvailableGenerations(newGenerationCount);
    
        // Update Firestore
        try {
            await updateDoc(doc(db, "users", currentUser.uid), {
                availableGenerations: newGenerationCount
            });
        } catch (error) {
            console.error("Error updating available generations:", error);
        }
        setIsLoading(false); // Set loading state to false after image generation
    };
    const Tab = ({ title}) => {
        return (
            <div 
                className={`tab ${activeTab === title ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(title)}
            >
                {title}
            </div>
        );
    };
    const handleBuyGenerations = async () => {
        // Check if the user has enough gems
        if (userData.gems >= 300) {
            try {
                logGemPurchase(currentUser.uid, 300, "edit_your_waifu", "add_generations")
                // Deduct 300 gems and add 10 generations
                const updatedGems = userData.gems - 300;
                const newGenerationCount = availableGenerations + 10;
    
                // Update Firestore
                await updateDoc(doc(db, "users", currentUser.uid), {
                    gems: updatedGems,
                    availableGenerations: newGenerationCount
                });
    
                // Update local state
                setAvailableGenerations(newGenerationCount);
                // Update userData state with new gems count, if applicable
    
                message.success("Purchased 10 generations successfully!");
            } catch (error) {
                console.error("Error in purchasing generations:", error);
                message.error("Error in transaction. Please try again.");
            }
        } else {
            setShowWarning(true)
        }
    };
    const handleBack = () => {
        // You can use 'navigate' from 'react-router-dom' if you're using routing
        navigate("/customProfilePage");
        console.log('Back button clicked');
      };
    return (
        <div className='TagMain'>
        <div className="backgroundGradient">
            <h1 className="waifu-title">Avatar Editor</h1>
            <button onClick={handleBack} className="back-button">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
            {userData && <GemCounter/>}
            <div className='TagContent'>
            <div className='TagSelector'>
           { userData && userData.subscription === "None" && <div className="available-generations-display">
    Daily Available Generations: {availableGenerations}
</div>}
{/*availableGenerations === 0 && (
<button className="buy-generations-button" onClick={handleBuyGenerations}>
    Buy 10 Generations for 300 Gems
</button>)
*/}
{selectedKeywords && (
            <div className={containerVisible ? "selected-keywords-container show-keywords-container" : "selected-keywords-container"}>
                {selectedKeywords.map((keyword, index) => (
                    <span 
                        key={index} 
                        className={`keyword selected`} 
                        onClick={() => handleKeywordClick(keyword)}
                    >
                        {keyword.title}
                    </span>
                ))}
            </div>)}
            {/*
            <StyleSelector selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle} />*/}
            <div className="tabs">
                <Tab 
                    title="All"
                />
                {keywordGroups.map((group, index) => (
                    <Tab 
                        key={index}
                        title={group.title}
                    />
                ))}
            </div>
                {keywordGroups.map((group, index) => (
                    (activeTab === 'All' || activeTab === group.title) && (
                        <KeywordGroup 
    key={index} 
    group={group}
    handleKeywordClick={handleKeywordClick}
    selectedKeywords={selectedKeywords}
    unlockedKeywords={unlockedKeywords} // Pass the state here
/>
                    )
                ))}
</div>
            <div className='TagSelector'>
            {/* Image Placeholder */}
            <div className={imageURL ? 'imagePlaceholder' : 'imagePlaceholder text-only'}>
    {isLoading ? (
        <Spin tip="Generating...">
        </Spin>
    ) : imageURL ? (
        <img src={imageURL} alt="Generated Waifu" />
    ) : (
        <span>Your Image Will Appear Here</span>
    )}
</div>
<Modal
    title="Purchase Confirmation"
    visible={showPurchaseModal}
    onCancel={() => setShowPurchaseModal(false)}
    footer={[
        <Button key="cancel" onClick={() => setShowPurchaseModal(false)}>
            Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={() => handlePurchase(selectedPurchaseItem)}>
            Confirm
        </Button>,
    ]}
>
    <p>
        Would you like to purchase {selectedPurchaseItem?.title} for {selectedPurchaseItem?.gemPrice}
        <img className="textgem" src={gem} alt="" />
    </p>
</Modal>
            
            <div className={(imageURL && !isLoading) ? ("generate-button-wrapper up") : ("generate-button-wrapper")}>
    <button className="generate-button" onClick={handleGenerate}>
        {isLoading ? <div className="spinner"></div> : "Generate"}
    </button>
</div>
            {(imageURL && !isLoading) && (
                <div className="generate-button-wrapper">
                    <button 
                        className="generate-button" 
                        onClick={handleSaveOrContinue}
                    >
                        {redirectPage ? "Save" : "Save"}
                    </button>
                </div>
            )}
            </div>
            </div>
        </div>
        <SubscriptionModal show={showSubscriptionModal} handleClose={() => setShowSubscriptionModal(false)} character={{photoURL:imageURL ? imageURL: ""}}/>
        <NoGemsModal 
  visible={showWarning} 
   onCancel={() => setShowWarning(false)} 
/>
        </div>
    );
};

export default EditYourWaifu;

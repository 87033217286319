import React, { useState, useContext, useEffect} from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import MainNav from '../Common/MainNav';
import { AuthContext } from "../../context/AuthContext";
import { signOut, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, GithubAuthProvider,reauthenticateWithPopup, linkWithPopup, EmailAuthProvider, fetchSignInMethodsForEmail, reauthenticateWithCredential, deleteUser} from "firebase/auth";
import { auth  } from "../../firebase";
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { logEvent, db } from "../../firebase";
import { updatePassword, sendPasswordResetEmail } from 'firebase/auth';
import {AuthErrors} from "../../firebase"
import { Card, Form, Input, Button, Modal, Alert, Select, Spin, message, Divider, Col, Collapse, Typography,Space, Row} from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import "../../styles/Settingspage.scss"
import SettingsCTA from "../../img/SettingsCTA.png"
import {SubscriptionPanel, getPlanPrice, getFeaturesBySubscription} from '../SubscriptionPanel';
import { 
  doc, 
  setDoc, 
  getDoc, 
  serverTimestamp, 
  Timestamp, 
  updateDoc, 
  collection, 
  addDoc, 
  deleteDoc
} from "firebase/firestore";
const { Panel } = Collapse;
const { Title, Text, Paragraph } = Typography;


const ReAuthModal = ({ visible, onSubmit, onCancel, title, message }) => {
  const { currentUser, userData } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const maxAttempts = 3;
  const timeoutHours = 3;
  const handleSubmit = async () => {
    setError(null); // Clear the previous error message
    if(!password || password.trim() === '') {
      setError('Password cannot be empty');
      return;
    }
    const storedData = JSON.parse(localStorage.getItem('reAuthData') || '{}');
    const { attempts, lastAttemptTime } = storedData;
    const currentTime = new Date().getTime();

    // Check if the user is still within the timeout window
    if (attempts >= maxAttempts && currentTime - lastAttemptTime < timeoutHours * 60 * 60 * 1000) {
      setError(`Maximum password attempts exceeded. Try again in ${timeoutHours} hours.`);
      return;
    }
    setLoading(true);
    try {
      const authProvider = EmailAuthProvider.credential(currentUser.email, password);
      await reauthenticateWithCredential(currentUser, authProvider);
      await onSubmit(); // Call onSubmit prop after successful re-authentication
      onCancel(); // Call onCancel prop to hide the modal
    } catch (error) {
      console.error(error);
      let errorMessage;
      if (error.code === 'auth/wrong-password') {
        errorMessage = 'Incorrect password. Please try again.';
        localStorage.setItem(
          'reAuthData',
          JSON.stringify({
            attempts: (attempts || 0) + 1,
            lastAttemptTime: currentTime,
          })
        );
      } else {
        errorMessage = AuthErrors[error.code] || 'An error occurred while re-authenticating. Please try again later.';
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  const handleGoogleSignIn = async () => {
    const storedData = JSON.parse(localStorage.getItem('reAuthData') || '{}');
    const { attempts, lastAttemptTime } = storedData;
    const currentTime = new Date().getTime();

    if (attempts >= maxAttempts && currentTime - lastAttemptTime < timeoutHours * 60 * 60 * 1000) {
      setError(`Maximum password attempts exceeded. Try again in ${timeoutHours} hours.`);
      return;
    }

    setError(null);
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      await reauthenticateWithPopup(currentUser, provider)
        .then((result) => {
          console.log(result.user);
        })
        .catch((error) => {
          console.error(error);
          throw error; // Throw the error so it can be caught in the catch block below
        });
      await onSubmit(); // Call onSubmit prop after successful re-authentication
      onCancel(); // Call onCancel prop to hide the modal
    } catch (error) {
      localStorage.setItem(
        'reAuthData',
        JSON.stringify({
          attempts: (attempts || 0) + 1,
          lastAttemptTime: currentTime,
        })
      );
      console.error(error);
      let errorMessage = AuthErrors[error.code] || 'An error occurred while re-authenticating with Google. Please try again later.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title={title || "Re-authenticate"}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      okText="Submit"
      cancelText="Cancel"
      confirmLoading={loading}
    >
      {message && <Alert message={message} type="info" showIcon />}
      {error && <Alert message={error} type="error" showIcon />}
      <Form layout="vertical">
        <Row gutter={16}>
          <Col xs={24} sm={24}>
            <Form.Item label="Password" required>
              <Input.Password 
                value={password} 
                onChange={e => setPassword(e.target.value)} 
                placeholder="Please enter your password for verification" 
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24}>
            <Button type="primary" onClick={handleGoogleSignIn}>Re-authenticate with Google</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
const SettingsPage = () => {
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(false);
const [copyStatus, setCopyStatus] = useState('Click to copy');
  const { currentUser, userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [showReAuthModalAccount, setShowReAuthModalAccount] = useState(false);
  const [showReAuthModalSubscription, setShowReAuthModalSubscription] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [emailForReset, setEmailForReset] = useState('');
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [showCancelWarningModal, setShowCancelWarningModal] = useState(false);
  const [calculatedFeatures, setCalculatedFeatures] = useState([]);
  const [isBestValue, setIsBestValue] = useState(false);
  const [saleValue, setSaleValue] = useState("");
  const [isAccountSettingsLoading, setAccountSettingsLoading] = useState(false);
  const [showServiceTermination, setShowServiceTermination] = useState(true);
  const [editMode, setEditMode] = useState(false); // To toggle edit mode
  const [username, setUsername] = useState(userData ? userData?.username : (null)); // User's username
  const [pronouns, setPronouns] = useState(userData ? userData?.pronouns : (null)); // User's pronouns
  const [preferences, setPreferences] = useState(userData ? userData?.preferences : (null)); // User's preferences
  const [isServiceTerminationLoading, setServiceTerminationLoading] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState('');
  const [showReAuthModalPass, setShowReAuthModalPass] = useState(false)
  const CancelWarningModal = ({ visible, onProceed, onCancel, subscription }) => {
    const { features, saleValue, isBestValue } = getFeaturesBySubscription(subscription);
  
    return (
      <Modal
        title={<Title level={2} style={{ color: '#203378' }}>Think Twice, Stay Wise</Title>}
        visible={visible}
        footer={[
          <Space>
            <Button key="cancel" danger onClick={onCancel}>
              Reconsider & Stay
            </Button>,
            <Button key="proceed" type="primary" onClick={onProceed}>
              Confirm Cancellation
            </Button>
          </Space>
        ]}
        onCancel={onCancel}
      >
        <Alert 
          message="Critical Choice Ahead" 
          description="Your decision will have immediate effects. Please read carefully."
          type="warning" 
          showIcon 
          icon={<i className="fas fa-exclamation-triangle" style={{ color: '#FF9800' }}></i>}
        />
        <Divider />
        <Title level={3} style={{ color: '#4CAF50' }}>What You Stand to Lose:</Title>
        <SubscriptionPanel 
          planName={subscription}
          planPrice={getPlanPrice(subscription)}
          currentPlan={subscription}
          onUpgradeClick={null}
          features={features}
          saleValue={saleValue}
          isBestValue={isBestValue}
        />

        <Divider />
        <Title level={3}>The Perks of Staying On:</Title>
        <Paragraph>
          <ul>
            <li><Text strong>Unlock full access to premium features until the end of your billing cycle.</Text></li>
            <li><Text strong>Be the first to experience new updates and special events.</Text></li>
          </ul>
        </Paragraph>
        <Divider />
        <Title level={3} style={{ color: '#f44336' }}>The Cost of Leaving Now:</Title>
        <Paragraph>
          <Text strong>Immediate termination of all premium features and benefits. Are you absolutely sure?</Text>
        </Paragraph>
      </Modal>
    );
  };
  
  
  
  
  const handleEmailCopy = () => {
    navigator.clipboard.writeText('support@oniichat.com')
      .then(() => {
        setCopyStatus('Copied!');
      })
      .catch(() => {
        setCopyStatus('Failed to copy');
      });
  };
  
  useEffect(() => {
    if (userData && userData.subscription) {
      const { features, isBestValue, saleValue } = getFeaturesBySubscription(userData.subscription);
      console.log("features: ", features)
      setCalculatedFeatures(features);
      setSaleValue(saleValue)
      setIsBestValue(isBestValue);
    }
  }, [userData]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowServiceTermination(false);
    }, 60000); // 30 seconds delay
  
    return () => clearTimeout(timer); // Cleanup timeout
  }, []);
  
  const handleCancelSubscription = async () => {
    // Fetch the user's data from Firestore
    const userDoc = await getDoc(doc(db, "users", currentUser.uid));
    const userData = userDoc.data();
  
    // Check if the user has a subscription
    if (!userData || userData.subscription === "None" || !userData.subscription) {
      // Display a message and return early if the user doesn't have a subscription
      message.warning("You don't have an active subscription to cancel.");
      return;
    }
  
    // If the user has a subscription, proceed to show the warning modal
    setShowCancelWarningModal(true);
  };
  // Logout function
  const handleLogout = async () => {
    localStorage.removeItem('tempSessionId')
    localStorage.setItem("loggedIn", "false")
    await signOut(auth);
    window.location.href = "/"; // Redirect the user to the login pages
  };
  const feedbackCollection = collection(db, "feedbacks");
  const handleFeedbackSubmit = async () => {
    try {
      // Add a new document with the feedback information
      await addDoc(feedbackCollection, {
        text: feedback,
        timestamp: serverTimestamp(),
        user_id: currentUser.uid
      });
      
      // You can then proceed to cancel the subscription
      await handleCancelSubscription();
  
      // Close the feedback modal
      setFeedbackModalVisible(false);
      
      // Optionally, you can show a success message
      message.success('Your feedback has been submitted successfully.');
    } catch (error) {
      console.error(error);
      // Optionally, you can show an error message
      message.error('An error occurred while submitting your feedback. Please try again later.');
    }
  };
  // Change password function
  const handleChangePassword = async () => {
    if (!newPassword || newPassword.length < 6) {
      message.error('Please enter a valid new password. Password should be at least 6 characters long.');
      return;
    }
    
    await updatePassword(currentUser, newPassword);
    setShowChangePasswordModal(false)
    message.success('Your password has been updated successfully');
  };

  // Reset password function
  const handleResetPassword = async () => {
    if (!emailForReset) {
      message.error('Please enter a valid email address');
      return;
    }

    await sendPasswordResetEmail(auth, emailForReset);
    message.success('A password reset link has been sent to your email');
  };
  
  const handleDeleteAccount = async () => {
    setLoading(true);
    try {
      await deleteAccount();
      message.success('Your account has been successfully deleted.');
    } catch (error) {
      message.error("You have entered the wrong password.");
    } finally {
      setLoading(false);
      setDeleteModalVisible(false);
    }
  };
  const cancelSubscription = async () => {
  
    if (!currentUser.isAnonymous) {
      throw new Error("You must be logged in to cancel your subscription");
    }
    const userDoc = await getDoc(doc(db, "users", currentUser.uid));
    const userData = userDoc.data();
    
    if (!userData || userData.subscription === "None") {
      throw new Error("You don't have an active subscription");
    }
    
    const data = {
      subscription_id: userData.subscription_id,
      uid: currentUser.uid
    };
    
    const res = await fetch('https://fathomless-gorge-94679.herokuapp.com/api/cancelsubscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    });
  
    if (!res.ok) {
      const error = await res.text();
      throw new Error(error);
    }
    console.log("response", res)
  };
  const onProceed = async () => {
    try {
      await cancelSubscription();
    } catch (error) {
      message.error('Failed to cancel subscription: ' + error.message);
      return; // If the subscription cancellation fails, return early without deleting the account
    }
    setShowCancelWarningModal(false)
  }
  const handleServiceTerminationClick = () => {
    setServiceTerminationLoading(true);
      setServiceTerminationLoading(false);
      navigate("/CancelSubscription"); // Or any other logic you want to execute after loading
  };
  
  const deleteAccount = async () => {
    const userDoc = await getDoc(doc(db, "users", currentUser.uid));
    const userData = userDoc.data();
  
    if (userData && userData.subscription !== "None") {
      try {
        await cancelSubscription();
      } catch (error) {
        message.error('Failed to cancel subscription: ' + error.message);
        return; // If the subscription cancellation fails, return early without deleting the account
      }
    }
  
    // Delete user from Firebase authentication and Firestore
    await Promise.all([
      await deleteDoc(doc(db, "users", currentUser.uid)),
      await deleteUser(currentUser),
    ]);
  
    localStorage.setItem("loggedIn", "false");
    navigate("/")
    // Logout user
  }; 
  const handleUpdateProfile = async () => {

    await updateDoc(doc(db, "users", userData.uid), {
      "FormData.name": username,
      "FormData.pronouns": pronouns // Correct way to update a nested field
    });
    setEditMode(false);
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        const userDataDoc = userDoc.data();
        // Set state with user data
        setUsername(userDataDoc.FormData.name);
        setPronouns(userDataDoc.FormData.pronouns);
        // ... other user data
      } catch (error) {
        console.error("Error fetching user data: ", error);
        // Handle the error appropriately
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserData();
  }, [currentUser]); // Dependency array
  return (
    <div className='SettingsPage'>
    <MainNav />
<div className="settingsWrapper">
  {loading ? (
    <div className="spinner-container">
      <Spin tip="Loading user data..." size="large"/>
    </div>
  ) : (
    <div className="settings-container">
          <div className="section">
            <div className="section-header">Account Management</div>
            {editMode ? (
              <>
                <div className="option-item">
                  <UserOutlined />
                  <input 
                    type="text" 
                    value={username} 
                    onChange={e => setUsername(e.target.value)} 
                    placeholder="Username" 
                    className="settings-input" 
                  />
                </div>
                <div className="option-item">
                  <select 
                    value={pronouns} 
                    onChange={e => setPronouns(e.target.value)}
                    className="settings-select"
                  >
                    <option value="he/him">He/Him</option>
                    <option value="she/her">She/Her</option>
                    <option value="they/them">They/Them</option>
                  </select>
                </div>
                <div className="option-item">
                  <button className="choose-button" onClick={handleUpdateProfile}>
                    Update Profile
                  </button>
                </div>
              </>
            ) : (
              <>
                <p>Username: {username}</p>
                <p>Pronouns: {pronouns}</p>
                <p>Your Email: {currentUser ? currentUser.email : null}</p>
                <button className="choose-button" onClick={() => setEditMode(true)}>
                  Edit Profile
                </button>
              </>
            )}
            <div className="optionsButtons">
            <button className="choose-button" onClick={() => {console.log("press");setShowReAuthModalPass(true)}}>
  Change Password
</button>
              <button className="choose-button" onClick={() => setDeleteModalVisible(true)}>
                Delete Account
              </button>
              <button className="choose-button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        {(userData && userData.subscription !== "None") ? (
          <div>
          <SubscriptionPanel 
         planName={userData && userData.type !== "annual" ? userData.subscription : "Annual Plan"}
  planPrice={getPlanPrice(userData && userData.type !== "annual" ? userData.subscription : "Annual Plan")}
          currentPlan={userData && userData.type !== "annual" ? userData.subscription : "Annual Plan"}
          onUpgradeClick={null}  // No upgrade possible for the current plan
          features={calculatedFeatures} // State-managed features  // You might want to dynamically set this based on the subscription
        />
<Collapse 
  onChange={(key) => {
    if (key.includes('accountSettings')) {
      setAccountSettingsLoading(true);
      setTimeout(() => {
        setAccountSettingsLoading(false);
      }, 30000); // 10 seconds delay
    }
  }}
  bordered={false} 
  ghost 
  expandIcon={({ isActive }) => <ArrowDownOutlined rotate={isActive ? 180 : 0} style={{ color: '#ffffff' }} />} 
  style={{ background: 'rgba(255, 255, 255, 0.2)', marginTop: '15px', borderRadius: '8px' }}
>
  <Panel 
    header={<Text style={{ color: '#ffffff' }}>Show Info</Text>} 
    key="accountSettings"
    style={{ padding: '15px' }}
  >
    {isAccountSettingsLoading ? (
    <Spin tip="Loading...">
      {/* You can put a placeholder or additional content here while loading */}
    </Spin>
  ) : (
<Row gutter={16}>
  <Col span={18}>
    <div style={{ padding: '10px 0' }}>
      <Text strong style={{ color: '#D1E0FF' }}>
        <i className="fas fa-id-card" style={{ color: '#AABFFF' }}></i> Subscription ID:
      </Text>
      <Text style={{ color: '#ffffff' }}>{userData.subscription_id}</Text>
    </div>
    <div style={{ padding: '10px 0' }}>
      <Text strong style={{ color: '#D1E0FF' }}>
        <i className="fas fa-toggle-on" style={{ color: '#AABFFF' }}></i> Subscription Status:
      </Text>
      <Text style={{ color: '#ffffff' }}>active</Text>
    </div>
    <div style={{ padding: '10px 0' }}>
      <Text strong style={{ color: '#D1E0FF' }}>
        <i className="fas fa-tags" style={{ color: '#AABFFF' }}></i> Subscription Plan:
      </Text>
      <Text style={{ color: '#ffffff' }}>{userData.subscription}</Text>
    </div>
    <div style={{ padding: '10px 0' }}>
      <Text strong style={{ color: '#D1E0FF' }}>
        <i className="fas fa-wallet" style={{ color: '#AABFFF' }}></i> Payment Method:
      </Text>
      <Text style={{ color: '#ffffff' }}>PayPal-Account</Text>
    </div>
  </Col>
</Row>
  )}

    <Collapse 
      bordered={false} 
      ghost 
      expandIcon={({ isActive }) => <ArrowDownOutlined rotate={isActive ? 180 : 0} style={{ color: '#ffffff' }} />} 
      style={{ background: 'rgba(255, 255, 255, 0.1)', marginTop: '10px', borderRadius: '8px' }}
    >
      <Panel 
        header={<Text style={{ color: '#D1E0FF' }}>Account Settings</Text>} 
        key="accountSettings"
        style={{ padding: '10px' }}
      >
          {isAccountSettingsLoading ? (
      <Spin tip="Loading...">
        {/* You can put a placeholder here if you want */}
      </Spin>
    ) : (
     <><Button 
  type="link" 
  block 
  style={{ color: '#AABFFF', borderColor: '#AABFFF' }}
  onClick={() => setTooltipVisible(true)}
>
  <i className="fas fa-headset" style={{ color: '#AABFFF' }}></i> Contact Support
</Button>

<Modal
  title="Need Help?"
  visible={tooltipVisible}
  onCancel={() => setTooltipVisible(false)}
  footer={null}
>
  <p>For any assistance, you can reach out to our support team:</p>
  <div 
    onClick={handleEmailCopy} 
    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
  >
    <span style={{ marginRight: '8px' }}>support@oniichat.com</span>
    <i className="fas fa-clipboard" title={copyStatus}></i>
  </div>
  <small style={{ color: copyStatus === 'Copied!' ? 'green' : 'red' }}>{copyStatus}</small>
</Modal>
<Button 
  type="link" 
  block 
  style={{ color: '#AABFFF', borderColor: '#AABFFF' }}
  onClick={() => setTooltipVisible(true)}
>
  <i className="fas fa-question-circle" style={{ color: '#AABFFF' }}></i> Help
</Button>
      {showServiceTermination ? (
  <Spin tip="Loading Service Termination...">
    {/* Optional placeholder content during loading */}
  </Spin>
) : (
  <span 
    style={{ 
      color: '#666666', 
      fontSize: '0.65em', 
      textDecoration: 'underline', 
      cursor: 'pointer' 
    }}
    onClick={handleServiceTerminationClick}
  >
    <i className="fas fa-exclamation-triangle" style={{ color: '#666666' }}></i> Initiate Service Termination
  </span>
)}</>
    )}
      </Panel>
    </Collapse>
  </Panel>
</Collapse>

      </div>
): (
  <div className="subscription-section">
  <div className="subscription-info">
    <div className="subscription-icon">
      <img src={SettingsCTA} alt="SettingsCTA" /> 
    </div>
    <div className="subscription-text">
    Get pro <br />
      Interact with your waifus without limits. Talk to Advanced AI, find romance, role-play, grow with your waifu and so much more.
    </div>
  </div>
  <button className="choose-subscription-button" onClick={() => {navigate("/shop")}}>
    Choose Subscription
  </button>
</div>
)
}
<Modal
  title="Change Password"
  visible={changePasswordModalVisible}
  onOk={handleChangePassword}
  onCancel={() => setChangePasswordModalVisible(false)}
  okText="Change"
  cancelText="Cancel"
>
  <Form layout="vertical">
    <Form.Item label="New Password" required validateStatus={changePasswordError ? 'error' : ''} help={changePasswordError}>
      <Input.Password
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        placeholder="Enter New Password"
      />
    </Form.Item>
  </Form>
</Modal>
    <Modal
      title="Delete Account"
      visible={deleteModalVisible}
      onOk={() => {setShowReAuthModalAccount(true); setDeleteModalVisible(false)}}
      onCancel={() => setDeleteModalVisible(false)}
      okText="Delete"
      cancelText="Cancel"
    >
      <Alert message="Are you sure you want to delete your account? This action is irreversible." type="warning" />
    </Modal>
    <Modal
      title="Cancel Subscription"
      visible={cancelModalVisible}
      onOk={handleCancelSubscription}
      onCancel={() => setCancelModalVisible(false)}
      okText="Confirm"
      cancelText="Cancel"
    >
      <Alert message="Are you sure you want to cancel your subscription? You will lose all your subscriber benefits." type="warning" />
    </Modal>
    <Modal
  title="Why are you canceling your subscription?"
  visible={feedbackModalVisible}
  onOk={handleFeedbackSubmit}
  onCancel={() => setFeedbackModalVisible(false)}
  okText="Submit"
  cancelText="Cancel"
>
  <Form.Item label="Feedback" required>
    <Input 
      value={feedback} 
      onChange={e => setFeedback(e.target.value)} 
      placeholder="Please share why you are canceling your subscription" 
    />
  </Form.Item>
</Modal>
    <ReAuthModal 
  visible={showReAuthModalSubscription} 
  onSubmit={handleCancelSubscription}  
  onCancel={() => setShowReAuthModalSubscription(false)}
  title="Confirm Subscription Cancellation"
  message="For your security, please re-enter your password to confirm cancellation of your subscription."
/>
<ReAuthModal 
  visible={showReAuthModalPass} 
  onSubmit={() => {setChangePasswordModalVisible(true); setShowReAuthModalPass(false)}}  
  onCancel={() => setShowReAuthModalPass(false)}
  title="Reauthenticate"
  message="For your security, please reauthenticate."
/>
{userData && <CancelWarningModal 
  visible={showCancelWarningModal} 
  onProceed={onProceed} 
  onCancel={() => setShowCancelWarningModal(false)} 
 subscription={userData ? userData.subscription : ""}
/>}
<ReAuthModal 
  visible={showReAuthModalAccount} 
  onSubmit={handleDeleteAccount}  
  onCancel={() => setShowReAuthModalAccount(false)}
  title="Confirm Account Deletion"
  message="For your security, please enter your password to confirm your account deletion. This action cannot be undone."
/>
  </div>
)}
</div>
</div>
  );
};

export default SettingsPage;

import React, { useState, useContext, useEffect } from 'react';
import { Select, Spin, message } from 'antd';

import { useNavigate,  Redirect, useParams} from "react-router-dom";
import { getFirestore, doc, setDoc, updateDoc, getDoc } from "firebase/firestore"
import { AuthContext } from "../context/AuthContext";
import MainNav from '../components/Common/MainNav';
// Define styles
import "../styles/ProfilePage.scss"
import { db } from "../firebase";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ProfilePage = () => {
    // Placeholder for profile image
    const { currentUser, userData } = useContext(AuthContext);
    const [customCharacter, setCustomCharacter] = useState("")
    const profileImage = 'https://stars-test.s3.amazonaws.com/free-prod/4e1fc945-5064-49ba-9fb6-0e1e5e9e0fec-0.png';
    const navigate = useNavigate();
    const LoadSavedData = async () => {
        try {
          console.log(userData);
          if(!userData)
          {
            return
          }
          if (!userData.customCharacter) {
            console.error("No custom character data available.");
            // You could show a user-friendly message here
            // or set some default states, then return.
            return;
          }
          
          const customCharacterDoc = await getDoc(doc(db, "customCharacters", userData.customCharacter));
          
          if (!customCharacterDoc.exists) {
            console.error("Custom character document does not exist.");
            // Handle this case similarly to above.
            return;
          }
          
          const customCharacter = customCharacterDoc.data();
          console.log("=====>", customCharacter)
          // Check if the customCharacter data is actually present
          if (!customCharacter) {
            console.error("Incomplete custom character data.", "image: ",customCharacter.imageURL, "traits: ", customCharacter.selectedTraits);
            // Handle this case as well.
            return;
          }
    setCustomCharacter(customCharacter)
          
        } catch (error) {
          console.error("Error fetching custom character data:", error);
          // Handle the error appropriately here, perhaps by showing an error message to the user.
        }
      };
      useEffect(() => {
        LoadSavedData()
        console.log("update data")
    },[userData])
    const flattenTraits = (traits) => {
        return Object.values(traits).flat();
      };
      const handleBack = () => {
        // You can use 'navigate' from 'react-router-dom' if you're using routing
        navigate("/yourWaifu");
        console.log('Back button clicked');
      };
      const imageURL = customCharacter?.imageURL || 'https://firebasestorage.googleapis.com/v0/b/oniichat-2c310.appspot.com/o/DALL%C2%B7E%202023-11-27%2018.30.36%20-%20A%20simple%20anime-style%20female%20character%20suitable%20for%20a%20profile%20picture%2C%20in%20black%20and%20white.%20She%20has%20a%20gentle%20expression%2C%20long%20flowing%20hair%2C%20and%20a%20minima.png?alt=media&token=728abfba-2c03-43b0-b4e0-b0992e4bf06f';
      const displayName = customCharacter?.displayName || 'Choose Name';
      const tagline = customCharacter?.tagline; // Corrected the typo here
    return (
      <>
        <div className='profile-content-container'>
        <MainNav /> {/* Make sure your MainNav component has the 'main-nav' class */}
        <div className="profile-container">
        <button onClick={handleBack} className="back-button">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
          <div className="profile-header">
          <img src={imageURL} alt="Profile" />
          <h1>{displayName}</h1>
          </div>
          <div className="profile-personality">
                    {tagline ? <span className="personality-tag">{tagline}</span>
                        : <span className="personality-tag">No Personality created</span>
                    }
                </div>
          <div className="edit-buttons">
          <button className="edit-button" onClick={() => {
    // Define the values for save and redirectPage parameters
    const redirectPageParam = 'customProfilePage'; // Replace 'DestinationPage' with your desired redirect path

    // Navigate to the EditYourWaifu route with parameters
    navigate(`/EditYourWaifu?redirectPage=${redirectPageParam}`);
}}>Looks</button>
          <button className="edit-button" onClick={() => {
    const redirectParam = "customProfilePage"; // Replace with your desired value
    navigate(`/WaifuName?redirectPage=${redirectParam}`);
}}>Waifu Name</button>
          <button className="edit-button" onClick={() => {
    // Define the values for save and redirectPage parameters
    const redirectPageParam = 'customProfilePage'; // Replace 'DestinationPage' with your desired redirect path

    // Navigate to the EditYourWaifu route with parameters
    navigate(`/CreateWaifuPersonality?redirectPage=${redirectPageParam}`);
}}>Personality</button>
          </div>
        </div>
        </div>
      </>
    );
  }
  
  export default ProfilePage;
import './wdyr'; 

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import * as Sentry from '@sentry/react';
import { AuthContextProvider } from "./context/AuthContext";
import { CharacterProvider } from "./context/CharacterContext";
import { Integrations } from '@sentry/tracing';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserTrackingProvider } from './context/UserTrackingContext';
Sentry.init({
  dsn: "https://eccf1066d627443f8dda65f2513dbfe3@o4505603011117056.ingest.sentry.io/4505603071016960",
  release: "oniichat@1.0",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https://oniichat.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <AuthContextProvider>
  <CharacterProvider>        <App />
    </CharacterProvider>
  </AuthContextProvider>
  </React.StrictMode>
);

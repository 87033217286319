import fetch from 'node-fetch';

export async function getStableDiffusionImage(prompt, style) {
    try {
      // Replace this URL with the actual URL of your deployed Firebase function
      const firebaseFunctionURL = "https://us-central1-oniichat-2c310.cloudfunctions.net/generateImage";
      console.log("st prompt", prompt)
      // Making a request to the Firebase function
      const response = await fetch(firebaseFunctionURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
          prompt: prompt,
          subscription: "Ultimate"
         })  // Sending the prompt as a parameter in the request body
      });
  
      // Parsing the response
      const data = await response.json();
      console.log(data)
      // Extracting the output link from the response
      const outputLink = data.url;
  
      if (outputLink) {
        // Do something with the output link, e.g., display it in the UI
        console.log("Output link:", outputLink);
        return outputLink;
      } else {
        console.error("Output link not found");
        return null;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }
import { Tracker } from "./common";

export class XTracker extends Tracker {
  static get trackerId() {
    return "xTracking";
  }

  static get trackingKeys() {
    return ["twclid", "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
  }
}

import React, { useState, useEffect, lazy, Suspense , useRef} from 'react';
import { Modal, Button, Card, Tabs } from 'antd';
import ReactPlayer from 'react-player/lazy';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import "../styles/video-modal.scss"
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  FieldValue                               
} from "firebase/firestore";
import { logEvent, db, storage } from "../firebase";
import "../styles/VideoModal.scss"
const { TabPane } = Tabs;

const VideoModal = ({ characterData, onUnlockVideo, isModalVisible, onCancelModal, userData}) => {
  const [normalVideos, setNormalVideos] = useState([]);
  const [explicitVideos, setExplicitVideos] = useState([]);
  const [visibleNormalVideos, setVisibleNormalVideos] = useState([]);
  const [visibleExplicitVideos, setVisibleExplicitVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadMoreRef = useRef(null);
  const [purchasedVideos, setPurchasedVideos] = useState(userData.purchasedVideos || {});

  const VIDEO_BATCH_SIZE = 10; // Number of videos to load each time
    
      useEffect(() => {
    // Load initial videos
    setVisibleNormalVideos(normalVideos.slice(0, VIDEO_BATCH_SIZE));
    setVisibleExplicitVideos(explicitVideos.slice(0, VIDEO_BATCH_SIZE));
  }, [normalVideos, explicitVideos]);
  // Intersection Observer to trigger loading more videos
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !loading) {
        setLoading(true);

        // Load more videos
        setTimeout(() => {
          const newCount = visibleNormalVideos.length + VIDEO_BATCH_SIZE;
          setVisibleNormalVideos(normalVideos.slice(0, newCount));
          setVisibleExplicitVideos(explicitVideos.slice(0, newCount));
          setLoading(false);
        }, 1000); // Simulate network delay
      }
    }, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loading, normalVideos, explicitVideos, visibleNormalVideos, visibleExplicitVideos]);
    const categorizeVideos = () => {
      const newNormalVideos = [];
      const newExplicitVideos = [];
      if (characterData) {
        // For each level, add only the first video that hasn't been purchased
    
        for (const videoLink of characterData.videos_nsfw1) {
          newExplicitVideos.push({ link: videoLink, description: "are you afraid of skin😋 ?", gems: 500 });          
        }
    
        for (const videoLink of characterData.videos_nsfw2) {
          newExplicitVideos.push({ link: videoLink, description: "Only watch if you really like me😳 ", gems: 2000 });
          
        }
    
        for (const videoLink of characterData.videos_nsfw3) {
          newExplicitVideos.push({ link: videoLink, description: "My most private insights💋 ", gems: 3000 });          }
        
        for (const videoLink of characterData.videos) {
          newNormalVideos.push({ link: videoLink, description: "Some private insights🤫", gems: 3000 });          
        }
        // Repeat the pattern for other video arrays as needed
      }
      console.log("newNormalVideos", newNormalVideos)
      setNormalVideos(newNormalVideos);
      setExplicitVideos(newExplicitVideos);
    };

    const handleUnlockVideo = (index, videoLink, gems) => {
      onUnlockVideo(index, gems, videoLink, async (isUnlocked) => {
        if (isUnlocked) {
          
          // Update Firestore with the newly purchased video
          const userDocRef = doc(db, 'users', userData.uid);
          const newPurchasedVideos = { ...purchasedVideos, [videoLink]: true };
          await updateDoc(userDocRef, { purchasedVideos: newPurchasedVideos });
    
          setPurchasedVideos(newPurchasedVideos);
        }
      });
    };
    


    useEffect(() => {
      categorizeVideos();
    }, [characterData, purchasedVideos]);
    
  const renderVideoCards = (videos, category) => {
    return videos.map((video, index) => {
      const isPurchased = purchasedVideos[video.link];
      return (
        <LazyLoadComponent>
        <Card key={index} hoverable className="video-card" /* ... */ >
          {/* Video player and overlay */}
          <div className="video-container">

      <ReactPlayer
        url={video.link}
        width='100%'
        height='100%'
        playing={isModalVisible}
        loop={true}
        muted={true}
        controls={isPurchased}
      />

                  {!purchasedVideos[video.link] && (
                    <div className="video-blur-overlay"></div>
                  )}
                </div>
          <p>{video.description}</p>
          {isPurchased ? (
            <Button  block>
              Unlocked
            </Button>
          ) : (
            <Button onClick={() => handleUnlockVideo(index, video.link, video.gems)} className='unlock-button' block>
              Unlock with {video.gems} Gems
            </Button>
          )}
        </Card></LazyLoadComponent>);
    });
  };



  return (
    <Modal
      title="My Video Collection"
      visible={isModalVisible}
      onCancel={onCancelModal}
      width={850}
      footer={null}
      className="video-modal"
    >
      <Tabs defaultActiveKey="1" className="video-tabs">
        <TabPane tab="Normal Videos" key="1">
          <div className="video-grid">
            {renderVideoCards(visibleNormalVideos, 'normal')}
          </div>
        </TabPane>
        <TabPane tab="Explicit Videos" key="2">
          <div className="video-grid">
            {renderVideoCards(visibleExplicitVideos, 'explicit')}
          </div>
        </TabPane>
      </Tabs>
      {false && <p>No more videos available. Check back later!</p>}
    </Modal>
  );
};


export default VideoModal;
import React, { useState, useContext, useEffect} from 'react';
import '../styles/VoicePopup.scss'; // Assume you have a CSS file for styling
import { ChatContext } from '../context/ChatContext';
const voices = [
  { id: 1, name: 'Scarlett', description: 'Erotic, Mature and Sensual', voiceId: "scarlett-johansson" },
  { id: 2, name: 'Emma S.', description: 'Unique, Expressive and Vibrant', voiceId: "emma-stone" },
  { id: 3, name: 'Emma W.', description: 'Youthful, Intelligent and Articulate', voiceId: "emma-watson" },
  { id: 4, name: 'Ashley', description: 'Tomboy, Adventurous and rather Deep', voiceId: "ashley-teagan" },
  { id: 5, name: 'Nekobot', description: 'Digital, Synthetic and Playful', voiceId: "neko-bot" },
  { id: 6, name: 'Cleo', description: 'Youthful, Happy and rather High', voiceId: "cleo-tomson" },
];

const VoicePopup = ({ isOpen, onClose }) => {
  const [selectedVoice, setSelectedVoice] = useState(null);
  const {setDisplayType, setShowSignUp, voiceId, setVoiceId} = useContext(ChatContext)

  useEffect(() => {
    const storedVoiceId = localStorage.getItem('selectedVoiceId');
    if (storedVoiceId) {
      setSelectedVoice(JSON.parse(storedVoiceId));
    }
  }, []);

  const handleSelectVoice = (voice) => {
    setSelectedVoice(voice);
    localStorage.setItem('selectedVoiceId', JSON.stringify(voice)); // Store the voice ID in localStorage
  };
  const handleSubmit = () => {
    // Handle the submission of selected voices
    console.log('Selected voices: ', selectedVoice);
    setVoiceId(selectedVoice.voiceId)
    onClose(); // Close the popup
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="voice-popup-overlay">
    <div className="voice-popup">
      <h2>Select a Voice</h2>
      <div className="voice-list">
        {voices.map((voice) => (
          <div
            key={voice.id}
            className={`voice-item ${selectedVoice?.id === voice.id ? 'selected' : ''}`}
            onClick={() => handleSelectVoice(voice)}
            role="button"
            tabIndex={0}
          >
            <span className="voice-name">{voice.name}</span>
            <p className="voice-description">{voice.description}</p>
          </div>
        ))}
      </div>
      <div className="voice-popup-actions">
        <button className="cancel-button" onClick={onClose}>Cancel</button>
        <button className="confirm-button" onClick={handleSubmit}>Confirm</button>
      </div>
    </div>
  </div>
  );
};

export default VoicePopup;

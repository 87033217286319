import { Tracker } from "./common";

export class ExoClickTracker extends Tracker {
  static get trackerId() {
    return "exoClickTracking";
  }

  static get trackingKeys() {
    return ["exotracker"];
  }

  getConversionId() {
    const trackingParams = this.getTrackingParams();
    return trackingParams ? trackingParams["exotracker"] : undefined;
  }
}
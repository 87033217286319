import React, { useState, useContext } from 'react';
import { doc, updateDoc, query, collection, where, getDocs } from "firebase/firestore"; 
import { Upload, message, Spin } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { functions, httpsCallable, storage} from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { debounce } from '@mui/material';
const ImageUpload = ({ onImageUpload }) => {
    const { currentUser, userData } = useContext(AuthContext);
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);

    const uploadProps = {
        listType: "picture-card",
        showUploadList: false,
        beforeUpload: file => {
            const reader = new FileReader();
            reader.onload = async e => {
                setImageUrl(e.target.result);
                setPreviewVisible(true);
                await uploadToFirestore(e.target.result, currentUser.uid);
            };
            reader.readAsDataURL(file);
            return false; // Prevent default upload behavior
        }
    };


    const uploadToFirestore = async (base64, userId) => {
        setUploading(true);

        try {
            // Query Firestore for the document with the matching createdBy field
            const votingCharactersRef = collection(db, 'votingCharacters');
            const q = query(votingCharactersRef, where("createdBy", "==", userId));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming the first document is the one to update
                const docRef = doc(db, 'votingCharacters', querySnapshot.docs[0].id);

                // Update the existing document in Firestore
                await updateDoc(docRef, { imageUrl: base64 });

                message.success('Image updated successfully!');
            } else {
                // Handle the case where no document is found
                message.error('No matching document found to update.');
            }
        } catch (error) {
            console.error('Error updating image:', error);
            message.error('Update failed.');
        } finally {
            setUploading(false);
        }
    };
    const uploadButton = (
        <div>
            {uploading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
<Upload {...uploadProps}>
  {previewVisible ? (
    <div style={{ position: 'relative', textAlign: 'center' }}>
      <img src={imageUrl} alt="avatar" style={{ width: '100%', maxHeight: '200px', borderRadius: '24px' }} />
      {uploading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
    </div>
  ) : uploadButton}
</Upload>
    );
};

export default ImageUpload;

import React, { useState, useEffect } from 'react';
import '../../styles/BoostPopup.scss';
import { Modal, Button, InputNumber, message, Row, Col, Radio } from 'antd';
import { RocketOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { runTransaction, doc } from '@firebase/firestore';
import { db, logEvent, functions, httpsCallable, logGemPurchase } from '../../firebase';
import {
  updateDoc,
  onSnapshot,
  FieldValue
} from "firebase/firestore";
import multipleGems from "../../img/multipleGems.png"



const BOOST_COST = 100;

const BoostPopup = ({ character, user: initialUser , authUser}) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [boostsToBuy, setBoostsToBuy] = useState(0);
  const [boostType, setBoostType] = useState('upvotes');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(initialUser);
  const toggleButtonClicked = () => {
    setButtonClicked(!buttonClicked);
  };
  const BoostBundle = ({ gems, price, originalPrice, onClick, countText, saleText, voteType }) => (
    <div className="gemItem">
      {saleText ? (<div className='banner'><span className='bannerText'>{saleText}</span></div>) : null}
      <div className="emojiDisplay" style={{ transform: voteType === 'downvotes' ? 'rotate(135deg)' : 'rotate(-45deg)' }}>🚀</div>
      {countText ? (<div className='countText'><span>{countText}</span></div>) : null}
      <div className="priceButton" onClick={onClick}>
        {originalPrice && <span className="originalPrice">{originalPrice}</span>}
        <span className="price">{price}</span>
      </div>
    </div>
  );
  
  const [showBoostBundles, setShowBoostBundles] = useState(true);
  const [showTitle, setShowTitle] = useState(false);
  const purchaseBoostBundle = async (bundleSize, bundlePrice) => {
    if (user.gems < bundlePrice) {
      message.error("You do not have enough gems to purchase this bundle.");
      return;
    }

    setLoading(true);

    try {
      // Call the boostCharacter cloud function
      const boostCharacter = httpsCallable(functions, 'boostCharacter');
      await boostCharacter({
        characterId: character.id,
        userId: user.uid,
        boostsToBuy: bundleSize,
        boostType: boostType,
        totalCost: bundlePrice
      });

      // Success message
      message.success(`Successfully boosted ${character.name} with ${bundleSize} ${boostType}!`);

      // Log event when transaction is successful
      logEvent('boost_transaction_successful', {
        characterId: character.id,
        userId: user.uid,
        boostsToBuy: bundleSize,
        boostType: boostType,
      });
      if(authUser?.email !== "admin@test.com")
      {
      logGemPurchase(user.uid, bundlePrice, "boost", bundleSize)
      }
    } catch (error) {
      // Error handling
      message.error("Failed to boost. Please try again!");
      console.error("Boost Transaction Failed: ", error);

      // Log event when transaction fails
      logEvent('boost_transaction_failed', { reason: error.message });
    } finally {
      setLoading(false);
      setVisible(false); // Close the modal after transaction
    }
  };

  const updateCharacterBoosts = async (charId, additionalBoosts) => {
    const charRef = doc(db, 'votingCharacters', charId);
    await updateDoc(charRef, {
      boosts: FieldValue.increment(additionalBoosts)
    });
  };

  const calculateBoostsForBundle = (bundleSize) => {
    return bundleSize;
  };



  useEffect(() => {
    if (initialUser && initialUser.uid) {
      const userRef = doc(db, 'users', initialUser.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        setUser(doc.data());
      });

      // Clean up the listener when the component is unmounted
      return () => unsubscribe();
    }
  }, [initialUser?.uid]);
  const handleOk = async () => {

      setVisible(false);

  }

  return (
<div className='boostPopup'>
    <Button onClick={() => setVisible(true)} icon={<RocketOutlined />} size="large" disabled={loading}>
      Boost
    </Button>
    <Modal
      title="Boost a character"
      visible={visible}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={() => setVisible(false)}
      className="boost-modal"
      styles={{
        body: {
          backgroundImage: 'linear-gradient(to bottom, #7f7fd5, #86a8e7, #91eae4)',
        },
      }}
      footer={[
        <Button key="back" onClick={() => setVisible(false)} className="cancelButton">
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk} className="okButton">
          OK
        </Button>,
      ]}
    >
      <Row>
        <Col xs={24} sm={12} className="boostInfo">
          <p>Each boost costs {BOOST_COST} gems.</p>
          <p>You have {user.gems} gems.</p>
        </Col>
        <Col xs={24} sm={12} className="boostControls">
          <div className="voteAndInputSideWrapper">
            <Radio.Group onChange={e => setBoostType(e.target.value)} value={boostType} className="boostTypeSelector">
              <Radio.Button value="upvotes">Upvote <ArrowUpOutlined /></Radio.Button>
              <Radio.Button value="downvotes">Downvote <ArrowDownOutlined /></Radio.Button>
            </Radio.Group>
          </div>
        </Col>
        {showTitle && (
          <Col className='boostBundleTitle'>
            <h1>🚀Purchase Boost-Bundles🚀</h1>
          </Col>
        )}
        <h2 className="boostBundleHeadline">🚀 Boost Bundles 🚀</h2>
        {showBoostBundles && (
          <div className="gemGrid"> 
          <BoostBundle gems={10} price="900 gems" originalPrice="1000" onClick={() => purchaseBoostBundle(10, 900)} countText="10 BOOSTS" saleText="10% OFF" voteType={boostType}/>
            <BoostBundle gems={50} price="4250 gems" originalPrice="5000" onClick={() => purchaseBoostBundle(50, 4250)} img={multipleGems} countText="50 BOOSTS" saleText="15% OFF" voteType={boostType} />
            <BoostBundle gems={100} price="8000 gems" originalPrice="10000" onClick={() => purchaseBoostBundle(100, 8000)} img={multipleGems} countText="100 BOOSTS" saleText="20% OFF" voteType={boostType}/>
          </div>
        )}
      </Row>
    </Modal>
  </div>
  );
}

export default BoostPopup;
import React, { useContext, useEffect, useState} from "react";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  FieldValue                               
} from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Button, Tooltip, Switch, Dropdown, Menu, Modal,Typography, Space} from 'antd';
import { db, logEvent} from "../../firebase";
import Messages from "./Messages";
import { useNavigate } from 'react-router-dom';
import Input from "./Input";
import { ChatContext, ChatContextProvider } from "../../context/ChatContext";
import { AuthContext } from "../../context/AuthContext";
import gem from "../../img/gem.png";
import backButton from "../../img/backButton.png";
import { useLocation} from "react-router-dom";
import SubscriptionModal from "./SubscriptionModal";
import { Link } from "react-router-dom";
import GemCounter from "../Common/GemCounter";
import AdvancedAIModal from "./AdvancedAIModal";
import ChatMenuWindow from "./ChatMenuWindow";
import Diary from "../Chat/Sidebars/Diary";
import StoryStore from "./Sidebars/StoryStore";
import MemorySidebar from "./Sidebars/MemorySidebar";
import { faArrowLeft, faEllipsisV, faRepeat, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faExpand, faCompress } from '@fortawesome/free-solid-svg-icons'
import BackBtnImage from "../../img/back-button.png";
import SignInOut from "../Auth/SignInOut"
import {handleAIResponse} from "../../AIConnector.js"
import VideoModal from "../VideoModal"
import NoGemsModal from "../NoGemsModal";
import "../../styles/xpBar.scss"
import UnlockedCharacters from '../../components/UnlockedCharacters';
import RelationshipStatusModal from "../RelationshipStatusModal";
const Chat = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const custom = queryParams.get("custom");
  const [showAdvancedAIModal, setShowAdvancedAIModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000)
  const {showRelationshipStatusModal, setShowRelationshipStatusModal, showNewLevelModal, setShowNewLevelModal, activeWindow, setActiveWindow, setDisplayType, data, displayType, showSignUp, setShowSignUp, fullScreenImageSrc, setFullScreenImageSrc, showModal, setShowModal, selectedImage, showWarning, setShowWarning, setShowVerification, showVerification, showErr, setShowErr, showMessageModal, setShowMessageModal, selectedMessage, characterData, chatID, chatData, selectedStory} = useContext(ChatContext);
  const { currentUser, userData} = useContext(AuthContext);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const navigate = useNavigate();
  const [showChatMenu, setShowChatMenu] = useState(false)
  const sessionId = currentUser ?  currentUser.uid : "";
  const [filteredContent, setFilteredContent] = useState([]);
  const [lastChatID, setLastChatID] = useState(null);

  const handleRightClick = (event) => {
    event.preventDefault();
    // You can add code here to handle the right click event
  }
  useEffect(() => {
    // Function to fetch characters from Firestore and transform their properties
    const fetchCharacters = async () => {
      const querySnapshot = await getDocs(query(collection(db, "characters"), where("level", "==", userData.level)));
      const characters = [];
      querySnapshot.forEach((doc) => {
        const characterData = doc.data();
        // Transform 'displayName' to 'name' and 'photoURL' to 'image'
        characters.push({
          name: characterData.displayName,
          image: characterData.photoURL,
          ...characterData // Spread other properties of the character if needed
        });
      });
      setFilteredContent(characters);
      console.log(characters); // Log the transformed array
    };
  
    if (showNewLevelModal) {
      fetchCharacters();
    }
  }, [showNewLevelModal, userData]);
  
  useEffect(() => {
    if(!userData) return
    const selectedBackground = (selectedStory && selectedStory.bg) ? `url(${selectedStory.bg})` : 'linear-gradient(180deg, #5C75CC 0%, #203378 100%)';
    const chatWrapper = document.querySelector(".ChatWrapper");
    chatWrapper.style.backgroundImage = selectedBackground;

    // Force a repaint
    void chatWrapper.offsetWidth;
  }, [selectedStory]);
  useEffect(() => {
    if (userData && userData.lastChatID) {
      setLastChatID(userData.lastChatID);
    }
  }, [userData]);

  // Effect to update the lastChatID
  useEffect(() => {
    if (currentUser && chatID && chatID !== lastChatID) {
      const userDocRef = doc(db, "users", currentUser.uid);

      updateDoc(userDocRef, {
        lastChatID: chatID
      }).catch(error => {
        console.error("Error updating user's lastChatID:", error);
      });

      setLastChatID(chatID);
    }
  }, [currentUser, chatID, lastChatID, db]);
  useEffect(() => {
    if((currentUser && !currentUser.isAnonymous && userData && !(userData?.FormData)))
    {
      navigate("/multistepform")
    }
    const checkMobileDevice = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    window.addEventListener("resize", checkMobileDevice);
    
    if (userData && currentUser && characterData) {
      console.log(currentUser);
      // Get the chat document from the chats collection using the chatId
      const chatDocRef = doc(db, "chats", chatID);
      const unSubChat = onSnapshot(chatDocRef, (doc) => {
        if (doc.exists()) {
          const messages = doc.data().messages;
          // Check if userData and messages are available
          if (userData && messages) {
            // Check if the messages array is longer than 10 and shorter than 13
            // and if the last message was sent by the current user
            if (messages.length > 10 && messages.length < 13 && messages[messages.length-1]["senderId"] === data.user.uid) {
              // Check if the user has a subscription and if the popup has not been shown yet
              if (!currentUser.isAnonymous && userData.subscription === "None" && !localStorage.getItem("subscriptionPopupShown" + data.user.uid)) {
                // Show the subscription popup here
                setShowSubscriptionModal(true);
                localStorage.setItem("subscriptionPopupShown" + data.user.uid, true);
                console.log("Showing subscription popup");
              }
            }
          }
        }
      });
  
      return () => {
        unSubChat();
        window.removeEventListener("resize", checkMobileDevice);
      };
    }
  }, [currentUser, userData]);
  
  const BuyImage = async () => {
    setShowModal(false)
    try
    {
if(userData.gems-5 < 0)
{
  setShowWarning(true)
  return;
}
    const messageDoc = await getDoc(doc(db, "chats", chatID));
    const temp = messageDoc.data();
    const messageArr = Object.values(temp.messages);
    for (let i = 0; i < messageArr.length; i++)
    {
      console.log("selected ",selectedImage.id, "loop ", messageArr[i].id);
        if(messageArr[i].id === selectedImage.id)
        {
          messageArr[i] = {
            id: selectedImage.id,
            text: selectedImage.text,
            senderId: selectedImage.senderId,
            date: selectedImage.date,
            img: selectedImage.img,
            unlocked: true
          }
        }
    }
    await setDoc(doc(db, "chats", chatID), {
      messages: messageArr
}, { merge: true });
await updateDoc(doc(db, "users", currentUser.uid), {
gems: userData.gems-5,
});

}
catch(err)
{
  setShowErr(true)
  console.log(err)
}

  }
  const BuyMessage = async () => {
    setShowMessageModal(false)
    try
    {
if(userData.gems-10 < 0)
{
  setShowWarning(true)
  return;
}
    const messageDoc = await getDoc(doc(db, "chats", chatID));
    const temp = messageDoc.data();
    const messageArr = Object.values(temp.messages);
    for (let i = 0; i < messageArr.length; i++)
    {
        if(messageArr[i].id === selectedMessage.id)
        {
          messageArr[i] = {
            id: selectedMessage.id,
            text:selectedMessage.text,
            senderId: selectedMessage.senderId,
            date: selectedMessage.date,
            locked: false,
          }
        }
    }
    console.log(messageArr)
    await setDoc(doc(db, "chats", chatID), {
      messages: messageArr
}, { merge: true });
await updateDoc(doc(db, "users", currentUser.uid), {
gems: userData.gems-10,
}, { merge: true });

}
catch(err)
{
  console.log(err)
}

  }
  const ResetChat = async () => {
    try {
      if (currentUser && (userData.gems - 10 < 0)) {
        if(currentUser.isAnonymous === false)
        {
        setShowWarning(true);
        return;
        }
        else{
          setDisplayType("")
          setShowSignUp(true)
          return
        }
      }
      const confirmed = window.confirm("Are you sure you want to reset the chat? This action cannot be undone.");
      if (!confirmed) {
        return;
      }
      await setDoc(doc(db, "chats", chatID), {
        messages: []
      }, {merge: true});
      if(currentUser)
      {
      await updateDoc(doc(db, "users", currentUser.uid), {
        gems: userData.gems - 10,
      });
    }
    } catch (err) {
      console.log(err);
    }
  };
  const hideChatMenu = () => setShowChatMenu(false);
 const handleBack = () => {
  if(custom)
  {
  navigate('/yourWaifu');
  }
  else{
    navigate('/selectchat');
  }
 }
 const handleClickWithoutLogin = () => {
  setDisplayType("features")
  setShowSignUp(true)
}
const XPBar = ({ xp, maxXP, level, characterName }) => {
  const xpPercentage = (xp / maxXP) * 100;

  return (
    <div className="xp-bar-wrapper">
      <div className="character-name">{characterName}</div>
      <div className="level-and-xp-bar">
        <div className="level-indicator">Lvl {level}</div>
        <div className="xp-bar-container">
          <div className="xp-bar" style={{ width: `${xpPercentage}%` }}></div>
        </div>
      </div>
    </div>
  );
};

const toggleVisibility = () => {
  setShowNewLevelModal(!showNewLevelModal)
}

  return (
    <>
        {!userData ? (    <div className="loader-container">
    <div className="spinner"></div>
  </div>): (
    <div className="ChatWrapper">
    <ChatMenuWindow 
      isOpen={showChatMenu} 
      handleClose={hideChatMenu} 
    />
{(currentUser && userData && characterData && chatData) &&(
  <Diary 
    isOpen={activeWindow === 'diary'} 
    handleClose={() => setActiveWindow(null)} 
    characterName={characterData.uid}
    chatData={chatData}
  />
)}
{(characterData && currentUser && userData && userData.uid) &&(
  <StoryStore 
    isOpen={activeWindow === 'storyStore'} 
    handleClose={() => setActiveWindow(null)} 
    character={characterData} 
    user={userData}
  />
)}
{(userData && currentUser) && (
  <MemorySidebar 
    isOpen={activeWindow === 'memory'} 
    handleClose={() => setActiveWindow(null)} 
    user={userData}
  />
)}
        <div className="container">
    <div className="chat" onContextMenu={handleRightClick}>
<div className="chatInfo">
<div>
  <FontAwesomeIcon
    className="back"
    icon={faChevronLeft} // Make sure this is the correct name for the icon you want to use from FontAwesome
    onClick={handleBack}
  />
</div>
{characterData ? (
  characterData.idleurl ? (
    <video autoPlay loop playsInline muted className="photo" onClick={() => setShowRelationshipStatusModal(true)} type="video/mp4">
      <source src={characterData.idleurl} />
    </video>
  ) : (
    <img className="photo" src={characterData.photoURL} alt="" onClick={() => setShowRelationshipStatusModal(true)}/>
  )
) : (
  <div></div>
)}
<XPBar xp={userData.xp ? userData.xp : 0} maxXP={userData.maxXP ? userData.maxXP: 100} level={userData && userData.level ? userData.level : 1} characterName={characterData?.displayName}/>
{<GemCounter />}
{!isMobile && (
  <>
    <div className="options">
      <Tooltip title="Advanced AI">
        <div className="advancedAI">
          <img src="https://1637693156.rsc.cdn77.org/web/main-page/advanced_ai@2x.png" alt="Advanced AI"/>
          <Switch checked={userData?.subscription === "Ultimate Plan"}onChange={() => {
    if (userData?.subscription !== "Ultimate Plan") {
        if (currentUser.isAnonymous) {
            handleClickWithoutLogin();
        } else {
            setShowAdvancedAIModal(true);
        }
    }
}} />
        </div>
      </Tooltip>
    </div>
  </>
)}
  <button className="burger-menu" onClick={() => setShowChatMenu(true)}>
    <FontAwesomeIcon icon={faBars} />
  </button>
</div>
{sessionId && (<Messages />) }
{/*
<button onClick={toggleVisibility}>Toggle Characters</button>*/}
      <UnlockedCharacters characters={filteredContent} visibility={showNewLevelModal} onClose={() => setShowNewLevelModal(false)} level={userData.level} />
{sessionId && <Input resetChatFunc={ResetChat}/>}
</div>

      <SubscriptionModal show={showSubscriptionModal} handleClose={() => setShowSubscriptionModal(false)} character={characterData} />
      <AdvancedAIModal show={showAdvancedAIModal} handleClose={() => setShowAdvancedAIModal(false)} character={characterData} />
      
<Modal
  title="INFORMATION"
  visible={showVerification}
  onCancel={() => setShowVerification(false)}
  footer={[
    <Button key="submit" type="primary" href="/shop">
      SUBSCRIBE HERE
    </Button>,
  ]}
>
<p>
  Sorry, this content is currently unavailable to you. To access explicit content, please consider obtaining a 
  <a href="/shop"> subscription</a>. Your waifus are eagerly waiting for you!
</p>
</Modal>

<Modal
  title="INFORMATION"
  visible={showModal}
  onOk={BuyImage}
  onCancel={() => setShowModal(false)}
  footer={[
    <Button key="back" onClick={() => setShowModal(false)}>
      No
    </Button>,
    <Button key="submit" type="primary" onClick={BuyImage}>
      Yes
    </Button>,
  ]}
>
  <p>
    Do you want to unlock the picture for 5
    <img className="textgem" src={gem} alt=""></img>
  </p>
  <p className="subscriptionInfo">
    <Link to="/shop">Subscriptions</Link> provide unrestricted access to all content at no additional cost.
  </p>
</Modal>
<Modal
  title="INFORMATION"
  onOk={BuyMessage}
  onCancel={() => setShowMessageModal(false)}
  footer={[
    <Button key="back" onClick={() => setShowMessageModal(false)}>
      No
    </Button>,
    <Button key="submit" type="primary" onClick={BuyMessage}>
      Yes
    </Button>,
  ]}
>
  <p>
    Would you like to unlock the message for 10
    <img className="textgem" src={gem} alt=""></img>
  </p>
  <p className="subscriptionInfo">
    <Link to="/shop">Subscriptions</Link> provide unrestricted access to all content at no additional cost.
  </p>
</Modal>
<SubscriptionModal show={showMessageModal} handleClose={() => setShowMessageModal(false)} character={characterData} />

<Modal
  title="WARNING"
  visible={showErr}
  onCancel={() => setShowErr(false)}
  footer={[
    <Button key="back" onClick={() => setShowErr(false)}>
      OK
    </Button>,
  ]}
>
  <p>
    Oops! An error has occurred. Please try again.
  </p>
</Modal>

<NoGemsModal 
  visible={showWarning} 
   onCancel={() => setShowWarning(false)} 
/>

<SignInOut 
  isVisible={showSignUp}
  onClose={() => setShowSignUp(false)}
  initialForm="register"
  displayType={displayType}
/>
    </div>
</div>
    )}
    {fullScreenImageSrc && (
      <div className="fullscreen-image-container">
        <img src={fullScreenImageSrc} alt="Full Screen" />
        <FontAwesomeIcon
          icon={faCompress}
          className="image-resize-icon"
          onClick={() => setFullScreenImageSrc(null)}
        />
      </div>
    )}
{characterData &&<RelationshipStatusModal isOpen={showRelationshipStatusModal} onClose={()=> setShowRelationshipStatusModal(false)} avatar={characterData.photoURL} characterName={characterData.displayName} onChangeStatus={() => setShowSubscriptionModal(true)} isSubscribed={userData.subscription !== "None"}/>}
</>
  );
};

export default Chat;

import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { collection, query, onSnapshot, doc, setDoc, getDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Spin, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import Character from "../../components/Voting/Character";
import CharacterFormModal from '../../components/Voting/CharacterFormModal';
import CountdownComponent from '../../components/Voting/CountdownComponent';
import MainNav from "../../components/Common/MainNav";
import WinnerCard from '../../components/Voting/Winnercard';
import { AuthContext } from '../../context/AuthContext';
import { db, auth, functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import gemsImage from "../../img/shopPromo/7500gems.png";
import gemsImage2 from "../../img/shopPromo/3750gems.png";
import discordGif from "../../img/shopPromo/Discord.gif";

const VotingCharacters = () => {
  const [filter, setFilter] = useState('popular');
  const { currentUser, userData } = useContext(AuthContext);
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCharacterForm, setShowCharacterForm] = useState(false);
  const [error, setError] = useState(null);
  const [countdownEnded, setCountdownEnded] = useState(false);
  const [winner, setWinner] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [leaderboardLoading, setLeaderboardLoading] = useState(true);
  const navigate = useNavigate();

  const filterOptions = {
    NEW: 'new',
    POPULAR: 'popular',
    TRENDING: 'trending',
    MY_SUBMISSIONS: 'mySubmissions',
    HALL_OF_FAME: 'hallOfFame',
  };

  const handleVote = useCallback(async (characterId, voteType) => {
    if (!auth.currentUser) {
      message.error("You need to be logged in to vote.");
      return;
    }

    try {
      const characterRef = doc(db, 'votingCharacters', characterId);
      const characterSnap = await getDoc(characterRef);

      if (characterSnap.exists()) {
        const characterData = characterSnap.data();
        const updatedVotes = {
          upvotes: (characterData.votes?.upvotes || 0) + (voteType === 'upvote' ? 1 : 0),
          downvotes: (characterData.votes?.downvotes || 0) + (voteType === 'downvote' ? 1 : 0),
        };

        await setDoc(characterRef, { 
          votes: updatedVotes,
          lastBoostBy: auth.currentUser.uid // Add this line to track who last boosted
        }, { merge: true });

        const allVotersRef = collection(db, 'AllCharacterVoters');
        await addDoc(allVotersRef, {
          characterId,
          userId: auth.currentUser.uid,
          voteType,
          timestamp: serverTimestamp(),
        });

        message.success("Vote registered successfully!");
        fetchLeaderboardData(); // Refresh leaderboard data after voting
      } else {
        message.error("Character not found.");
      }
    } catch (error) {
      message.error("An error occurred while voting. Please try again.");
    }
  }, []);

  const fetchCharacters = useCallback(async () => {
    setError(null);
    setLoading(true);

    try {
      const charactersQuery = query(collection(db, 'votingCharacters'));

      const unsubscribe = onSnapshot(charactersQuery, (charactersSnapshot) => {
        const charactersList = charactersSnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));

        setCharacters(charactersList);
        setLoading(false);
      }, (error) => {
        setLoading(false);
        setError(`Error fetching characters: ${error.message}`);
      });

      return () => unsubscribe();
    } catch (error) {
      setLoading(false);
      setError(`Error fetching characters: ${error.message}`);
    }
  }, []);

  const fetchLeaderboardData = useCallback(async () => {
    setLeaderboardLoading(true);
    try {
      const getLeaderboardData = httpsCallable(functions, 'getLeaderboardData');
      const result = await getLeaderboardData();
      setLeaderboardData(result.data);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      setError("Failed to fetch leaderboard data");
    } finally {
      setLeaderboardLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCharacters();
    fetchLeaderboardData();
    const intervalId = setInterval(fetchLeaderboardData, 5 * 60 * 1000); // Refresh every 5 minutes
    return () => clearInterval(intervalId);
  }, [fetchCharacters, fetchLeaderboardData]);

  const addWinnerToFirestore = async (winnerCharacter) => {
    let retries = 3;
    while (retries > 0) {
      try {
        const winnerData = {
          ...winnerCharacter,
          winDate: serverTimestamp(),
          votingRoundEnd: serverTimestamp(),
        };

        await setDoc(doc(db, 'winners', winnerCharacter.id), winnerData, { merge: true });
        message.success("Winner saved successfully!");
        return;
      } catch (error) {
        retries--;
        if (retries === 0) {
          if (error.code === 'permission-denied') {
            setError("You don't have permission to save the winner.");
          } else {
            setError(`Failed to save the winner. Error: ${error.message}`);
          }
          message.error("Failed to save the winner. Please try again.");
        }
      }
    }
  };

  useEffect(() => {
    if (countdownEnded && characters.length > 0) {
      const maxVotesCharacter = characters.reduce((max, character) => {
        const maxVotes = max?.votes?.upvotes - max?.votes?.downvotes;
        const characterVotes = character?.votes?.upvotes - character?.votes?.downvotes;

        return characterVotes > maxVotes ? character : max;
      }, characters[0]);

      if (maxVotesCharacter) {
        setWinner(maxVotesCharacter);
        addWinnerToFirestore(maxVotesCharacter);
      } else {
        setError("Unable to determine a winner. Please try again.");
      }
    }
  }, [countdownEnded, characters]);

  const handleFilterChange = (newFilter) => {
    if (newFilter === filterOptions.HALL_OF_FAME) {
      navigate('/hall-of-fame');
    } else {
      setFilter(newFilter);
    }
  };

  const renderedCharacters = useMemo(() => {
    let filteredCharacters = [...characters];

    filteredCharacters.forEach(character => {
      const hoursSincePosted = (Date.now() - new Date(character.creationDate).getTime()) / 1000 / 60 / 60;
      const voteScore = character.votes.upvotes - character.votes.downvotes;
      character.trendingScore = voteScore / (hoursSincePosted || 1);
    });

    switch (filter) {
      case filterOptions.NEW:
        filteredCharacters.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
        break;
      case filterOptions.POPULAR:
        filteredCharacters.sort((a, b) => (b.votes.upvotes - b.votes.downvotes) - (a.votes.upvotes - a.votes.downvotes));
        break;
      case filterOptions.TRENDING:
        filteredCharacters.sort((a, b) => b.trendingScore - a.trendingScore);
        break;
      case filterOptions.MY_SUBMISSIONS:
        filteredCharacters = filteredCharacters.filter(
          (character) => character.createdBy === userData?.uid
        );
        filteredCharacters.sort((a, b) => (b.votes.upvotes - b.votes.downvotes) - (a.votes.upvotes - a.votes.downvotes));
        break;
      default:
        filteredCharacters.sort((a, b) => (b.votes.upvotes - b.votes.downvotes) - (a.votes.upvotes - a.votes.downvotes));
        break;
    }

    return filteredCharacters;
  }, [characters, filter, userData]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthChecked(true);
      if (user) {
        console.log("User is signed in:", user);
      } else {
        console.log("No user is signed in.");
      }
    });

    return () => unsubscribe();
  }, []);

  const renderLeaderboardContent = () => (
    <div className="top-character-row">
      {/* First Leaderboard Container (Left) */}
      <div className="leaderboard-container left-leaderboard">
        <h3>Top Creator 🏆</h3>
        {leaderboardLoading ? (
          <Spin size="small" />
        ) : leaderboardData?.topCreator ? (
          <div className="creatorItem">
            <span className="rank" style={{ fontSize: "1.5em" }}>🥇</span>
            <span className="creatorName">{leaderboardData.topCreator.userName}</span>
            <span className="creatorScore">{leaderboardData.topCreator.votes} votes</span>
          </div>
        ) : (
          <p>No top creator yet.</p>
        )}
        <div className="prizes">
          <p className="gems">4000 Gems!</p>
          <img src={gemsImage} alt="Gems" className="gems-image" />
          <p className="plus">+</p>
          <img src={discordGif} alt="Discord" className="discord-gif" />
          <p className="discord-role">Discord Role:</p>
          <p className="discord-role">Legend - Creator</p>
        </div>
      </div>

      {/* Character Container (Middle) */}
      <div className="top-character-container">
        {renderedCharacters.length > 0 && (
          <Character
            key={renderedCharacters[0].id}
            character={renderedCharacters[0]}
            rank={1}
            onVote={handleVote}
          />
        )}
      </div>

      {/* Second Leaderboard Container (Right) */}
      <div className="leaderboard-container right-leaderboard">
        <h3>Top Voters 🚀</h3>
        {leaderboardLoading ? (
          <Spin size="small" />
        ) : leaderboardData?.topVoters?.length > 0 ? (
          leaderboardData.topVoters.map((voter, index) => (
            <div key={voter.userId} className="creatorItem">
              <span className="rank" style={{ fontSize: "1.5em" }}>{['🥇', '🥈', '🥉'][index]}</span>
              <span className="creatorName">{voter.userName}</span>
              <span className="creatorScore">{voter.totalBoosts} boosts</span>
            </div>
          ))
        ) : (
          <p>No top voters yet.</p>
        )}
        <div className="prizes">
          <p className="gems">3250 Gems!</p>
          <img src={gemsImage2} alt="Gems" className="gems-image2" />
          <p className="plus">+</p>
          <img src={discordGif} alt="Discord" className="discord-gif" />
          <p className="discord-role">Discord Role:</p>
          <p className="discord-role">Legend - Voter</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="mainWrapper">
      <MainNav />
      <div className="contentWrapper">
        {!authChecked ? (
          <p>Checking authentication status...</p>
        ) : (
          <>
            <h1 className="headline">The Countdown Is On</h1>
            <h2 className="subheadline">First placed waifu in TOP 🏆 will be added to the platform when the countdown ends!</h2>
            <CountdownComponent onEnd={() => setCountdownEnded(true)} />
            <button className="button addCharacterBtn" onClick={() => setShowCharacterForm(true)}>Post Waifu</button>
            <div className="filterBtmContainer">
              <button
                className={`filterButton ${filter === filterOptions.POPULAR ? 'active' : ''}`}
                onClick={() => handleFilterChange(filterOptions.POPULAR)}
              >
                TOP 🏆
              </button>
              <button
                className={`filterButton ${filter === filterOptions.NEW ? 'active' : ''}`}
                onClick={() => handleFilterChange(filterOptions.NEW)}
              >
                Recent
              </button>
              <button
                className={`filterButton ${filter === filterOptions.TRENDING ? 'active' : ''}`}
                onClick={() => handleFilterChange(filterOptions.TRENDING)}
              >
                Trending
              </button>
              <button
                className={`filterButton ${filter === filterOptions.MY_SUBMISSIONS ? 'active' : ''}`}
                onClick={() => handleFilterChange(filterOptions.MY_SUBMISSIONS)}
              >
                My Submissions
              </button>
              <button
                className="filterButton"
                onClick={() => handleFilterChange(filterOptions.HALL_OF_FAME)}
              >
                Hall of Fame
              </button>
            </div>
            {error && (
              <div style={{ color: 'red', margin: '10px 0', padding: '10px', backgroundColor: 'rgba(255,0,0,0.1)' }}>
                <p>Error: {error}</p>
                {winner && <button onClick={() => addWinnerToFirestore(winner)}>Retry Saving Winner</button>}
              </div>
            )}
            {loading ? (
              <div style={{ justifyContent: "center", display: "flex", marginTop: "3rem" }}>
                <Spin size="large" />
              </div>
            ) : !countdownEnded ? (
              <>
                {renderLeaderboardContent()}
                <div className="remaining-characters">
                  {renderedCharacters.slice(1).map((character, index) => (
                    <Character
                      key={character.id}
                      character={character}
                      rank={index + 2}
                      onVote={handleVote}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <WinnerCard winner={winner} />
              )}
            </>
          )}
          <CharacterFormModal
            show={showCharacterForm}
            handleClose={() => setShowCharacterForm(false)}
          />
        </div>
      </div>
    );
  };
  
  export default React.memo(VotingCharacters);
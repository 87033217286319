import React, { useState, useEffect, useRef, useContext } from 'react';
import { useMicVAD } from "@ricky0123/vad-react";
import axios from 'axios';
import { ChatContext } from "../../context/ChatContext";
import { handleAIResponse, handleAISendStream, TtsToAudio } from "../../AIConnector";
import {
    arrayUnion,
    doc,
    Timestamp,
    updateDoc
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase";
import '../../styles/CallInterface.scss';
import idleSound from '../../img/idle-sound.mp3'; // Import the idle sound file
import { getStableDiffusionImage } from '../../stableDiffusionConnector';

const AudioRecorder = () => {
    const [transcript, setTranscript] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false); // State to track ongoing requests
    const [isMuted, setIsMuted] = useState(false); // State to track if the mic is muted
    const audioChunksRef = useRef([]);
    const mediaRecorderRef = useRef(null);
    const audioStreamRef = useRef(null);
    const audioRef = useRef(null); // Reference for the audio element
    const idleAudioRef = useRef(null); // Reference for the idle audio element
    const [audioUrl, setAudioUrl] = useState('');
    const [responseText, setResponseText] = useState('');  // For displaying AI text responses
    const [imageLink, setImageLink] = useState(''); // State for image link
    const [isAIResponsePlaying, setIsAIResponsePlaying] = useState(false);

    const { setShowErr, characterData, chatID } = useContext(ChatContext);
    const { currentUser, userData } = useContext(AuthContext);

    // Function to handle fetching and releasing the audio stream
    const getAudioStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioStreamRef.current = stream;
            return stream;
        } catch (error) {
            console.error("Error accessing the microphone: ", error);
        }
    };

    const playResponseAudio = async (text) => {
        try {
            setIsAIResponsePlaying(true);
            const audioLink = await TtsToAudio(text);
            setAudioUrl(audioLink);
            if (audioRef.current) {
                audioRef.current.src = audioLink;
                audioRef.current.play();
                audioRef.current.onended = () => setIsAIResponsePlaying(false);
            }
        } catch (error) {
            console.error("Error playing response audio: ", error);
            setIsAIResponsePlaying(false);
        }
    };

    const stopIdleSound = () => {
        if (idleAudioRef.current) {
            idleAudioRef.current.pause();
            idleAudioRef.current.currentTime = 0;
        }
    };

    useEffect(() => {
        console.log(characterData);
    }, [characterData]);

    // Function to start the media recording
    const startRecording = async () => {
        if (isAIResponsePlaying) {
            console.log("AI is speaking, not recording user input.");
            return;
        }
        if (isMuted) {
            console.log("Microphone is muted.");
            return;
        }
        stopIdleSound();
        if (!audioStreamRef.current) {
            await getAudioStream();
        }
        const mediaRecorder = new MediaRecorder(audioStreamRef.current);
        mediaRecorder.ondataavailable = event => {
            if (event.data.size > 0) {
                audioChunksRef.current.push(event.data);
            }
        };
        mediaRecorder.start();
        mediaRecorderRef.current = mediaRecorder;
        setIsRecording(true);
        console.log('Recording started');
    };

    const handleTextSend = async (text) => {
        try {
            const messageObj = {
                id: uuid(),
                text,
                senderId: currentUser.uid,
                date: Timestamp.now(),  // Firebase server timestamp for the message
                locked: false
            };

            console.log("arrayUnion", messageObj);

            await updateDoc(doc(db, "chats", chatID), {
                messages: arrayUnion(messageObj),
                lastUpdated: Timestamp.now()  // Update the lastUpdated field
            });

        } catch (err) {
            console.error(`Error sending text message: ${err.message}`);
            // Ask the user if they want to retry
            if (window.confirm("There was an error sending the text message. Do you want to retry?")) {
                handleTextSend(text);  // recursive call
            }
        }
    };

    const handleTranscriptsend = async (transcript) => {
        if (isRequesting) {
            console.warn("A request is already in progress");
            return;
        }

        setIsRequesting(true);
        await handleTextSend(transcript);

        try {
            const { text: aiAnswer, imageContent } = await handleAISendStream({
                chatId: chatID,
                character: characterData,
                currentUser: currentUser,
                userData: userData,
                setRequesting: setShowErr,
                setLastPrompt: setShowErr,
                setShowErr: setShowErr,
                story: ""
            });

            const [audioLink, imageLink] = await Promise.all([
                TtsToAudio(aiAnswer),
                getStableDiffusionImage("((1girl)) anime masterpiece, high quality, highly detailed, " + ", anime, hentai style (" + imageContent + ")")
            ]);

            setAudioUrl(audioLink);
            setImageLink(imageLink); // Set the image link state
            setResponseText(aiAnswer);
            setTranscript(aiAnswer);

            if (audioRef.current) {
                audioRef.current.src = audioLink;
                audioRef.current.play();
            }
        } catch (error) {
            console.error("Error processing request: ", error);
        } finally {
            setIsRequesting(false);
        }
    };

    // Function to stop the media recording and process the audio
    const stopRecording = () => {
        setIsRecording(false);
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                console.log('Blob size:', audioBlob.size);
                if (audioBlob.size > 0) {
                    await sendAudioToFirebase(audioBlob);
                } else {
                    console.error('Empty audio blob.');
                }
                audioChunksRef.current = [];
                // Cleanup the stream
                if (audioStreamRef.current) {
                    audioStreamRef.current.getTracks().forEach(track => track.stop());
                    audioStreamRef.current = null;
                }
            };
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current = null;
        }
    };

    // Function to send audio data to a server (example: Firebase)
    const sendAudioToFirebase = async (audioBlob) => {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.webm');
        const response = await axios.post('http://127.0.0.1:3001/oniichat-2c310/us-central1/transcribeAudio', formData);
        const transcriptData = response.data.text;
        await handleTranscriptsend(transcriptData);
    };

    // Function to mute/unmute the microphone
    const toggleMute = () => {
        setIsMuted(!isMuted);
        if (audioStreamRef.current) {
            audioStreamRef.current.getAudioTracks().forEach(track => {
                track.enabled = !track.enabled;
            });
        }
    };

    // Using the useMicVAD hook to manage voice activity detection
    const vad = useMicVAD({
        onVADMisfire: () =>{
            stopRecording()
        },
        startOnLoad: true,
        workletURL: 'http://localhost:3000/static/js/vad.worklet.bundle.min.js',
        onSpeechStart: () => {
            console.log('Speech start detected');
            if (!isRecording && !isAIResponsePlaying) startRecording();
        },
        onSpeechEnd: () => {
            console.log('Speech end detected');
            if (isRecording) stopRecording();
        },
        minSpeechDuration: 1000,
        minSilenceDuration: 500,
        minTimeBeforeRecording: 1000 // Ensures 1 second before VAD is included
    });

    return (
        <div className="call-interface">
            <div className="profile-section">
                <img src={characterData && characterData.photoURL} alt="Character Avatar" className="character-avatar" />
                <div className="character-info">
                    <h2>{characterData && characterData.displayName}</h2>
                    <p>{characterData && characterData.description}</p>
                </div>
            </div>
            <div className="video-container">
                {imageLink ? (
                    <img src={imageLink} alt="Generated Content" className="generated-image" />
                ) : (
                    <img src={characterData && characterData.photoURL} alt="Character" className="character-image" />
                )}
                <div className="call-overlay">
                    <div className="call-status">
                        <span className={`status-icon ${isRecording ? 'on-call' : 'idle'}`}></span>
                        <p>{isRecording ? 'On Call' : 'Idle'}</p>
                    </div>
                    <div className="call-actions">
                        <button className={`call-button ${isRecording ? 'end-call' : 'start-call'}`}>
                          End Stream
                        </button>
                        <button className="mute-button" onClick={toggleMute}>
                            {isMuted ? 'Unmute Mic' : 'Mute Mic'}
                        </button>
                    </div>
                </div>
            </div>
            <div className="last-message">
                <p>{responseText}</p>
            </div>
            <audio ref={audioRef} />
        </div>
    );
};

export default AudioRecorder;

import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Container, Nav, Navbar } from 'react-bootstrap';

import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  FieldValue                               
} from "firebase/firestore";
import logo from "../../img/Oniichat_Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faHeart, faUser, faArrowUp, faComments } from "@fortawesome/free-solid-svg-icons";
import card from "../../img/credit-card.png";
import logout from "../../img/logout.png";
import GemCounter from "../Common/GemCounter";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { AuthContext } from "../../context/AuthContext";
import {signOut} from "firebase/auth";
import { auth, db, AuthErrors} from "../../firebase";
import {logEvent} from "../../firebase";
import { ChatContext } from "../../context/ChatContext";
import { faLock, faStar} from "@fortawesome/free-solid-svg-icons";
import NextGemRechargeCounter from "./NextGemRechargeCounter";
import { notification } from 'antd';
import UnlockedFeature from "../UnlockedFeature";
const MainNav = () => {
  const [pendingPopupShown, setPendingPopupShown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showCheckCancel, setShowCheckCancel] = useState(false)
  const [showCheckLogout, setShowCheckLogout] = useState(false)
  const [showSettings, setShowSettings] = useState(false);
  const [showUnlockCustom, setShowUnlockCustom] = useState(false)
  const navigate = useNavigate();
  const location = useLocation(); // get location object
  const { currentUser, userData } = useContext(AuthContext);


  useEffect(() => {
    if (userData && !pendingPopupShown && userData.subscription_pending) {
      Modal.warning({
        title: 'Subscription Payment Pending',
        content: (
          <>
            <p>Your subscription payment has not been made. Please complete your payment on <a href="https://www.paypal.com/" target="_blank" rel="noreferrer">PayPal</a>. Until this payment is processed, you will not be able to fully utilize the features of this site.</p>
            <p>If your payment has been completed and you are still seeing this message, there might be a delay in updating your subscription status. Please be patient as this could take a few minutes.</p>
            <p>For any concerns, feel free to reach out to us via our <a href="https://discord.gg/Se96PwTVm5" target="_blank" rel="noreferrer">Discord</a> channel or send an email to <a href="mailto:support@oniichat.com">support@oniichat.com</a>.</p>
          </>
        ),
        okButtonProps: { disabled: true }, // Disable the OK button
        closable: false, // Make the modal not closable
      });
      setPendingPopupShown(true);
    }
  }, [userData]);

  const openNotification = () => {
    notification.open({
      message: 'Congratulations!',
      description: 'You have successfully unlocked the your waifu function!',
      className: 'waifu-notification', // Custom class for styling
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };
  
    // Debounce function for setIsOpen
    const debounce = (func, delay) => {
      let timer;
      return function(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, delay);
      };
    };
  
    // Debounced version of setIsOpen
    const debouncedSetIsOpen = debounce(setIsOpen, 100);
  
    // Handler for Navbar Toggle
    const handleNavbarToggle = () => {
      debouncedSetIsOpen(!isOpen);
    };
    
  const cancelSubscription = async () => {
    setShowCheckCancel(false)
    console.log("user", currentUser)
    if(!userData.subscription_id)
    {
      return
    }
    try{
      const userDoc = await getDoc(doc(db, "users", currentUser.uid));
      const userData = userDoc.data()
      const data = {
        subscription_id: userData.subscription_id,
        uid: currentUser.uid
      }
      const res = await fetch('https://fathomless-gorge-94679.herokuapp.com/api/cancelsubscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data) 
    });
  }
  catch(err)
  {
    console.log(err)
  }
    }
  const StartPage = (page) => {
    navigate("/" + page)
    logEvent(`clicked_${page}`);
  };
  useEffect(() => {
    if (currentUser && userData && !userData.availableGenerations && !currentUser.isAnonymous) {
      // Ensure the notification is only shown once by checking a condition or a state
      if (!sessionStorage.getItem('waifuNotificationShown')) {
        setShowUnlockCustom(true)
        // Set a flag in sessionStorage to not show the notification again
        sessionStorage.setItem('waifuNotificationShown', 'true');
      }
    }
  }, [userData]);
  return (
    <>
          <UnlockedFeature visibility={false} onClose={() => setShowUnlockCustom(false)}/>
<Navbar id='mainNavbar' fixed="top" expand='lg' variant='dark' bg='primary' fluid={true} className="mainNav">
  <Container fluid>
    <Navbar.Brand href='/'>
      <img src={logo} height='50' alt='' loading='lazy' />
    </Navbar.Brand>
    <Navbar.Toggle
            aria-label='Toggle navigation'
            onClick={handleNavbarToggle}
          >
      <span className='navbar-toggler-icon'></span>
    </Navbar.Toggle>
    <Navbar.Collapse show={isOpen} className='navCollapse'>
      <Nav className='mr-auto mb-2 mb-lg-0'>
        <Nav.Link onClick={() => StartPage('')} active={location.pathname === '/' || location.pathname === '/selectchat'} className='nav-link'>
          <FontAwesomeIcon icon={faHome} className='nav-icon' /> 
          <span>Home</span>
        </Nav.Link>
        {/* The most important links are grouped at the beginning 
                <Nav.Link onClick={() => StartPage('selectchat')} active={location.pathname === '/selectchat'} className='nav-link'>
          <FontAwesomeIcon icon={faHeart} className='nav-icon' /> 
          <span>Waifus</span>
        </Nav.Link>*/}
       {currentUser && !currentUser.isAnonymous &&  (<Nav.Link onClick={() => StartPage('yourWaifu')} active={location.pathname === '/yourWaifu' || location.pathname === '/customProfilePage'} className='nav-link'>
        <FontAwesomeIcon icon={faStar} className='nav-icon' /> 
            <span>Your Waifu (Beta)</span>
        </Nav.Link>
        
        )}
        <Nav.Link 
  onClick={() => StartPage('shop')} 
  active={location.pathname === '/shop'} 
  className='nav-link'
>
  <FontAwesomeIcon icon={faArrowUp} className='nav-icon' /> 
  <span>Upgrade</span>
</Nav.Link>
       {currentUser && !currentUser.isAnonymous &&  (<Nav.Link onClick={() => StartPage('featurevoting')} active={location.pathname === '/featurevoting'} className='nav-link'>
        <FontAwesomeIcon icon={faStar} className='nav-icon' /> 
            <span>Feature Community Voting</span>
        </Nav.Link>
        
        )}
        {currentUser && !currentUser.isAnonymous ? (
          <> 
            {/* The Profile link is placed at the end */}
{/*<Nav.Link 
  onClick={() => StartPage('community')} 
  active={location.pathname === '/community'} 
  className='nav-link'
>
  <FontAwesomeIcon icon={faComments} className='nav-icon' /> 
  <span>Community</span>
</Nav.Link>*/}
            <Nav.Link onClick={() => StartPage('profile')} active={location.pathname === '/profile'} className='nav-link'>
              <FontAwesomeIcon icon={faUser} className='nav-icon' /> 
              <span>Profile</span>
            </Nav.Link>

          </>
        ): (
          currentUser && (
      <Nav.Link onClick={() => navigate("/login")} className='nav-link'>
        <FontAwesomeIcon icon={faUser}  className='nav-icon' /> Login
      </Nav.Link>)
    )}
      </Nav>
      {userData && <GemCounter/>}
      {userData && userData.subscription !== "None" && userData.plan_purchase_date && <NextGemRechargeCounter userData={userData} />}
        {/* New addition: Conditional login button */}
  {/* New addition: Conditional login button */}
    </Navbar.Collapse>
  </Container>
</Navbar>

<Modal show={showSettings} onHide={() => setShowSettings(false)} tabIndex="-1" className="settings">
      <Modal.Header>
        <Modal.Title>Settings</Modal.Title>
        <Button className="btn-close" variant="none" onClick={() => setShowSettings(false)}></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="settingsButton" onClick={() => setShowCheckLogout(true)}>
          <img src={logout} alt=""></img>Logout
        </div>
        <div className="settingsButton" onClick={() => setShowCheckCancel(true)}>
          <img src={card} alt=""></img>Cancel subscription
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowSettings(false)}>OK</Button>
      </Modal.Footer>
</Modal>
 <Modal show={showCheckCancel} setShow={setShowCheckCancel} tabIndex='-1'>
            <Modal.Header>
              <Modal.Title>INFORMATION</Modal.Title>
              <Button className='btn-close' color='none' onClick={() => setShowCheckCancel(false)}></Button>
            </Modal.Header>
            <Modal.Body>Do you really want to cancel your subscription</Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShowCheckCancel(false)}>
                No
              </Button>
              <Button  variant='primary' onClick={() => cancelSubscription()}>Yes</Button>
            </Modal.Footer>
      </Modal>
      <Modal show={showCheckLogout} setShow={setShowCheckLogout} tabIndex='-1'>
            <Modal.Header>
              <Modal.Title>INFORMATION</Modal.Title>
              <Button className='btn-close' color='none' onClick={() => setShowCheckLogout(false)}></Button>
            </Modal.Header>
            <Modal.Body>Do you really want to logout</Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShowCheckLogout(false)}>
                No
              </Button>
              <Button  variant='primary' onClick={() => logout()}>Yes</Button>
            </Modal.Footer>
      
      </Modal>
    </>
  );
};
export default MainNav;
/*         <Nav.Link href='' onClick={() => StartPage('imagine')}>
          Imagine
        </Nav.Link>*/
import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from 'react-router-dom';
import { BACKEND_URL } from "../../constants";

import {
    collection,
    query,
    where,
    getDocs,
    setDoc,
    doc,
    updateDoc,
    serverTimestamp,
    getDoc,
    onSnapshot,
    FieldValue,
    addDoc
} from "firebase/firestore";
import "../../styles/Checkout.scss"
import { db, logEvent } from "../../firebase";
import { useParams } from "react-router-dom";
import { getAnalytics } from "firebase/analytics";
import { message, Collapse } from "antd";
import * as Sentry from "@sentry/react";
const { Panel } = Collapse;
const Checkout = () => {
    const location = useLocation();
    const [showFeedback, setShowFeedback] = useState(false);
    const { productName, productPrice, type, productID } = useParams();
    const { currentUser, userData } = useContext(AuthContext);
    const [loading, setLoading] = useState(false)
    const UID = useRef(null);
    const navigate = useNavigate()
    useEffect(() => {
        if (currentUser && currentUser.uid !== undefined) {
            console.log('aksjdhkjasdaskd', currentUser)
            const uid = currentUser.uid;
            console.log(uid);
            UID.current = uid;
        }
    }, [currentUser]);

    // Function to log PayPal button click
    const logPayPalButtonClick = () => {
        console.log("paypal button click")
        logEvent('paypal_click_' + productName, {
            product_name: productName,
            product_id: productID,
        });
    };
    // creates a paypal order
    const createOrder = (data, actions) => {
        console.log("uid: ", UID);
        return actions.order
            .create({
                intent: "CAPTURE",
                purchase_units: [
                    {
                        description: productName,
                        amount: {
                            value: productPrice,
                            currency_code: "USD",
                        },
                        custom_id: UID.current,
                    },
                ],
                redirect_urls: {
                    return_url: 'https://oniichat.com/shop'
                },
                // not needed if a shipping address is actually needed
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
    };
    const savePurchaseInfo = async (details, type) => {
        try {
            const dashboardCollection = collection(db, "dashboard");
            const userRef = doc(db, "users", UID.current);
            const userDoc = await getDoc(userRef);
            const userData = userDoc.data();

            const purchaseInfo = {
                userId: UID.current,
                productName: productName,
                productPrice: productPrice,
                transactionId: details ? (details.id) :(""),
                purchaseDate: new Date(),
                type: type,
                lastChatID: userData.lastChatID || null,
                messages: []
            };

            // If lastChatID exists, fetch the messages
            if (userData.lastChatID) {
                const chatRef = doc(db, "chats", userData.lastChatID);
                const chatDoc = await getDoc(chatRef);
                if (chatDoc.exists()) {
                    purchaseInfo.messages = chatDoc.data().messages || [];
                }
            }

            await addDoc(dashboardCollection, purchaseInfo);
            console.log("Purchase info saved to dashboard collection");
        } catch (error) {
            console.error("Error saving purchase info to dashboard:", error);
            Sentry.captureException(error);
        }
    }
    // set
    // set up the recurring transaction
    const createSubscription = (data, actions) => {
        // replace with your subscription plan id
        // https://developer.paypal.com/docs/subscriptions/#link-createplan

        return actions.subscription.create({
            intent: "subscription",
            name: productName,
            plan_id: productID,
            custom_id: UID.current
        });
    }
    // check Approval
    const onApprove = (data, actions) => {
        console.log("approved")

        return actions.order.capture().then(function (details) {
            const { payer } = details;
            console.log(details)
            localStorage.setItem("paymentProcessing", "true")
            // Save purchase info to dashboard collection
            savePurchaseInfo(details, 'one-time');
            // log purchase event
            logEvent('purchase', {
                value: productPrice,
                currency: 'USD',
                transaction_id: details.id,
                product_name: productName,
                product_id: productID,
                userId: UID.current,
                type: type, // 'subscription' or otherwise
            });
            window.trackTwitterConversion('purchaseGems',productPrice);

            alert("Thanks for purchasing!")
            navigate("/shop")
        });
    };
    const subscribeWithPayabl = async () => {
        setLoading(true)
        let obj = {
            email: currentUser?.email,
            amount: productPrice,
            plan: productName,
            uid: currentUser?.uid,
        }
        let source = userData?.FormData?.source
        let subid = userData?.FormData?.subid
        if (source && subid && source !=="none" && subid !== "none") {
            obj['source'] = source
            obj['subid'] = subid
        }
        const res = await fetch(`${BACKEND_URL}/api/pay-with-payabl-sub`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(obj)
        });

        let data = await res?.json()
        if (data?.data) {
            setLoading(false)
            window.open(data?.data)
        }
    }
    const payWithPayabl = async () => {
        setLoading(true)

        let obj = {
            email: currentUser?.email,
            amount: productPrice,
            gems: productName,
            uid: currentUser?.uid,
        }

        const searchParams = new URLSearchParams(location.search);
        let source = userData?.FormData?.source
        let subid = userData?.FormData?.subid
        console.log('source', source, subid)
        if (source && subid && source !=="none" && subid !== "none") {
            obj['source'] = source
            obj['subid'] = subid
        }

        const res = await fetch(`${BACKEND_URL}/api/pay-with-payabl`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(obj)
        });

        let data = await res?.json()

        if (data?.data) {
            setLoading(false)

            console.log('ajksdkdhkjsad', currentUser, productPrice, data?.data)

            window.open(data?.data)
        }

    }
    const cancelSubscription = async () => {

        if (!currentUser.isAnonymous) {
            throw new Error("You must be logged in to cancel your subscription");
        }
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        const userData = userDoc.data();

        if (!userData || userData.subscription === "None") {
            throw new Error("You don't have an active subscription");
        }

        const data = {
            subscription_id: userData.subscription_id,
            uid: currentUser.uid
        };

        const res = await fetch('https://fathomless-gorge-94679.herokuapp.com/api/cancelsubscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!res.ok) {
            const error = await res.text();
            throw new Error(error);
        }
        console.log("response", res)
    };
    const onApproveSubscription = async (data, actions) => {

        console.log("approved")
        console.log(data)
        return actions.subscription.get().then(async function (details) {
            console.log(details)
            // User can upgrade to new plan
            // Check if user has an active subscription before trying to cancel it
            if (userData.subscription !== 'None') {
                // Cancel current subscription first
                try {
                    await cancelSubscription();
                } catch (error) {
                    console.error("Error cancelling subscription: ", error);
                    return;
                }
            }
            try {
                const userRef = doc(db, "users", UID.current);
                await updateDoc(userRef, {
                    subscription: "Ultimate Plan",
                    subscription_id: details.id
                });
                await savePurchaseInfo(details, 'subscription');
                console.log("User subscription updated to Ultimate Plan");
            } catch (error) {
                console.error("Error updating user subscription: ", error);
                Sentry.captureException(error);
            }
            logEvent('purchase', {
                value: productPrice,
                currency: 'USD',
                transaction_id: details.id,
                product_name: productName,
                product_id: productID,
                userId: UID.current,
                type: type, // 'subscription' or otherwise
            });
                        window.trackTwitterConversion('purchaseSubscription', productPrice);

            localStorage.setItem("paymentProcessing", "true")
            alert("Thank you for your subscription! It may take up to 2 minutes for the subscription to be activated");
            navigate("/shop")
        })
    };
    const onError = (err) => {
        console.log(err);
        let errMessage = "An error occurred with your payment.";

        if (err.intent === 'CAPTURE' && err.message.includes('insufficient funds')) {
            message = "You have insufficient funds for this transaction.";
        }

        message.error(errMessage)
        Sentry.captureException(errMessage);
        // log error event
        logEvent('checkout_error', {
            error_message: err.message,
            error_name: err.name,
            product_name: productName,
            product_id: productID,
        });
    };
    const FeedbackForm = () => {
        const [feedback, setFeedback] = useState("");

        const submitFeedback = async () => {
            if (feedback.trim()) {
                const feedbackCollection = collection(db, "feedbacks");
                await setDoc(doc(feedbackCollection), {
                    text: feedback,
                    timestamp: serverTimestamp(),
                    user_id: UID.current
                });
                message.success("Feedback submitted. Thank you!");
                setFeedback(""); // Reset the textarea after submission
            } else {
                message.error("Please enter your feedback before submitting.");
            }
        };

        return (
            <div className="feedbackForm">
                <textarea
                    style={{ '::placeholder': { color: '#999' } }}
                    placeholder="Share your feedback with us..."
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
                <button onClick={submitFeedback} className="button">Submit Feedback</button>
            </div>
        );
    };
    const renderLoading = () => (
        <div className="loader-container">
            <div className="spinner"></div>
        </div>
    );

    //live ATSozBY8MeQAaCrpz92R5wSOBfS2Jps2yBYwK3BRtbEA4d-SYvmTdO2Qt9mNYzIKqn6nQgI4rdnD5jUq sandbox AbqDGsQ_e-xf3OIam7ZsEprVMnOchPutL_5Zq48X4_yEOq8HDtlBl6RE_1K5dtO4WUz66Fjv-_4U0Dfx
    return (
        loading ? renderLoading() : (
            <div className="mainWrapper">
                <PayPalScriptProvider
                    options={{
                        "client-id": "ATSozBY8MeQAaCrpz92R5wSOBfS2Jps2yBYwK3BRtbEA4d-SYvmTdO2Qt9mNYzIKqn6nQgI4rdnD5jUq",
                        vault: true,
                        ...(type === "subscription"
                            ? { intent: "subscription" }
                            : { intent: "capture" }),
                    }}
                >
                    <div className="checkoutWrapper">
                        {/* Buy Anonymously Section */}
                        <div className="buy-anonymously-section">
                            <h2><i className="fas fa-user-secret"></i> Buy Anonymouslys</h2>
                            <p>Enjoy our services without revealing your identity. Your privacy matters to us. Charges will also appear anonymously on your billing statement.</p>
                        </div>
                        <div className="desc">
                            <div className="title">{type === "subscription" ? (<div>Subscribe to {productName}</div>) : (<div>Buy {productName}</div>)}</div>
                            <div className="price">{productPrice}$</div>
                        </div>
                        <div className="wrapper">
                            <PayPalButtons
                                onClick={logPayPalButtonClick}
                                style={{ layout: "vertical" }}
                                onError={onError}
                                {...(type === "subscription"
                                    ? { createSubscription }
                                    : { createOrder })}
                                {...(type !== "subscription" ? { fundingSource: "paypal" } : {})}
                                {...(type === "subscription"
                                    ? { onApprove: onApproveSubscription }
                                    : { onApprove: onApprove })}
                            />
                        </div>
                        {/*type === "subscription" && (
                            <div className="wrapper">
                                <button className="payWithCreditCard" onClick={() => subscribeWithPayabl()}>
                                    <i className="fas fa-credit-card"></i> Subscribe with Credit Card
                                </button>
                            </div>
                        )*/}
                        {/*type !== "subscription" && (
                            <div className="wrapper">
                                <button className="payWithCreditCard" onClick={() => payWithPayabl()}>
                                    <i className="fas fa-credit-card"></i> Pay with Credit Card
                                </button>
                            </div>
                        )*/}

                        <button className="showFeedback" onClick={() => setShowFeedback(!showFeedback)}>
                            {showFeedback ? "Hide Feedback" : "Provide Feedback"}
                        </button>
                        {showFeedback && <FeedbackForm />}
                    </div>
                </PayPalScriptProvider>
            </div>
        )
    );
}

export default Checkout;
import React, { useContext, useEffect, useState} from "react";
import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
const ChatMenuWindow = ({ isOpen, handleClose, onClick }) => {
    const { currentUser, userData} = useContext(AuthContext);
    const {setDisplayType, setShowSignUp, activeWindow, setActiveWindow} = useContext(ChatContext);
    const handleChatMenu = (type) => {
        handleClose()
        setActiveWindow(type);
      }
    const navigationItems = [
        { onClick: () => handleChatMenu('chat'), image: "https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/gradient/chat-dynamic-gradient.png", label: 'Chat' },
        { onClick: () => handleChatMenu('storyStore'), image: "https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/gradient/bookmark-dynamic-gradient.png", label: 'Stories' },
        { onClick: () => handleChatMenu('memory'), image: "https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/gradient/puzzle-dynamic-gradient.png", label: 'Memory' },
        { onClick: () => handleChatMenu('diary'), image: "https://pub-821312cfd07a4061bf7b99c1f23ed29b.r2.dev/v1/dynamic/gradient/notebook-dynamic-gradient.png", label: 'Diary' },
    ];
    const handleClickWithoutLogin = () => {
        handleClose()
        setDisplayType("features")
        setShowSignUp(true)
    }
    return isOpen ? (
        <div className='ChatMenuWindow'>
            <button className='close-button' onClick={handleClose}>
                x
            </button>
            <ul className='navigation-grid'>
            {navigationItems.map((item, i) => (
                    <li key={i} className="navigation-item">
                        <button onClick={!currentUser.isAnonymous ? (item.onClick) :(handleClickWithoutLogin)} style={{fontSize: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img src={item.image} alt={item.label} style={{width: '75px', height: '75px'}} />
                            <p className="navigation-item-label">{item.label}</p>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
}

export default ChatMenuWindow;



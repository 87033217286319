import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faRedo, faBrain } from '@fortawesome/free-solid-svg-icons'; // Import additional icons
import '../styles/RelationshipStatusModal.scss';

const RelationshipStatusModal = ({ isOpen, onClose, avatar, characterName, level, onResetChat, onResetMemory, onChangeStatus, isSubscribed }) => {
  if (!isOpen) return null;
  const handleResetChat = () => {
    // Logic to reset chat
    onResetChat();
  };

  const handleResetMemory = () => {
    // Logic to reset memory
    onResetMemory();
  };
  return (
    <div className="modal-overlay">
      <div className='modal-rel'>
        <div className="modal-header">
          <img src={avatar} alt="Avatar" className="modal-avatar" />
          <button className="modal-close-btn" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        </div>
        <h2 className="modal-title">{isSubscribed ? ("Relationship Status") : ("Change Your Relationship with "+ characterName)}</h2>
        <div className="modal-body">
          {/* Level Display */}
          {/* Reset Chat and Memory Buttons */}
        {!isSubscribed ? (
          <>
          <button className="status-btn">
            More than friends
          </button>
          <button className="status-btn status-btn--active" onClick={() => onChangeStatus()}>
            <FontAwesomeIcon icon={faLock} />
            Romantic Partners
          </button>
          </>
        ): (
          <>
          <button className="status-btn status-btn--active">
            Romantic Partners
          </button>
          </>
        )}
        </div>
      </div>
    </div>
  );
};

RelationshipStatusModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  avatar: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  character: PropTypes.string
};

export default RelationshipStatusModal;
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db, logCustomEvent } from "../firebase";
import { ExoClickTracker } from "./exoclick";
import { RedditTracker } from "./reddit";
import { XTracker } from "./x";


const exoClickTracker = new ExoClickTracker();
const xTracker = new XTracker();
const redditTracker = new RedditTracker();

const trackers = [
  exoClickTracker,
  xTracker,
  redditTracker
]

const updateUserTrackingParams = async (params) => {
  const user = auth.currentUser;
  if (!user) {
    return;
  }
  try {
    const d = doc(db, "users", user.uid);
    const userDoc = await getDoc(d);
    if (userDoc.exists()) {
      params = params || getTrackingParams();
      await updateDoc(d, {
        ...params
      });
      logCustomEvent("tracking_params_updated", {
        userId: user.uid,
        params
      });
    }
  } catch (e) {
    console.error("Error updating user tracking params", user, params, e);
    logCustomEvent("tracking_params_update_error", {
      error: e.message,
      params
    })
  }
}

/**
 * Extract tracking parameters from the URL
 * 
 * @param {URLSearchParams} params 
 */
const extractTrackingParams = (params) => {
  // console.log('extractTrackingParams', Array.from(params.entries()));
  for (const tracker of trackers) {
    const filteredParams = tracker.extractTrackingParams(params);
    if (filteredParams) {
      logCustomEvent("tracking_params_extracted", {
        tracker: tracker.trackerId,
        userId: auth.currentUser?.uid,
        params: tracker.getTrackingParams()
      });
      updateUserTrackingParams(tracker.getNamedTrackingParams());
      return filteredParams;
    }
  }
  return undefined;
}

/**
 * Get the tracking parameters from all supported trackers
 * 
 * @returns {Record<string, Record<string, string>>} The tracking parameters.
 */
const getTrackingParams = () => {
  const trackingParams = {};
  for (const tracker of trackers) {
    const params = tracker.getNamedTrackingParams();
    if (params) {
      Object.assign(trackingParams, params);
    }
  }
  // Notes: just for compatibility with the original code, should be removed in the future
  trackingParams['conversionTrackingId'] = getExoConversionId();
  return trackingParams;
}

const getExoConversionId = () => {
  return exoClickTracker.getConversionId() || null;
}

const tracking = {
  extractTrackingParams,
  getTrackingParams,
  getExoConversionId,
  updateUserTrackingParams,
}

export default tracking;
import { Tracker } from "./common";

export class RedditTracker extends Tracker {
  static get trackerId() {
    return "redditTracking";
  }

  static get trackingKeys() {
    return ["rdt_cid", "utm_compaign", "utm_content", "utm_medium", "utm_source"];
  }
}

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'PPPangramSansRounded-Bold',
  },
});

const WinnerCard = ({ winner }) => {

    return (
      <ThemeProvider theme={theme}>
        <Card className="card" sx={{ borderRadius: '20px' }}>
          <CardContent className="card-content">
            <img src={winner.imageUrl} alt={winner.name} className="character-image" />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h4" component="div" className="character-name">
              🏆 {winner.name}
              </Typography>
            </div>
            <Typography variant="body1" color="text.secondary" className="character-description">
              {winner.description}
            </Typography>
            <Typography variant="body2" color="text.secondary" className="character-user">
             Winner by @{winner.userName}
            </Typography>
          </CardContent>
        </Card>
      </ThemeProvider>
    );
  };
  
  export default WinnerCard;
import React, { useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import '../styles/FilterBar.scss';
import thumbUpIcon from '../img/Daumen.png';
import animeIcon from '../img/Anime.png'; // Corrected file path
import realisticIcon from '../img/Camera.png'; // Corrected file path
import animeGameIcon from '../img/Gaming.png'; // Corrected file path
import maleIcon from '../img/Male.png'; // Corrected file path
import specialIcon from '../img/Sparkles.png'; // Corrected file path
import privateVideoWaifusIcon from '../img/Video.png'; // Corrected file path
import newIcon from '../img/New.png'; // Corrected file path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
// Array of all categories
const allCategories = [
  { name: 'Recommended', emphasized: true},
  { name: 'Anime', premium1: true},
  { name: 'Picture Waifus', premium: true },
  { name: 'Furry', premium1: true},
  { name: 'Romance', premium1: true},
  { name: 'Anime Game', premium1: true},
  { name: 'Special', premium: true},
  { name: 'Male', premium1: true},
  { name: 'Saviorfagging', premium1: true},
  { name: 'Depressed', premium1: true},
  { name: 'Yandere', premium1: true},
  { name: 'Dominant', premium1: true},
  { name: 'Private Video Waifus', premium: true },
  { name: 'Giantess', premium1: true },
  { name: 'Femboy', premium1: true },
  { name: 'Cute', premium1: true},
  { name: 'Slave', premium1: true},
  { name: 'Virgin', premium1: true},
  { name: 'Sadistic', premium1: true},
  { name: 'Realistic', premium1: true},
  { name: 'Dark Fantasy', premium1: true},
  { name: 'VTuber', premium1: true},
  { name: 'Girlfriend', premium1: true},
  { name: 'Bully', premium1: true},
  { name: 'Scenario', premium1: true},
  { name: 'Action', premium1: true},
  { name: 'Love', premium1: true},
  { name: 'Pony', premium1: true},
  { name: 'Multiple Characters', premium1: true},
];

const FilterBar = ({ handleSelectCategory, selectedCategory }) => {
  const {currentUser} = useContext(AuthContext)
  useEffect(() => {
    if (!currentUser.isAnonymous) {
      // Check if 'Favorites' already exists in allCategories
      const hasFavorites = allCategories.some(category => category.name === 'Favorites');
  
      // Push 'Favorites' only if it doesn't exist
      if (!hasFavorites) {
        allCategories.push({ name: 'Favorites', emphasized1: true });
      }
    }
  }, [currentUser]);
  // Define the number of categories to show when not expanded
  const initialCategoriesCount = 6;

  const location = useLocation();
  const navigate = useNavigate();

  // Function to get the expanded state from URL
  const getExpandedFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('expanded') === 'true';
  };

  const [isExpanded, setIsExpanded] = useState(getExpandedFromURL());

  // Update URL when isExpanded changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (isExpanded) {
      searchParams.set('expanded', 'true');
    } else {
      searchParams.delete('expanded');
    }
    navigate({ search: searchParams.toString() }, { replace: true });
  }, [isExpanded, navigate]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  // Function to render a category button
  const renderCategoryButton = (category) => (
    <button
      key={category.name}
      className={`
        ${selectedCategory.toLowerCase() === category.name.toLowerCase() ? 'active' : ''} 
        filterButton 
        ${category.premium ? 'premium' : ''}
        ${category.premium1 ? 'premium1' : ''} 
        ${category.emphasized ? 'emphasized' : ''} 
        ${category.emphasized1 ? 'emphasized1' : ''} 
        ${category.emphasized2 ? 'emphasized2' : ''} 
        ${category.emphasized3 ? 'emphasized3' : ''}
      `}
      onClick={() => handleSelectCategory(category.name.toLowerCase())}
      aria-pressed={selectedCategory.toLowerCase() === category.name.toLowerCase()}
    >
      {category.name}
      {category.premium && <span className="premium-tag">Premium</span>}
    </button>
  );
  
  

  return (
    <div className={`filterBtnContainer ${isExpanded ? 'expanded' : ''}`}>
      {allCategories.slice(0, isExpanded ? allCategories.length : initialCategoriesCount).map(renderCategoryButton)}

      {allCategories.length > initialCategoriesCount && (
        <button className="expandButton" onClick={toggleExpand}>
          {isExpanded ? 'Collapse' : 'Expand All'}
          <FontAwesomeIcon 
            className="expandIcon" 
            style={{"margin": "10px"}} 
            icon={isExpanded ? faChevronUp : faChevronDown} 
          />
        </button>
      )}
    </div>
  );
};

FilterBar.propTypes = {
  handleSelectCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
};

export default FilterBar;
import { useState, useEffect } from "react";

export const useForm = (steps, onSubmit) => {
  const [formState, setFormState] = useState(
    steps.reduce((acc, step) => {
      acc[step.key] = step.key === "birthday" ? null : "";
      return acc;
    }, { interests: [] })  // add 'interests' key with an empty array value to the initial state
  );
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit();
    }
  };

  const validateForm = () => {
    const newErrors = {};

    steps.forEach((step) => {
      if (!formState[step.key]) {
        newErrors[step.key] = `Please enter your ${step.label.toLowerCase()}.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Log formState to debug if necessary
  useEffect(() => {
    console.log(formState);
  }, [formState]);

  return { formState, handleChange, handleSubmit, errors };
};

import React, { useContext, useEffect, useState, useRef} from "react";
import { Tooltip,Dropdown, Menu, Button, message as msg, message} from 'antd';
import { AuthContext } from "../../context/AuthContext";
import { ChatContext, creat } from "../../context/ChatContext";
import send from "../../img/sendNormal.png"
import gem from "../../img/gem.png"
import { useLocation } from "react-router-dom";
import VoiceLimitModal from "../VoiceLimitModal";
import {
  arrayUnion,
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  collection,
  addDoc,
  runTransaction,
  onSnapshot
} from "firebase/firestore";
import { logEvent, db, storage, logGemPurchase } from "../../firebase";
import { v4 as uuid } from "uuid";
import * as Sentry from '@sentry/react';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { handleAIResponse, checkModeration,checkModerationForTOSViolation, generatePrompt, generateTopicPrompt, generateStartStoryPrompt, TtsToAudio, generateImagePrompt} from "../../AIConnector";
import { useRouteLoaderData } from "react-router-dom";
import { buildDeprecatedPropsWarning } from "@mui/x-date-pickers/internals";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faSync, faEraser, faArrowUp, faRedo, faChevronLeft, faChevronRight  } from '@fortawesome/free-solid-svg-icons';
import { createChatId } from "../../helpers";
import ScrollableTopics from "../ScrollableTopics";
import NoGemsModal from "../NoGemsModal";
import "../../styles/Input.scss"
import VideoModal from "../VideoModal";
import { start } from "@popperjs/core";
import "../../styles/get-voice-memo.scss"
import { getStableDiffusionImage } from "../../stableDiffusionConnector";
import { promises } from "fs";
import GiftPopup from "../GiftPopup";
import CreateTikTokButton from "../CreateTikTokButton";
const Input = ({resetChatFunc}) => {
  const [showStartStoryButton, setShowStartStoryButton] = useState(true);
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const { currentUser, userData } = useContext(AuthContext);
  const { setShowNewLevelModal, lastPrompt, voiceId, setLastPrompt, setDisplayType, setShowSignUp, setShowXpMessageAnimWithId,requesting,setActiveWindow, activeWindow, setRequesting, setShowWarning, setShowErr, chatID, characterData, chatData, selectedStory, setShowMessageModal} = useContext(ChatContext);
  const [lastImagePaid, setLastImagePaid] = useState(true);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [hasTooltipBeenShown, setHasTooltipBeenShown] = useState(false);
  const userMessageFlaggedRef = useRef(false);
  const [showVoiceLimitModal, setShowVoiceLimitModal] = useState(false)
  const [videoModalShow, setVideoModalShow] = useState(false) 
  const [showPromptInput, setShowPromptInput] = useState(false)
  const [promptInput, setPromptInput] = useState("")
  const [showGiftPopup, setShowGiftPopup] = useState(false)
  const [showMenu, setShowMenu] = useState(false);
  const [showNSFWMenu, setShowNSFWMenu] = useState(false);
  const menuRef = useRef(null);
  const nsfwMenuRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
    if (nsfwMenuRef.current && !nsfwMenuRef.current.contains(event.target)) {
      setShowNSFWMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  const handleNSFWClick = () => {
    setShowNSFWMenu(true);
  };

  const sessionId = currentUser.uid;
  const trimOldestMessages = (messages, limit, batchSize) => {
    if (messages.length > limit) {
      return messages.slice(batchSize); // Remove 'batchSize' number of oldest messages
    }
    return messages;
  };
  useEffect(() => {
    setRequesting(false)
  }, [])
  const handleFocus = () => {
    if (!hasTooltipBeenShown) {
      setIsTooltipVisible(true);
      setHasTooltipBeenShown(true);
    }
  }

  const handleBlur = () => {
    if (isTooltipVisible) {
      setIsTooltipVisible(false);
    }
  }

  const handleUndo = async () => {
    try {
      // Early return if a request is already in progress
      if (requesting) return;
  
      // Early return if the user is not logged in
  
      // Check for sufficient gems
      if (userData.gems <= 1) {
        setShowWarning(true);
        return;
      }
  
      // Fetch the current messages array from Firestore
      const docRef = doc(db, "chats", chatID);
      const messages = chatData?.messages;
  
      // Handle case when there are zero messages
      if (!messages || messages.length === 0) {
        console.log("No messages to undo.");
        return;
      }
  
      // Check if the last message was sent by the current session
      if (messages[messages.length - 1].senderId === sessionId) {
        console.log("Cannot undo a user message.");
        return;
      }
  
      // Remove the last message and update Firestore
      const updatedMessages = messages.slice(0, -1);
      await updateDoc(docRef, { messages: updatedMessages });
  
      // Trigger AI response
      await handleAISend();
  
      // Deduct a gem from the user's account
      await updateDoc(doc(db, "users", currentUser.uid), {
        gems: userData.gems - 1
      }, { merge: true });
  
    } catch (error) {
      console.error("An error occurred while undoing the message:", error);
    }
  };
  const handleChange = (e) => {
    if (e.key === 'Enter') {
      handleSend()
    }
  }
  const handleSend = async () => {
    try {
      // Check if a request is currently ongoing
      if (requesting) {
        return;
      }
      if(text.includes("/image")){
        setRequesting(true)
        setText("")
        await getImage(text.replace("/image", ""))
        setRequesting(false)
        return
      }
      if(currentUser.isAnonymous)
      {
    if(userData.gems -2 < 0 )
    {
      setDisplayType("")
      setShowSignUp(true)
      return
    }
  }
    // Check the user's gem count only if it's a logged-in user
    if (!currentUser.isAnonymous && (userData.gems < getGemCosts(userData.subscription) || isNaN(userData.gems))) {
      setShowWarning(true);
      return;
    }
  
      // Handle sending
      if (img) {
        await handleImageSend();
      } else if (text.trim() !== "") {
        if(chatData.messages.length > 200)
        {
              // Fetch the current messages
    const docRef = doc(db, "chats", chatID);
          // Update the Firestore document with the updated messages array
              // Trim oldest messages if the limit is reached
              console.log("trimming")
    let messages = chatData.messages;
  // Trim oldest messages if the limit is reached
  const messageLimit = 200; // Set your limit here
  const batchSize = 20; // Number of messages to remove at once
  messages = trimOldestMessages(messages, messageLimit, batchSize);
    await updateDoc(docRef, {
      messages: messages,
    });
        }
        // Log the new count
        await handleTextSend();
console.log(localStorage.getItem("messagesCount"));
// Deduct gem costs
const gemCosts = getGemCosts(userData.subscription);
const userRef = doc(db, "users", userData.uid);
const updates = {
gems: userData.gems - gemCosts
};

if (characterData && characterData.video) {
updates.messagesSinceLastVideo = (userData.messagesSinceLastVideo || 0) + 1;
}

// Perform the update
await updateDoc(userRef, updates, { merge: true });
      } else {
        msg.info("Please enter a message before clicking send.");
        return;
      }
        console.log("length", chatData.messages.length)
      // Increment the message count using a transaction
    } catch (err) {
      console.error(`Error during send: ${err.message}`);
      msg.error(`Error during send: ${err.message}`);
    }
  };
  
  const incrementMessageCount = async (characterUid) => {
    await runTransaction(db, async (transaction) => {
      const characterDoc = doc(db, "characters", characterUid);
      const characterSnapshot = await transaction.get(characterDoc);
  
      if (!characterSnapshot.exists()) {
        // handle the error
        throw new Error(`Document for character ${characterUid} does not exist!`);
      }
  
      const newMessagesCount = characterSnapshot.data().messagesCount + 1;
      transaction.update(characterDoc, { messagesCount: newMessagesCount });
    });
  };
  
  const getGemCosts = (subscription) => {
    switch (subscription) {
      case "Plus Plan":
        return 1;
      case "Ultimate Plan":
        return 0;
      default:
        return 2;
    }
  };
  const checkAndSetFlaggedStatus = async (message) => {
    let flagged = false
    if(currentUser.isAnonymous === true)
    {
      return false
    }
    flagged = await checkModeration(message) ? true : false;
    console.log("flagged status", flagged);
    userMessageFlaggedRef.current = flagged;
    if(flagged === undefined){
      return false
    }
    return flagged
  };
  const handleTextSend = async () => {
    try {
      setText("");
      //const flagged = await checkAndSetFlaggedStatus(text);
      console.log("text", text);
      
      const messageObj = {
        id: uuid(),
        text,
        senderId: sessionId,
        date: Timestamp.now(),  // Firebase server timestamp for the message
        locked: false
      };
  
      console.log("arrayUnion", messageObj);
  
      await updateDoc(doc(db, "chats", chatID), {
        messages: arrayUnion(messageObj),
        lastUpdated: Timestamp.now()  // Update the lastUpdated field
      });
  
      setRequesting(true);
      await handleAISend();
    } catch (err) {
      Sentry.captureException(err);
      console.error(`Error sending text message: ${err.message}`);
      // Ask the user if they want to retry
      if (window.confirm("There was an error sending the text message. Do you want to retry?")) {
        handleTextSend();  // recursive call
      }
    } finally {
      setRequesting(false);
    }
  };
  const xpForNextLevel = (currentLevel) => {
    const baseXP = 100; // Base XP
    const levelPower = 2; // Power to raise the level number (e.g., squared)
    const additionalXPPerLevel = 20; // Additional XP required for each subsequent level
  
    // Calculate XP using a polynomial growth model and add a constant increment per level
    return Math.floor(baseXP * Math.pow(currentLevel, levelPower) + additionalXPPerLevel * (currentLevel - 1));
  };
  const handleImageSend = async () => {
    const storageRef = ref(storage, "/userImages/" + uuid());
    await uploadBytesResumable(storageRef, img).then(async (snapshot) => {
      getDownloadURL(snapshot.ref).then(async (downloadURL) => {
        const caption = "await GetImageCaption(downloadURL)";
        const desc = `*You send ${characterData.uid} the picture that contains: "${caption}"*`;
        console.log("arrayUnion",{
          id: uuid(),
          "text": desc,
          senderId: currentUser.uid,
          date: Timestamp.now(),
          img: downloadURL,
          unlocked: true,
        })
        await updateDoc(doc(db, "chats", chatID), {
          messages: arrayUnion({
            id: uuid(),
            "text": desc,
            senderId: currentUser.uid,
            date: Timestamp.now(),
            img: downloadURL,
            unlocked: true,
          }),
        });
  
        handleAISend();
      });
      setImg(null);
    });
  };

  const handleAISend = async (isVoice = false) => {
    try {
      console.log("characterData", characterData)
      const sessionId = currentUser && !currentUser.isAnonymous ? userData.uid : localStorage.getItem('tempSessionId');
      const sessionData = currentUser && !currentUser.isAnonymous ? userData : { FormData: { name: `Oniichan` }, subscription: "None", memories: [] };
      const docRef = doc(db, "chats", chatID);
      const { messages } = (await getDoc(docRef)).data();
  
      const userMessage = messages[messages.length - 1];
      messages[messages.length - 1].locked = false
  
      // Start moderation checks for the last message
      console.log("Checking moderation for last message:", userMessage.text);
      const [lastMessageModerationPromise, tosViolationCheck] = await Promise.all([
        checkModeration(userMessage.text),
        checkModerationForTOSViolation(userMessage.text)
      ]);
  
      console.log("Firebase moderation result (TOS violation check):", tosViolationCheck);
  
      // If TOS violation is detected, return early with an apology message
      if (tosViolationCheck) {
        console.log("TOS violation detected. Returning apology message.");
        const apologyMessage = "I apologize, but I cannot respond to that message as it may violate our terms of service.";
        await updateDoc(docRef, {
          messages: arrayUnion({
            id: uuid(),
            text: apologyMessage,
            senderId: characterData.uid,
            date: Timestamp.now(),
            locked: true,
            violation: true
          }),
        });
        return;
      }
  
      console.log("sessionData", sessionData)
      console.log("prompt", characterData)
      const finalPrompt = generatePrompt(characterData, sessionData, messages, "", selectedStory?.storyPrompt, currentUser);
      let text = "";
      setLastPrompt(finalPrompt)
      console.log("sessionData", sessionData)
      setRequesting(true);
      console.log("starting text")
      text = await processUserRequestAndGetResponse(finalPrompt, [sessionData.FormData.name + ":", characterData.displayName + ":", "(OOC"]);
      text = text.replace(characterData.displayName + ":", "").replace(sessionData.FormData.name + ":", "")
      text = text.replace("{{user}}", sessionData.FormData.name)
  
      console.log("text", text)
      if (text === "An error occured please regenerate") {
        setShowErr(true);
        return;
      }
      if (text === "I apologize, but I cannot respond to that message as it may violate our terms of service.") {
        const apologyMessage = "I apologize, but I cannot respond to that message as it may violate our terms of service.";
        messages[messages.length - 1].locked = true
        await updateDoc(docRef, {
          messages: arrayUnion({
            id: uuid(),
            text: apologyMessage,
            senderId: characterData.uid,
            date: Timestamp.now(),
            locked: true,
            violation: true
          }),
        });
        return;
      }
      // Matches '(OOC' and everything after it
      let oocRegex = /\(OOC[\s\S]*/;
  
      text = text.replace(sessionData.FormData.name + ":", "");
      text = text.replace(characterData.displayName + ":", "");
      text = text.replace(oocRegex, "");
  
      // Check moderation for AI response
      const aiModerationOld = await checkModeration(text);
      console.log("AI response moderation result:", aiModerationOld);
  
      const isVoiceMessage = isVoice;
      let voiceMessageURL = null;
      if (isVoiceMessage) {
        voiceMessageURL = await TtsToAudio(text, voiceId)
      }
      let newMessages = await processResponseMessages(text, characterData.uid, voiceMessageURL);
  
      if ((characterData.images || characterData.paidImages) && shouldSendImage(userMessage.text, text)) {
        const imageMessage = await processImageMessage();
        newMessages.push(imageMessage);
      }
      console.log("newMessages", newMessages)
      console.log("arrayUnion", newMessages)
  
      // Get the result of the last message moderation
      const lastMessageFlagged = await lastMessageModerationPromise;
      console.log("Last message moderation result:", lastMessageFlagged);
  
      // Update the locked property of the last message if flagged
      if (lastMessageFlagged || tosViolationCheck) {
        messages[messages.length - 1].locked = true;
        await updateDoc(docRef, {
          messages: messages,
        });
      }
  
      // Use old moderation result for locking the AI messages
      const aiFlagged = aiModerationOld || lastMessageFlagged;
      newMessages = newMessages.map(msg => ({...msg, locked: aiFlagged}));
  
      if (newMessages.length > 0) {
        await updateDoc(docRef, { messages: arrayUnion(...newMessages) });
      }
  
      setRequesting(false);
      if (newMessages.length > 0) {
        setShowXpMessageAnimWithId(newMessages[0].id);
    
        // Calculate and Update XP
        const xpToAdd = 10; // Fixed XP for each AI message sent
        let updatedXP = (userData.xp || 0) + xpToAdd;
        let newLevel = userData.level || 1;
        let maxXP = xpForNextLevel(newLevel);
    
        // Check if the user has reached the XP for the next level
        while (updatedXP >= maxXP) {
          newLevel += 1; // Increase level
          updatedXP -= maxXP; // Subtract maxXP from updatedXP as user levels up
          maxXP = xpForNextLevel(newLevel); // Calculate next level's XP requirement
        }
    
        // Update user's XP, level, and maxXP in the database
        const userRef = doc(db, "users", currentUser.uid);
        let newGems = userData.gems
        if(newLevel > userData.level)
        {
          setShowNewLevelModal(true)
          if(currentUser.isAnonymous === true && newLevel === 2){
            newGems = 0
          }
          if(currentUser.isAnonymous === false)
          {
          newGems = newGems + 10
          }
          console.log("newGems", newGems)
        }
// Start with the basic update
let updateData = {
  xp: updatedXP,
  level: newLevel,
  maxXP: maxXP
};
// Conditionally add the 'gems' field
if (newLevel > userData.level) {
  updateData.gems = newGems;
}

// Perform the update
await updateDoc(userRef, updateData);
        // Reset setShowXpMessageAnimWithId to "" after 2 seconds
        setTimeout(() => {
          setShowXpMessageAnimWithId("");
        }, 2000); // 2000 milliseconds delay
    }
    
          // Reset userMessageFlagged to false after it's been used
          userMessageFlaggedRef.current = false; // Reset the flag
    } catch (err) {
      setRequesting(false);
      console.error(`An error occurred: ${err}`, err, );
    }
  };
  
  const processUserRequestAndGetResponse = async (finalPrompt, context) => {
    try {
      return await handleAIResponse(finalPrompt, context);
    } catch (err) {
      setShowErr(true);
      logEvent(`server error ${err}`);
      return null;
    }
  };
  
  const processResponseMessages = async (text, senderId, voiceMessageURL) => {
    let flagged = userMessageFlaggedRef.current ||voiceMessageURL
    if(!flagged)
    {
      flagged = await checkAndSetFlaggedStatus(text)
    }
    const responseMessages = text.split(`${senderId}:`).filter(msg => msg.trim().length !== 0);
    const newMessages = [];
    for (const response of responseMessages) {
      const textMessage = {
        id: uuid(),
        text: response.trim(),
        senderId,
        date: Timestamp.now(),
        voice: voiceMessageURL,
        locked: flagged
      };
      console.log("ai message", textMessage)
      if(textMessage.id && textMessage.text && textMessage.senderId && textMessage.date){
        newMessages.push(textMessage);
      } else {
        console.error(`Invalid textMessage: ${JSON.stringify(textMessage)}`);
      }
    }
  
    return newMessages;
  };
  
  const processImageMessage = async () => {
    let imgSrc = null;
  
    // If the character is Amy, always use paidImages and set locked
    if (characterData.displayName === 'Amy') {
      imgSrc = characterData.paidImages[Math.floor(Math.random() * characterData.paidImages.length)];
      const imageMessage = {
        id: uuid(),
        text: '',
        senderId: characterData.uid,
        date: Timestamp.now(),
        img: imgSrc,
        unlocked: false, // Always locked for Amy
        paid: true, // Always a paid image for Amy
      };
      return imageMessage;
    } 
  
    // Original logic for other characters
    let tempPaid = true;
    if ((lastImagePaid && characterData.images && characterData.images.length > 0) || currentUser.isAnonymous) {
      tempPaid = false
      imgSrc = characterData.images[Math.floor(Math.random() * characterData.images.length)];
    } else {
      tempPaid = true
      imgSrc = characterData.paidImages[Math.floor(Math.random() * characterData.paidImages.length)];
    }
    const imageMessage = {
      id: uuid(),
      text: '',
      senderId: characterData.uid,
      date: Timestamp.now(),
      img: imgSrc,
      unlocked: false,
      paid: tempPaid, // Image from character.paidImages is a paid image
    };
  
    // Update the lastImagePaid flag
    setLastImagePaid(!lastImagePaid);
  
    return imageMessage;
  };
  

  function shouldSendImage(userText, aiText) {
    const keywords = ["send", "pic", "show"];
    return keywords.some((word) => userText.includes(word) || aiText.includes(word)) || Math.random() < 0.5;
  }
  useEffect(() => {
    setShowNewLevelModal(false)
  }, [])
  const getLocalStorageItem = (key, defaultValue) => {
    const stored = localStorage.getItem(key);
    return stored ? JSON.parse(stored) : defaultValue;
  };
  
  const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  const handleSendVideo = async () => {
    try {
      // Ensure there's a characterData with video links
      if (!characterData?.videos || characterData.videos.length === 0) {
          console.error("No video links available for the character.");
          return;
      }
      if(!userData.videosReceived)
      {
        await setDoc(doc(db, "users", userData.uid), { videosReceived: 0 }, { merge: true });
      }
  // Check and update the number of videos received
  const videosReceived = userData.videosReceived;
  const messagesSinceLastVideo = userData.messagesSinceLastVideo || 0;

console.log("start")
  // Check if the user has crossed the 10-messages threshold and hasn't reached the free video limit
  if (videosReceived < 2 && messagesSinceLastVideo >= 10) {
    logEvent('send_free_video', {
      characterId: characterData.uid,
      userId: userData.uid,
    });
    // Update user's videosReceived count in Firebase
    if (userData) {
      await updateDoc(doc(db, "users", userData.uid), {
        videosReceived: videosReceived + 1,
        messagesSinceLastVideo: 0,
      });
    }
   }
      
      if (userData.gems >= 200) {
        logEvent('send_paid_video', {
          characterId: characterData.uid,
          userId: userData.uid,
          gemCost: 200
        });
        logGemPurchase(currentUser.uid, 2000, "send video","send_paid_video");
        // Deduct gems and send video
        const userRef = doc(db, "users", userData.uid);
        const updatedGems = userData.gems - 200;
        await setDoc(userRef, { gems: updatedGems }, { merge: true });
        
        // Logic to send video...
      } else{
        setShowWarning(true)
        return
      }
 
  
      // Choose a random video link
      const randomIndex = Math.floor(Math.random() * characterData.videos.length);
      const videoLink = characterData.videos[randomIndex];
  
      // Create a new message object
      const videoMessage = {
        id: uuid(),
        text: "", // You can add a descriptive text or leave it empty
        senderId: characterData.uid,
        date: Timestamp.now(),
        video: videoLink, // Add the video link here
      };
  
      // Update Firestore chat document
      await updateDoc(doc(db, "chats", chatID), {
        messages: arrayUnion(videoMessage),
      });
console.log("create message for video")
const prompt = await generatePrompt(characterData, userData, chatData.messages, "videoMessage", currentUser)
const message = await handleAIResponse(prompt, [userData.FormData.name +":"])

      const videoTextMessage = {
        id: uuid(),
        text: message, // Optional descriptive text
        senderId: characterData.uid,
        date: Timestamp.now(),
      };

      // Update Firestore chat document with the new video message
      await updateDoc(doc(db, "chats", chatID), {
        messages: arrayUnion(videoTextMessage),
      });
  
    } catch (error) {
      logEvent('video_send_error', {
        characterId: characterData.uid,
        userId: userData.uid,
        error: error.message
      });
      console.error("An error occurred while sending the video:", error);
      // Handle any errors
    }
  };
  const getVideoButtonTooltip = () => {
    const videosReceived = userData.videosReceived || 0;
    const messagesSinceLastVideo = userData.messagesSinceLastVideo || 0;
  
    // Calculate the messages left for the next free video
    const messagesLeftForNextVideo = 10 - messagesSinceLastVideo;
  
    // Check if the limit of free videos is reached
    if (videosReceived >= 2 && userData.subscription !== "None") {
      return "";
    }
    if (videosReceived >= 2) {
      return 'Video limit reached';
    }
  
    return messagesLeftForNextVideo > 0 
           ? `Send ${messagesLeftForNextVideo} more message${messagesSinceLastVideo > 1 ? 's' : ''} to unlock a video`
           : 'Send a video';
  };
  async function handleSendExplicitVideoClick(level = 1) {
    // Define the cost for each level
    const gemCosts = {
      1: 2000,
      2: 4000,
      3: 6000,
    };
  
    // Define the video list for each level
    const videos = {
      1: characterData.videos_nsfw1,
      2: characterData.videos_nsfw2,
      3: characterData.videos_nsfw3,
    };
  
    // Check if the level is valid
    if (level in gemCosts && level in videos) {
      // Call the generic send function with specific parameters based on the level
      const randomIndex = Math.floor(Math.random() * videos[level].length)
      await handleSendExplicitVideo(gemCosts[level], videos[level][randomIndex]);
    } else {
      console.log("Invalid NSFW level selected.");
    }
  }
  async function handleSendExplicitVideo(gems, videoLink, onUnlockSuccess) {
    try {
        // Check if the user has enough gems
        if (userData.gems >= gems) {
          setVideoModalShow(false);
          // Deduct gems from the user's account and update the user data
          logGemPurchase(currentUser.uid, gems, "send video", "send_explicit_video");
          const userRef = doc(db, "users", userData.uid);
          const updatedGems = userData.gems - gems;
          await setDoc(userRef, { gems: updatedGems }, { merge: true });
          // You might want to call an API or Firestore function to update the user data here
          // For example: await updateUserGems(userData.uid, updatedUserData.gems);

          // Create a new video message object
          const videoMessage = {
            id: uuid(),
            text: "", // Optional descriptive text
            senderId: characterData.uid,
            date: Timestamp.now(),
            video: videoLink, // Add the video link here
            nsfw: true
          };

          // Update Firestore chat document with the new video message
          await updateDoc(doc(db, "chats", chatID), {
            messages: arrayUnion(videoMessage),
          });

          const prompt = await generatePrompt(characterData, userData, chatData.messages, "videoMessage", currentUser)
          const message = await handleAIResponse(prompt, [userData.FormData.name +":"])
           
          const videoTextMessage = {
            id: uuid(),
            text: message, // Optional descriptive text
            senderId: characterData.uid,
            date: Timestamp.now(),
          };

          // Update Firestore chat document with the new video message
          await updateDoc(doc(db, "chats", chatID), {
            messages: arrayUnion(videoTextMessage),
          });
          // Call the success callback if provided
          if (onUnlockSuccess) {
            onUnlockSuccess(true);
          }
    // Log event when sending an explicit video
    logEvent('send_explicit_video', {
      characterId: characterData.uid,
      userId: userData.uid,
      videoId: videoLink,
      gemCost: gems
    });

          // Close the video modal
        } else {
          // Handle the case where the user does not have enough gems
          // Show an error message or take appropriate actions
          setShowWarning(true)
        }
    } catch (error) {
        console.error("An error occurred while sending the video:", error);
           // Log error event
    logEvent('explicit_video_send_error', {
      characterId: characterData.uid,
      userId: userData.uid,
      error: error.message
    });
        // Handle any errors
        // Optionally call the success callback with false
        if (onUnlockSuccess) {
          onUnlockSuccess(false);
        }
    }
}

useEffect(() => {
  const sessionId = currentUser && !currentUser.isAnonymous ?  currentUser.uid : localStorage.getItem('tempSessionId');
  if (userData && characterData && sessionId) {
    const fetchChatAndSendVideoOrGreeting = async () => {
      const chatid = createChatId(sessionId, characterData.uid)
      const chatDocRef = doc(db, "chats", chatid);
      const chatDoc = await getDoc(chatDocRef);

      if (chatDoc.exists()) {
        const { messages } = chatDoc.data();

        if ((!messages || messages.length === 0) && !requesting) {
          setRequesting(true);

          if (characterData.videos) {
            // Send the first video from characterData.videos
            const videoMessage = {
              id: uuid(),
              text: "", // Customize this text
              senderId: characterData.uid,
              date: Timestamp.now(),
              video: characterData.firstVideo, // First video from the array
              first: true
            };
            await updateDoc(chatDocRef, { messages: [videoMessage] });
          }
          setRequesting(false);
        }
      }
    };

    fetchChatAndSendVideoOrGreeting();
  }
}, [currentUser, userData, characterData]);
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={resetChatFunc}>
        <FontAwesomeIcon icon={faEraser} /> Reset Chat
      </Menu.Item>
      <Menu.Item key="2" onClick={handleUndo}>
        <FontAwesomeIcon icon={faSync} /> Regenerate Message
      </Menu.Item>
    </Menu>
  );
    // Visual indicator for Send Explicit Video button
      // Display the gem price for the video button if the free video has been used
      const getVideoButtonLabel = () => {
        const videosReceived = userData.videosReceived || 0;
        const messagesSinceLastVideo = userData.messagesSinceLastVideo || 0;
        console.log("recieved",  userData.videosReceived)
        console.log("since", userData.messagesSinceLastVideo)
        // Calculate the messages left for the next free video
      
        // Check if the user can send a free video
        if (videosReceived < 2 && messagesSinceLastVideo >= 10) {
          return <span>Send Video</span>;
        }
      
        // Check if the user has reached the free video limit and doesn't have a subscription
        if (videosReceived >= 2 && userData.subscription === "None") {
          return <span>Get subscription for more videos</span>;
        }
        
        // If the user has gems, they can always send a video
        if (videosReceived >= 2 && userData.subscription !== "None") {
          return <span>Send Video 2000 Gems</span>;
        }
      
        // Default label when no other condition is met
        return <span>Send Video {`(${messagesSinceLastVideo}/10 messages)`}</span>;
      };
      // Check if the user can send an explicit video
const canSendExplicitVideo = () => {
  const videosReceived = userData.videosReceived || 0;
  const hasInteractedWithVideos = videosReceived > 0;

  // Conditions to send explicit video
  return hasInteractedWithVideos;
};

// Tooltip for Send Explicit Video button
const getExplicitVideoButtonTooltip = () => {
  if (!canSendExplicitVideo()) {
    return 'Locked: Send an explicit video';
  }
  return '';
};
const startStory = async () => {
  const sessionName = userData.FormData ? (userData.FormData.name) : ("Oniichat")
  // Additional logic for starting a story...
  setShowStartStoryButton(false); // Hide the button after it's clicked
  setRequesting(true)
  const prompt = generateStartStoryPrompt(characterData, userData, selectedStory?.story)
  let text = await processUserRequestAndGetResponse(prompt, [sessionName+":", characterData.displayName +":", "(OOC"]);
  text = text.replace(characterData.displayName + ":", "").replace(sessionName + ":", "")
  text.replace("{{user}}", sessionName)
  let newMessages = await processResponseMessages(text, characterData.uid, "");
  const docRef = doc(db, "chats", chatID);
  if (newMessages.length > 0) {
    await updateDoc(docRef, { messages: arrayUnion(...newMessages) });
  }
  setRequesting(false)
  console.log(prompt)
};
useEffect(() => {
  if (chatData && chatData.messages && chatData.messages.length === 0) {
    setShowStartStoryButton(true); // Hide the button if more than one message is present
  }
  else{
    setShowStartStoryButton(false);
  }
}, [chatData]);
const startTopic = async (topic) => {
  if(userData.gems < 2 && currentUser.isAnonymous)
  {
    setShowSignUp(true)
     return
  }

  if(userData.gems < 2)
  {
    setShowWarning(true);
return
  }
setRequesting(true)
const sessionData = currentUser && !currentUser.isAnonymous ?  userData : { FormData: { name: `Oniichan` }, subscription: "None", memories: [] };
  const prompt = await generateTopicPrompt(characterData, userData, chatData.messages, topic)
  let message = await handleAIResponse(prompt, [(userData?.FormData ? (userData.FormData.name) : "Oniichan") +":"])
  message = message.replace(characterData.displayName + ":", "").replace(sessionData.FormData.name + ":", "")
          // Create a new video message object
          const topicMessage = {
            id: uuid(),
            text: message,
            senderId: characterData.uid,
            date: Timestamp.now(),
            topic: topic, 
            locked: await checkModeration(text)
          };

          // Update Firestore chat document with the new message
          await updateDoc(doc(db, "chats", chatID), {
            messages: arrayUnion(topicMessage),
          });
          setRequesting(false)
}
const subtractGemsAndAddVoiceMemoCount = async (gemCost) => {
  const userRef = doc(db, "users", userData.uid);
const updates = {
gems: userData.gems - gemCost,
voiceMemosToday: (userData.voiceMemosToday ? userData.voiceMemosToday : 0) + 1
};
// Perform the update
await updateDoc(userRef, updates, { merge: true });
}
const getImage = async (prompt) => {
  const categoryArray = characterData.category.split(',').map(cat => cat.trim());
  console.log("category array", categoryArray);
  
  let isMale = categoryArray.some(cat => cat.toLowerCase() === "male");


  const imageUrl = await getStableDiffusionImage( `((${isMale ? "1boy, penis" : "1girl"}, 2_legs, ${prompt})), ${characterData.looks}, masterpiece, high quality, anime, newest, absurdres`)
  const imageMessage = {
    id: uuid(),
    text: '',
    senderId: characterData.uid,
    date: Timestamp.now(),
    img: imageUrl,
    unlocked: false, // Always locked for Amy
    paid: true, // Always a paid image for Amy
  };
  await updateDoc(doc(db, "chats", chatID), {
    messages: arrayUnion(imageMessage),
  });
}
const getVoiceMemo = async () => {
      // Check if the user has already accessed 10 voice memos today
      console.log("voiceMemosToday", userData.voiceMemosToday)
  if(currentUser.isAnonymous)
  {
    setShowSignUp(true)
    setDisplayType("features")
    return
  }
  if (!requesting) {
    if (userData.gems > 4) {
      //subtractGemsAndAddVoiceMemoCount(5);
    } else {
      // Show warning for insufficient gems
      setShowWarning(true);
      return;
    }
    await handleAISend(true);

    // Reset the daily voice memo count at the end of the day
    // This can be done using a scheduled task or cron job
  }
}

const handleGetImage = async () => {
  const prompt = promptInput;
  if (!prompt) { return; }
    // Retrieve and update the generation count
  let generationCount = parseInt(localStorage.getItem('generationCount')) || 0;
  // Check the condition for showing the signup popup
  if (currentUser.isAnonymous && generationCount === 1) {
    // Show signup popup
    setDisplayType("imageGen")
    setShowSignUp(true)
    return
  }
  if(!currentUser.isAnonymous && generationCount === 40){
    message.warning("The Image Generation is currently in beta and limited. Please provide feedback and try again tomorrow.")
    return
  }
  if (requesting) {
    const warningMessage = `Hang tight! ${characterData.displayName} is already preparing something for you. Please wait a moment before sending a new request.`;
    message.warning(warningMessage);
    return;
  }

  setShowPromptInput(false);
  setRequesting(true);
  await getImage(prompt);
  setRequesting(false);
  generationCount++;
  localStorage.setItem('generationCount', generationCount);

};

  return (
    <div>
    <GiftPopup isOpen={showGiftPopup} onClose={() => setShowGiftPopup(false)}/>
    <VoiceLimitModal isOpen={showVoiceLimitModal} onClose={() => setShowVoiceLimitModal(false)}/>
 {showStartStoryButton && (
        <button className="startStoryButton" onClick={(startStory)}>
          Let {characterData?.displayName} Start The Conversation
        </button>
      )}
{characterData && (<ScrollableTopics onTopicClick={startTopic}   onClickDiary={() => {
    setActiveWindow(activeWindow === "diary" ? null : "diary");
  }} onClickStories={() => {setActiveWindow(activeWindow === "storyStore" ? null : "storyStore");}} onClickVideos={() => setVideoModalShow(true)}characterData={characterData}/>)}
    {characterData && characterData?.videos && <VideoModal characterData={characterData} onUnlockVideo={handleSendExplicitVideo} isModalVisible={videoModalShow} onCancelModal={() => setVideoModalShow(false)} userData={userData}/>}
    <Tooltip 
        title="Start chatting or describe your actions within * symbols!" 
        visible={isTooltipVisible}
      >
         {showPromptInput ? (
        <div className="prompt-input">
        <Tooltip title={`Tell ${characterData.displayName} what scenario to enact or what to show as tags... e.g. ${characterData.displayName} gagged and tied up, hot, ....`}>
          <input type="text"placeholder={`Describe a scene with tags...`} onChange={(e) => setPromptInput(e.target.value)} value={promptInput}/>
          </Tooltip>
          <div className="buttons">
          <button className="input-button" onClick={handleGetImage}>
        <FontAwesomeIcon icon={faArrowUp} />
    </button>
    </div>
          {/* ... */}
        </div>
      ) : (
    <div className="input" onKeyPress={handleChange}>
        <input
          type="text"
          placeholder="Type something..."
          onChange={(e) => setText(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={text}
        />
    <div className="send">
      <input
        type="file"
        style={{ display: "none" }}
        id="file"
        onChange={(e) => setImg(e.target.files[0])}
      />
<div className="buttons">
{text && (
<Tooltip title={currentUser && !currentUser.isAnonymous ?  
  (userData?.subscription === "Plus Plan" ? "Send message (costs 1 gem)" : 
   userData?.subscription === "Basic Plan" || userData?.subscription === "None" ? "Send message (costs 2 gems)" : 
   "Send message")
  : "Send message"}>
    <button onClick={handleSend}>
        <FontAwesomeIcon icon={faArrowUp} />
    </button>
</Tooltip>)}
  {!text && (
    <div className="video-options-container">
      {characterData && characterData.category &&
        !characterData.category.toLowerCase().includes("realistic") &&
        !characterData.category.toLowerCase().includes("video") &&
        !characterData.category.toLowerCase().includes("multiple characters") && !currentUser.isAnonymous &&
        <Tooltip title="Click to request a custom image. Describe the scene or tags you want to see.">
          <button onClick={() => setShowPromptInput(true)}>
            <img src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/premium/camera-dynamic-premium.png" className="premium" alt=""></img>
          </button>
        </Tooltip>
      }
      {showMenu && (
        <div ref={menuRef} className="menu-popup">
          <ul>
            <li>
              <div onClick={handleSendVideo}>
                Send normal video (200 gems)
              </div>
            </li>
            <li>
              <div onClick={handleNSFWClick}>
                Send sexy video
              </div>
            </li>
          </ul>
        </div>
      )}

      {showNSFWMenu && (
        <div ref={nsfwMenuRef} className="menu-popup nsfw-menu">
          <ul>
            <li>
              <div onClick={() => handleSendExplicitVideoClick(1)}>
                Level 1 (2000 gems)
              </div>
            </li>
            <li>
              <div onClick={() => handleSendExplicitVideoClick(2)}>
                Level 2 (4000 gems)
              </div>
            </li>
            <li>
              <div onClick={() => handleSendExplicitVideoClick(3)}>
                Level 3 (6000 gems)
              </div>
            </li>
          </ul>
        </div>
      )}

      {characterData && characterData.category &&characterData.category.toLowerCase().includes("video") &&   <button className="resetButton" onClick={() => setShowMenu(true)}>
        <img src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/gradient/video-camera-dynamic-gradient.png" className="premium" alt=""></img>
        </button>}
      <Tooltip title="Reset chat (10 gems)">
        <button className="resetButton" onClick={resetChatFunc}>
          <FontAwesomeIcon icon={faRedo} /> {/* Replace 'faRedo' with the appropriate reset icon from your icon library if necessary */}
        </button>
      </Tooltip>
      </div>
  )}
</div>
    </div>
  </div>
      )}
  </Tooltip>
  {chatData && false && <CreateTikTokButton character={characterData} chatData={chatData.messages}/>}
  {/* !showStartStoryButton && !currentUser.isAnonymous &&
    <Tooltip title="Voice Memo (5 gems)">
  <button className="get-voice-memo-button" onClick={getVoiceMemo}>
  Get Voice Memo
</button>
</Tooltip>*/}

  {chatData && characterData?.videos && false && (
    <div className="video-buttons">
    <div className="tooltip-container">
  <Tooltip title={getVideoButtonTooltip()}>
    <div
      className={`video-button ${(!userData.videosReceived || userData.videosReceived < 2) && (userData.messagesSinceLastVideo || 0) < 10 ? 'disabled-button' : ''}`}
      onClick={() => {
        if ((userData.messagesSinceLastVideo || 0) >= 10 || (userData.videosReceived >= 2)) {
          handleSendVideo();
        } else {
          // Optionally handle the case where the button should be disabled
        }
      }}
    >
      {getVideoButtonLabel()}
    </div>
  </Tooltip>
</div>

  <div className="tooltip-container">
  <Tooltip title={getExplicitVideoButtonTooltip()}>
    <div
      className={`video-button ${!canSendExplicitVideo() ? 'disabled-button' : ''}`}
      onClick={() => {
        if (canSendExplicitVideo()) {
          setVideoModalShow(true);
        }
      }}
    >
      Send Explicit Video
    </div>
  </Tooltip>
</div>
</div>
)}
  </div>
  );
};

export default Input;

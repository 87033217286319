import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword ,signInWithPopup, GoogleAuthProvider, signInAnonymously, sendPasswordResetEmail} from "firebase/auth";
import { doc, setDoc, getDoc, updateDoc, writeBatch} from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import { auth, db, AuthErrors, logEvent} from "../../firebase";
import glogo from "../../img/Google_logo.svg"
import { Alert, message} from 'antd'; // Importing Ant Design Alert
import tracking from "../../tracking";
const Login = () => {
  const { currentUser} = useContext(AuthContext);
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false)
  const [email, setEmail] = useState("")
  const [forgotPasswordErr, setForgotPasswordErr] = useState(false)
  // State for showing the alert
  const [showAlert, setShowAlert] = useState(true);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    const batch = writeBatch(db); // Ensure this line is correctly initializing the batch
    try {
      const tempSessionId = auth.currentUser.uid;
      const result = await signInWithPopup(auth, provider);
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (!userDoc.exists()) {
        await setDoc(doc(db, "users", auth.currentUser.uid), {
          uid: auth.currentUser.uid,
          gems: 100, 
           ...tracking.getTrackingParams(),
          subscription: "None",
          email: auth.currentUser.email,
          xp: 0,       // Set the fetched xp
          maxXP: 100, // Set the fetched maxXP
          level: 1  // Set the fetched level
        });
      } else {
        tracking.updateUserTrackingParams();
      }
      message.success('Logged in successfully');
      setLoading(false);
      navigate("/selectchat")
    } catch (error) {
      logEvent( 'sign_in_with_google_failed', { error_message: error.message });
      message.error(AuthErrors[error.code] || error.message);
      setLoading(false);
    }
  }
  const SignInAnonymously = async() => {
    setLoading(true)
   await signInAnonymously(auth);
   try {
    const user = await getDoc(doc(db, "users", auth.currentUser.uid));
    console.log(user.data())
    //create user on firestore
    if(user.data() === undefined)
    {
    await setDoc(doc(db, "users", auth.currentUser.uid), {
      uid: auth.currentUser.uid,
      gems:  200,
      subscription: "None",
      xp: 0,       // Set the fetched xp
      maxXP: 100, // Set the fetched maxXP
      level: 1  // Set the fetched level
    });
  }
  else
  {
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      uid: auth.currentUser.uid,
      gems: user.data().gems,
      subscription: "None"
    });
  }
    setLoading(false)
    navigate("/selectchat");
  } 
  catch (err) {
    console.log(err);
    setErr(true);
    setLoading(false);
  }                                                                                                                                                                                                                                                                                

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;
    setLoading(true)
    try {
      await signInWithEmailAndPassword(auth, email, password);
      tracking.updateUserTrackingParams();
      setLoading(false)
      navigate("/selectchat")
    } catch (err) {
      setErr(true);
      setLoading(false)
      setErrMessage(err.code)
    }
  };
  function handleSendPasswordResetEmail(email) {
    sendPasswordResetEmail(auth, email)
      .then(function() {
        // Password reset email sent.
        console.log('Password reset email sent');
        setResetEmailSent(true)
      })
      .catch(function(error) {
        setForgotPasswordErr(true)
        // Error occurred. Inspect error.code.
        console.log('Error sending password reset email:', error);
      });
  }
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(()=>{
    if(currentUser?.uid && !currentUser.isAnonymous){
      navigate('/')
    }
  }
)
  return (
    <div>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (
        
    <div className="formContainer">
    {showAlert && (
            <Alert
              message="Subscription Reactivation"
              description="If you have received a subscription reactivation email, please use the password provided in the email along with your email address on which you received the reactivation email to log in."
              type="info"
              showIcon
              closable
              onClose={handleCloseAlert}
              style={{ marginBottom: '20px' }} // Style to position the alert
            />
          )}
      <div className="formWrapper">
        <h2>Log in</h2>
        <form onSubmit={handleSubmit}>
  <input type="email" placeholder="Please enter your email..." value={email} onChange={(e) => setEmail(e.target.value)} />
  <input type="password" placeholder="Please enter your password..." />
  {email.length > 1 ? (
    <div>
      {resetEmailSent ? (
        <span>We sent the reset link to your email.</span>
      ) : (
        forgotPasswordErr ? (
          <span>Sorry, there is no user with this email</span>
        ) : (
          <div className="forgotPassword" onClick={() => handleSendPasswordResetEmail(email)}><u>Forgot password?</u></div>
        )
      )}
    </div>
  ) : (
    <div></div>
  )}
  <button className="button">Sign in</button>
</form>
        {err && <span>{AuthErrors[errMessage]}</span>}
        <div>
        <button className="signInButton" onClick={signInWithGoogle}> <img src={glogo} alt=""/></button>
       { /*<button className="signInButton" onClick={() => SignInAnonymously()}>anonymous</button>*/}
        </div>
        <p>You don't have an account? <Link to="/register">Register</Link></p>
      </div>
    </div>
    )}
    </div>
  );
};

export default Login;

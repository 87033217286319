import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const NextGemRechargeCounter = ({ userData }) => {
    const [timeUntilRecharge, setTimeUntilRecharge] = useState('');

    useEffect(() => {
      const calculateNextRechargeDate = () => {
          if (!userData.plan_purchase_date) {
              console.log("Return null if the purchase date is not available");
              return null; // Return null if the purchase date is not available
          }
  
          const purchaseDate = moment.tz(userData.plan_purchase_date, moment.ISO_8601, 'America/New_York');
          const now = moment.tz('America/New_York');
          let nextRechargeDate = purchaseDate.clone().add(30, 'days'); // Add 30 days for the next recharge
  
          // Consider the buffer of 1 day
          const bufferInMilliseconds = 24 * 60 * 60 * 1000;
          const endDateWithBuffer = nextRechargeDate.clone().subtract(bufferInMilliseconds);
  
          while (endDateWithBuffer.isBefore(now)) {
              nextRechargeDate.add(30, 'days');
              endDateWithBuffer.add(30, 'days'); // Move the buffered date forward
          }
  
          return nextRechargeDate;
      };
  
      const updateRechargeTime = () => {
          const nextRechargeDate = calculateNextRechargeDate();
          if (!nextRechargeDate) return 'Loading...'; // Show loading message if date is not valid
  
          const now = moment.tz('America/New_York');
          const duration = moment.duration(nextRechargeDate.diff(now));
  
          return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`;
      };
  
      const timer = setInterval(() => {
          setTimeUntilRecharge(updateRechargeTime());
      }, 1000); // Update every second for accuracy
  
      return () => clearInterval(timer);
  }, [userData]);
  
  if (!userData.plan_purchase_date) {
      return <div style={rechargeStyle}>Loading...</div>; // Show loading message while waiting for data
  }
  
  const rechargeStyle = {
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    padding: '5px',
    borderRadius: '8px',
    fontFamily: 'PPPangramSansRounded-Bold',
    fontSize: "12px",
    textDecoration: 'none',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "1rem",

    // Responsive adjustments
    '@media (maxWidth: 768px)': {
      fontSize: '12px',
      marginLeft: '0',
      marginTop: '1rem'
    }
  };

  return (
    timeUntilRecharge !== "" && (
    <div style={rechargeStyle}>
      Next Gem Recharge: {timeUntilRecharge}
    </div>)
  );
};

export default NextGemRechargeCounter;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'antd';
import styles from '../../styles/InfoButton.module.css';

function InfoButton({ title = "Info", content = ["Some content..."] }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <button className={styles.infoButton} onClick={showModal}>
        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
      </button>
      <Modal 
        title={title} 
        visible={isModalVisible} 
        onCancel={handleOk} 
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        {content.map((text, index) => (
          <p key={index} className={styles.modalContent}>{text}</p>
        ))}
      </Modal>
    </>
  );
}

export default InfoButton;
import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { Modal, Button, Progress, Input, message, Steps, Typography, Tag, Space, Select, Spin, Alert, Tooltip } from 'antd';
import { CloudUploadOutlined, LinkOutlined, GiftOutlined, CloseOutlined, CopyOutlined, ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from "../context/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";

const { Step } = Steps;
const { Text, Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const VideoPreview = styled.video`
  width: 70%;
  max-width: 300px;
  border-radius: 10px;
  margin-top: 20px;
`;

const ProgressWrapper = styled.div`
  margin-top: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const InstructionsWrapper = styled.div`
  margin-top: 15px;
`;

const TikTokButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const TikTokButton = styled(Button)`
  background-color: #5cd3b7;
  border-color: #5cd3b7;
  color: white;
  border-radius: 20px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 12px;
  padding-right: 30px;
  &:hover, &:focus {
    background-color: #4cc3a7;
    border-color: #4cc3a7;
    color: white;
  }
`;

const HideButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  border-radius: 50%;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  color: white;
  &:hover, &:focus {
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
  }
`;

const StepTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #595959;
  margin-top: 5px; 
  margin-bottom: 5px; 
`;

const UniqueIdContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const UniqueIdLabel = styled(Text)`
  font-size: 16px;
  color: #000;
  margin-right: 8px; 
`;

const UniqueIdText = styled(Text)`
  font-size: 16px;
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
`;

const CopyButton = styled(Button)`
  color: #1890ff;
  background: transparent;
  border: none;
  padding: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  &:hover {
    color: #40a9ff;
  }
  &:focus {
    color: #096dd9;
  }
`;

const DownloadButton = styled(Button)`
  color: #1890ff;
  background: transparent;
  border: 1px solid #d9d9d9; /* Apply grey border */
  padding: 4px 12px; /* Ensure padding is applied for better appearance */
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:hover {
    color: #40a9ff;
    border-color: #40a9ff; /* Change border color on hover */
  }
  &:focus {
    color: #096dd9;
    border-color: #096dd9; /* Change border color on focus */
  }
`;

const InstructionHeadline = styled(Text)`
  font-size: 14px;
  color: black;
  margin-top: 15px;
  display: block;
`;

const NonEditableCaptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
`;

const NonEditableCaption = styled(Text)`
  font-size: 16px;
  color: #595959;
  margin-right: 10px;
`;

const BlackText = styled(Text)`
  color: black; /* Make text black */
`;

const CreateTikTokButton = ({ chatData, character }) => {
  const [apiCaption, setApiCaption] = useState('');
  const [showUploadSteps, setShowUploadSteps] = useState(false); // State to show elements after video creation
  const { currentUser } = useContext(AuthContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [caption, setCaption] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [videoUrl, setVideoUrl] = useState(null);
  const [tiktokLink, setTiktokLink] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [messageCount, setMessageCount] = useState(2);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const videoRef = useRef(null);
  const [availableMessageCount, setAvailableMessageCount] = useState(0);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [claimedRewards, setClaimedRewards] = useState({});
  const [buttonVisible, setButtonVisible] = useState(true);
  const [isVideoCreated, setIsVideoCreated] = useState(false); // New state to track if video is created

  // Initialize Firebase functions
  const functions = getFunctions();

  useEffect(() => {
    const storedClaimedRewards = JSON.parse(localStorage.getItem('claimedTikTokRewards') || '{}');
    setClaimedRewards(storedClaimedRewards);
  }, []);

  useEffect(() => {
    if (videoUrl) {
      setIsVideoLoading(true);
      setIsVideoVisible(false);
    }
  }, [videoUrl]);

  const generateUniqueId = () => {
    return uuidv4().substr(0, 8);
  };

  useEffect(() => {
    const storedVideoUrl = localStorage.getItem('tiktokVideoUrl');
    const storedUniqueId = localStorage.getItem('tiktokUniqueId');
    const storedApiCaption = localStorage.getItem('tiktokApiCaption');
    
    if (storedVideoUrl) {
      setVideoUrl(storedVideoUrl);
    }
    if (storedUniqueId) setUniqueId(storedUniqueId);
    if (storedApiCaption) setApiCaption(storedApiCaption);
    
    if (!storedUniqueId) {
      const newId = uuidv4().substr(0, 8);
      setUniqueId(newId);
      localStorage.setItem('tiktokUniqueId', newId);
    }
  }, []);

  const handleVideoError = () => {
    setIsVideoLoading(false);
    message.error('Failed to load video. Please try again.');
    handleCancelVideoCreation(); // Handle cancellation if video loading fails
  };

  useEffect(() => {
    const availableCount = chatData.length;
    setAvailableMessageCount(availableCount);
    
    if (messageCount < 2) {
      setMessageCount(2);
    } else if (messageCount > Math.min(8, availableCount)) {
      setMessageCount(Math.min(8, availableCount));
    }
  }, [chatData, messageCount]);

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setInterval(() => {
        setProgress(prev => (prev < 99 ? prev + 1 : prev));
      }, 1900);
    }
    return () => clearInterval(timer);
  }, [loading]);

  const handleCreateTikTok = async () => {
    if (chatData.length === 0) {
      message.error('No messages available to create a TikTok video.');
      return;
    }
  
    setLoading(true);
    setShowUploadSteps(true); // Show the steps as soon as the process starts
    setProgress(0);
    setVideoUrl(null);
    setIsVideoCreated(false); // Reset video creation state
    const baseUrl = "https://4xyfpvz9r44pzt-8000.proxy.runpod.net";
    const createUrl = `${baseUrl}/create_video`;
    
    const selectedChatData = chatData.slice(-messageCount).map(msg => ({
      ...msg,
      senderId: msg.senderId === currentUser.uid ? "User" : msg.senderId
    }));
    
    const randomImageIndex = Math.floor(Math.random() * character.images.length);
    const randomImage = character.images[randomImageIndex];
    
    const data = {
      chat_data: selectedChatData,
      character_name: character.uid,
      ...(caption && { caption_text: `${caption.trim()}` }),
      ai_profile_image_path: character.photoURL,
      caption_image_path: randomImage
    };
  
    try {
      const response = await axios.post(createUrl, data, {
        headers: { "Content-Type": "application/json" },
        validateStatus: function (status) {
          return status >= 200 && status < 300;
        },
      });
  
      if (response.status === 202) {
        const taskId = response.data.task_id;
        await checkVideoStatus(baseUrl, taskId);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error creating TikTok video:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      message.error('Failed to create TikTok video. Please try again.');
      handleCancelVideoCreation(); // Handle cancellation if the process fails
    } finally {
      setLoading(false);
    }
  };

  const checkVideoStatus = async (baseUrl, taskId) => {
    const statusUrl = `${baseUrl}/video_status/${taskId}`;

    while (true) {
      try {
        const statusResponse = await axios.get(statusUrl);
        const statusData = statusResponse.data;

        if (statusData.state === 'SUCCESS') {
          const videoUrlResponse = await axios.get(`${baseUrl}/get_video/${taskId}`);
          const newVideoUrl = videoUrlResponse.data.video_url.replace("https://f22ad7ce5a126fa66576aa0ada8132d1.r2.cloudflarestorage.com/oniichat", "https://pub-45d257aada644f7c97773ae944d2ec4d.r2.dev");
          setVideoUrl(newVideoUrl);
          localStorage.setItem('tiktokVideoUrl', newVideoUrl);
          
          const newCaption = videoUrlResponse.data.caption;
          setApiCaption(newCaption);
          localStorage.setItem('tiktokApiCaption', newCaption);
          
          setLoading(false);
          setProgress(100);
          const newUniqueId = generateUniqueId();
          setUniqueId(newUniqueId);
          localStorage.setItem('tiktokUniqueId', newUniqueId);
          setIsVideoCreated(true); // Set video as created
          message.success('AI TikTok video created successfully!');
          break;
        } else if (statusData.state === 'FAILURE') {
          throw new Error(`Task failed: ${statusData.error || 'Unknown error'}`);
        }

        await new Promise(resolve => setTimeout(resolve, 5000));
      } catch (error) {
        console.error('Error checking video status:', error);
        message.error('Failed to check video status. Please try again.');
        handleCancelVideoCreation(); // Handle cancellation if status check fails
        break;
      }
    }
  };

  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };

  const handleCopyCaption = () => {
    const fullCaption = `${apiCaption} #oniichat #chracterai #characteraichat #cai #characteraiwebsite #characteraibot #fyp #fy ${uniqueId}`;
    navigator.clipboard.writeText(fullCaption)
      .then(() => message.success('Full caption copied to clipboard!'))
      .catch(() => message.error('Failed to copy caption. Please try again.'));
  };

  const handleDownloadVideo = () => {
    if (videoUrl) {
      const link = document.createElement('a');
      link.href = videoUrl;
      link.download = 'AI_TikTok_Video.mp4'; // Specify the download file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      message.error('Video is not available for download.');
    }
  };

  const handleCopyUniqueId = () => {
    navigator.clipboard.writeText(uniqueId)
      .then(() => message.success('Unique ID copied to clipboard!'))
      .catch(() => message.error('Failed to copy Unique ID. Please try again.'));
  };

  const handleVerifyTikTok = async () => {
    if (!tiktokLink || !uniqueId) {
      message.error('Please enter both the TikTok link and ensure a video has been created.');
      return;
    }

    if (claimedRewards[uniqueId]) {
      message.error('Reward for this video has already been claimed.');
      return;
    }

    try {
      const verifyTikTokCaption = httpsCallable(functions, 'verifyTikTokCaption');
      const result = await verifyTikTokCaption({ link: tiktokLink, caption: uniqueId });

      if (result.data.verified) {
        message.success('TikTok verified successfully! 300 gems rewarded.');

        const updatedClaimedRewards = { ...claimedRewards, [uniqueId]: true };
        setClaimedRewards(updatedClaimedRewards);
        localStorage.setItem('claimedTikTokRewards', JSON.stringify(updatedClaimedRewards));

        setModalVisible(false);
      } else {
        message.error('Verification failed. Make sure the ID is in your TikTok video caption. If you forgot it, contact our support on Discord to claim your reward.');
      }
    } catch (error) {
      console.error('Error verifying TikTok:', error);
      message.error('Failed to verify TikTok. Please try again.');
    }
  };

  const handleCancelVideoCreation = () => {
    // If the video is not fully created, keep the upload steps visible
    if (!isVideoCreated) {
      setShowUploadSteps(true);
    } else {
      // If the video is fully created, reset the steps
      setShowUploadSteps(false);
      setProgress(0);
      setVideoUrl(null);
      setTiktokLink('');
      setUniqueId('');
      setIsVideoCreated(false); // Reset video creation state
    }
  };

  const showCloseConfirmation = () => {
    confirm({
      title: 'Are you sure you want to close this window?',
      icon: <ExclamationCircleOutlined />,
      content: 'Your Quest-progress will be lost.',
      okText: 'Close Window',
      okType: 'danger',
      cancelText: 'Continue Quest',
      onOk() {
        setModalVisible(false);
        handleCancelVideoCreation();
      },
      onCancel() {
        // Do nothing, user stays on the quest
      },
      centered: true,
    });
  };

  const handleModalClose = () => {
    if (isVideoCreated) {
      showCloseConfirmation(); // Show confirmation if video is created
    } else {
      setModalVisible(false);
      handleCancelVideoCreation(); // Close normally if video is not yet created
    }
  };

  const handleHideButton = (e) => {
    e.stopPropagation();
    setButtonVisible(false);
    localStorage.setItem('hideTikTokButton', 'true');
  };

  if (!buttonVisible || availableMessageCount < 2 || currentUser.isAnonymous) {
    return null;
  }

  return (
    <>
      {buttonVisible && availableMessageCount >= 2 && !currentUser.isAnonymous && (
        <TikTokButtonWrapper>
          <TikTokButton onClick={() => setModalVisible(true)}>
            Create AI TikTok Video For 500 Free Gems (Beta)
          </TikTokButton>
          <HideButton onClick={handleHideButton}>
            <CloseOutlined style={{ fontSize: '10px' }} />
          </HideButton>
        </TikTokButtonWrapper>
      )}
      <Modal
        title={<Title level={4}>Create AI TikTok Video From Your Chat</Title>}
        visible={modalVisible}
        onCancel={handleModalClose}  // Close based on video creation state
        footer={null}
        width={800}
        afterClose={handleCancelVideoCreation} // Handle cancellation if modal is closed
      >
        <Tag color="blue">AI-Generated Video</Tag>
        <div style={{ marginTop: '15px', marginBottom: '15px', padding: '15px', backgroundColor: '#f6ffed', borderRadius: '5px', border: '1px solid #b7eb8f' }}>
          <Title level={5} style={{ color: '#52c41a', marginBottom: '10px' }}>FREE Reward Just for Uploading!</Title>
          <Text strong style={{ fontSize: '16px' }}> 
            🎉 Get an amazing <span style={{ color: '#52c41a', fontSize: '20px' }}>500 gems</span> just for uploading your AI TikTok video! 🎉
          </Text>
        </div>
        <div style={{ marginTop: '15px', marginBottom: '15px', padding: '10px', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
          <Title level={5} style={{ color: '#1890ff' }}>Special Reward!</Title>
          <Text strong>
            Get 20,000 gems and a FREE Premium Subscription when your TikTok reaches 10,000 clicks!
          </Text>
        </div>
        <Space direction="vertical" style={{ width: '100%', marginTop: '10px' }}>
          <StepTitle>1. Step</StepTitle>
          <Input
            placeholder="Enter the caption for your TikTok"
            value={caption}
            onChange={e => setCaption(e.target.value)}
            disabled={loading}
          />
          <StepTitle>2. Step</StepTitle>
          <Text>Select the number of recent messages, which will be shown within your TikTok! (Min: 2, Max: 8):</Text>
          <Select
            value={messageCount}
            onChange={setMessageCount}
            style={{ width: 120 }}
            disabled={loading || availableMessageCount < 2}
          >
            {[...Array(Math.min(8, Math.max(2, availableMessageCount)) - 1)].map((_, index) => {
              const num = index + 2;
              return (
                <Option key={num} value={num}>{num} messages</Option>
              );
            })}
          </Select>
          <StepTitle>3. Step</StepTitle>
          <InstructionHeadline>
            Click on "Create AI TikTok" and wait for it´s creation.
          </InstructionHeadline>
          <TikTokButton 
            type="primary" 
            onClick={handleCreateTikTok} 
            loading={loading} 
            icon={<CloudUploadOutlined />}
          >
            {loading ? 'Creating AI Video...' : 'Create AI TikTok'}
          </TikTokButton>
        </Space>
        {showUploadSteps && (
          <>
            <ProgressWrapper>
              <Progress percent={progress} status={loading ? "active" : "normal"} />
            </ProgressWrapper>
            {loading && (
              <Alert
                message="Video Creation Process"
                description={
                  <Text>
                    Creating your AI TikTok video takes about 2-3 minutes. You can close this window and continue chatting while we work on your video. 
                    <span style={{ color: 'red', fontWeight: 'bold' }}> Please don't leave the chat or close this tab until the video is ready!</span>
                  </Text>
                }
                type="info"
                showIcon
                style={{ marginBottom: '20px' }}
              />
            )}
            {videoUrl && (
            <>
              {isVideoLoading && <Spin tip="Loading video..." />}
              <VideoPreview 
                ref={videoRef} 
                src={videoUrl} 
                controls 
                onLoadedData={handleVideoLoad}
                onError={handleVideoError}
                isVisible={isVideoVisible}
              />
            </>
            )}
            <InstructionsWrapper>
              <Title level={5}>How to Share Your AI Video on TikTok:</Title>
              <Steps direction="vertical" size="small" current={-1}>
                <Step 
                  title={<BlackText>Download AI Video</BlackText>} 
                  description={
                    <>
                      <BlackText>After the AI Video has been created: Click "Download AI Video" to save it to your device.</BlackText>
                      <DownloadButton
                        type="primary"
                        size="small"
                        onClick={handleDownloadVideo}
                        icon={<DownloadOutlined />}
                      >
                        Download AI Video
                      </DownloadButton>
                    </>
                  }
                />
                <Step title={<BlackText>Open TikTok App</BlackText>} description={<BlackText>Launch the TikTok app on your device.</BlackText>} />
                <Step title={<BlackText>Upload AI Video</BlackText>} description={<BlackText>Tap the "+" button, within TikTok, and select your AI-generated video.</BlackText>} />
                <Step title={<BlackText>Add Music</BlackText>} description={<BlackText>Consider adding a trending song to your video.</BlackText>} />
                <Step title={<BlackText>Edit Caption</BlackText>} description={
                  <BlackText>
                    Use your caption, by clicking on "Copy Full Caption" and paste it within the TikTok app in your description/caption, also add some fitting Hashtags, like the ones listed onby: 
                    <strong>{apiCaption} #oniichat #chracterai #characteraichat #cai #characteraiwebsite #characteraibot #fyp #fy</strong>
                    <br />
                    Important: Include your unique ID in the caption for claiming the reward: <strong>{uniqueId}</strong>
                    <br />
                    <Button 
                      type="primary" 
                      size="small" 
                      onClick={handleCopyCaption}
                      style={{ marginTop: '10px' }}
                    >
                      Copy Full Caption
                    </Button>
                  </BlackText>
                } />
                <Step title={<BlackText>Post</BlackText>} description={<BlackText>Tap 'Post' to share your AI-generated video on TikTok! Make sure, that your TikTok goes viral, in order to receive the "Special Reward", to achieve that, you can edit your Caption, Hashtags however you prefer!</BlackText>} />
              </Steps>
              <Text>TikTok Upload Link: <a href="https://www.tiktok.com/upload" target="_blank" rel="noopener noreferrer">https://www.tiktok.com/upload</a></Text>
              <StepTitle>4. Step</StepTitle>
              <Input
                placeholder="Enter your TikTok link after uploading"
                value={tiktokLink}
                onChange={e => setTiktokLink(e.target.value)}
                disabled={loading}
                prefix={<LinkOutlined />}
                style={{ marginTop: '10px' }}
              />
              <Button 
                onClick={handleVerifyTikTok} 
                icon={<GiftOutlined />}
                disabled={!tiktokLink}
                style={{ marginTop: '8px' }}
              >
                Verify & Get Reward
              </Button>
            </InstructionsWrapper>
          </>
        )}
      </Modal>
    </>
  );
};

export default CreateTikTokButton;

import React, { useState, useContext, useEffect } from "react";
import { createUserWithEmailAndPassword, updateProfile, signInWithPopup, GoogleAuthProvider, linkWithCredential, EmailAuthProvider } from "firebase/auth";
import { auth, db, AuthErrors, logEvent } from "../../firebase";
import { doc, setDoc, getDoc, updateDoc, writeBatch } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import { CharacterContext } from "../../context/CharacterContext";

import { message } from "antd"
import { Checkbox } from 'antd';
import glogo from "../../img/Google_logo.svg"
import { useLocation } from 'react-router-dom';
import tracking from "../../tracking";

const Register = () => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const { targetPage } = useContext(ChatContext)
  const { characterData } = useContext(CharacterContext)
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false); // State to track if terms are accepted
  const navigate = useNavigate();
  console.log('kjhskajdhsa', currentUser)
  const handleCheckboxChange = (e) => {
    setIsTermsChecked(e.target.checked);
  };
  const signInWithGoogle = async () => {
    if (!isTermsChecked){
      setErr(true);
      setErrMessage('Please fill terms and conditions');
      return;
    }
    const provider = new GoogleAuthProvider();
    setLoading(true);
    const batch = writeBatch(db);
    try {
      const tempSessionId = auth.currentUser.uid;
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (!userDoc.exists()) {
        await setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          gems: 100,  
          ...tracking.getTrackingParams(),
          subscription: "None",
          email: user.email,
          xp: 0,
          maxXP: 100,
          level: 1
        });
      }
      message.success('Logged in successfully');
      setLoading(false);
      navigate("/selectchat");
    } catch (error) {
      logEvent('sign_in_with_google_failed', { error_message: error.message });
      message.error(AuthErrors[error.code] || error.message);
      setLoading(false);
    }
  }
  const renderLoading = () => (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isTermsChecked){
      setErr(true);
      setErrMessage('Please fill terms and conditions');
      return;
    }
    setLoading(true);
    const displayName = "Oniichan";
    const email = e.target[0].value;
    const password = e.target[1].value;
    var res = null;
    try {
      if (currentUser != null && currentUser.email == null) {
        try {
          const credential = EmailAuthProvider.credential(email, password);
          console.log(credential);
          await convertAnonymToPermanent(credential);
          await setDoc(doc(db, "users", currentUser.uid), {
            uid: currentUser.uid,
            email: currentUser.email,
            gems: 200,
            subscription: "None",
            xp: 0,
            maxXP: 100,
            level: 1,
            ...tracking.getTrackingParams()
          });
          setLoading(false);
          navigate(`/multistepform`);
        } catch (err) {
          console.error("register error", err);
          setErrMessage(AuthErrors[err.code]);
          setErr(true);
          setLoading(false);
        }
      } else {
        console.log("create user");
        res = await createUserWithEmailAndPassword(auth, email, password);
        console.log(res);
        const date = new Date().getTime();
        try {
          await updateProfile(res.user, {
            displayName,
          });
          await setDoc(doc(db, "users", res.user.uid), {
            uid: res.user.uid,
            email: email,
            gems: 200,
            subscription: "None",
            xp: 0,
            maxXP: 100,
            level: 1
          });
          navigate(`/multistepform`);
        } catch (err) {
          console.error(err);
          setErr(true);
          setErrMessage(err.code);
          setLoading(false);
        }
      }
    } catch (err) {
      console.error(err);
      setErr(true);
      setErrMessage(err.code);
      setLoading(false);
    }
  };
  
  const convertAnonymToPermanent = async (credential) => {
    await linkWithCredential(auth.currentUser, credential)
  }
  useEffect(()=>{
    if(currentUser?.uid && !currentUser.isAnonymous){
      navigate('/')
    }
  }
)
  return (
    <div>
      {loading ? renderLoading() : (
        <div className="formContainer">
          <div className="formWrapper">
            <h2>Register</h2>
            <form onSubmit={handleSubmit}>
              <input required type="email" placeholder="Please enter a valid email..." />
              <input required type="password" placeholder="Please enter your password..." />
              <Checkbox checked={isTermsChecked} onChange={handleCheckboxChange}>I agree to the <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">terms of use</a> and <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a></Checkbox>
              <button disabled={loading} className="button">Sign up</button>
            </form>
            {err && <span>{errMessage}</span>}
            <button className="signInButton" onClick={signInWithGoogle} disabled={loading}>
              <img src={glogo} alt="" />
            </button>
            <p>
              You do have an account? <Link to="/login">Login</Link>
            </p>

          </div>
        </div>
      )}
    </div>
  );
};

export default Register;

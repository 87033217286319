import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Input, message, Typography, Space, Progress } from 'antd';
import { CopyOutlined, GiftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { AuthContext } from "../context/AuthContext";
import { db, functions, httpsCallable } from "../firebase"; // Adjust the import path as necessary
import styled from 'styled-components';

// Import the icons
import youtubeIcon from '../img/shopPromo/youtube.png';
import tiktokIcon from '../img/shopPromo/tiktok.webp';
import redditIcon from '../img/shopPromo/reddit.png';
import instagramIcon from '../img/shopPromo/instagram.webp';
import discordIcon from '../img/shopPromo/discord.webp';
import xIcon from '../img/shopPromo/x.png'; // Add your X (Twitter) icon here

const { Title, Paragraph, Text } = Typography;

const StyledButton = styled(Button)`
  background: #57a7ff;
  border: none;
  border-radius: 20px;
  color: #f8f8f8;
  font-weight: bold;
  height: 36px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  margin-bottom: 0.5rem;

  &:hover, &:focus {
    color: #f8f8f8;
    box-shadow: 0 0 10px rgba(87, 167, 255, 0.5);
  }

  .anticon {
    font-size: 16px;
    margin-right: 6px;
  }
`;

const ProgressWrapper = styled.div`
  margin-top: 20px;
  background: #f0f25;
  border-radius: 10px;
  padding: 15px;
`;

const ProgressText = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
`;

// Base IconWrapper with responsive design and hover effect
const IconWrapper = styled.a`
  display: inline-block;
  border-radius: 10px;
  margin: 0 5px; /* Ensure symmetrical spacing between icons */
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

// Individual icon styles
const TikTokIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const InstagramIcon = styled.img`
  width: 60px;
  height: 60px;
`;

const DiscordIcon = styled.img`
  width: 60px;
  height: 60px;
`;

const RedditIcon = styled.img`
  width: 45px;
  height: 45px;
`;

const YouTubeIcon = styled.img`
  width: 46px;
  height: 46px;
  margin-left: 15px; /* Add some extra margin to move the icon more to the right */
`;

const XIcon = styled.img`
  width: 70px;
  height: 46px;
`;

// Updated IconsSection with no gap and using margin for symmetrical spacing
const IconsSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const EarnWithOniichat = () => {
  const { currentUser, userData } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [affiliateLink, setAffiliateLink] = useState('');
  const [signups, setSignups] = useState(0);
  const [lastRewardedCount, setLastRewardedCount] = useState(0);
  const isAffiliateProgramFull = signups >= 15;
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);

  useEffect(() => {
    const checkAndRewardUser = async () => {
      if (currentUser && currentUser.uid && userData) {
        const baseUrl = 'https://oniichat.com';
        const uniqueId = currentUser.uid;
        const exotrackerParam = `exotracker=${uniqueId}`;
        const fullAffiliateLink = `${baseUrl}?${exotrackerParam}`;
        setAffiliateLink(fullAffiliateLink);

        setSignups(userData.affiliateSignups || 0);
        setLastRewardedCount(userData.lastRewardedCount || 0);

        // Check if we need to reward the user
        if (userData.affiliateSignups > (userData.lastRewardedCount || 0)) {
          const rewardUser = httpsCallable(functions, 'rewardAffiliateSignups');
          try {
            const result = await rewardUser({ 
              userId: uniqueId, 
              newSignups: userData.affiliateSignups, 
              lastRewardedCount: userData.lastRewardedCount || 0
            });
            console.log(result.data);
            message.success('You have been rewarded for new signups!');
          } catch (error) {
            console.error('Error rewarding user:', error);
          }
        }
      }
    };

    checkAndRewardUser();
  }, [currentUser, userData]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openInstructionsModal = () => setIsInstructionsModalOpen(true);
  const closeInstructionsModal = () => setIsInstructionsModalOpen(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(affiliateLink);
    message.success('Affiliate link copied to clipboard!');
  };

  return (
    <>
      <StyledButton
        onClick={openModal}
        icon={<GiftOutlined />}
      >
        Path to Gem Richeness!
      </StyledButton>

      <Modal
        title={<Title level={4}>Boost Your Gem Collection with Oniichat!🚀🔥</Title>}
        visible={isModalOpen}
        onCancel={closeModal}
        footer={null}
        width={500}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Paragraph>
            Ready to level up your experience?🤔 Share your unique referral link, and start earning gems for every new signup! Whether it's on Discord, TikTok, Instagram, YouTube Shorts, Reddit, X or other forums: It's easy to get started.🚀 Plus, amazing rewards await you!👾
          </Paragraph>

          <Input.Group compact>
            <Input 
              style={{ width: 'calc(100% - 100px)' }} 
              value={affiliateLink} 
              readOnly 
            />
            <Button 
              type="primary" 
              icon={<CopyOutlined />} 
              onClick={copyToClipboard}
              style={{ width: '100px', background: '#57a7ff', borderColor: '#57a7ff' }}
            >
              Copy
            </Button>
          </Input.Group>

          <Paragraph>
            <Text strong>How it works:</Text>
            <ul>
              <li>Share your unique affiliate link.🔗</li>
              <li>Friends sign up using your link and verify their account.✅</li>
              <li>You earn gems for each verified signup!💎</li>
            </ul>
          </Paragraph>
          <Button 
            type="link" 
            icon={<InfoCircleOutlined />} 
            onClick={openInstructionsModal}
          >
            View Detailed Sharing Instructions
          </Button>
          <ProgressWrapper>
            <ProgressText>Affiliate Program Progress</ProgressText>
            <Progress 
              percent={Math.min((signups / 15) * 100, 100)} 
              showInfo={false} 
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
            />
            {isAffiliateProgramFull ? (
              <Text strong style={{ color: '#87d068' }}>
                Congratulations! You've met the affiliate requirements. The program will be released very soon!
              </Text>
            ) : (
              <Text>
                {signups}/15 signups - {15 - signups} more to qualify for 50% revenue share and professional character creator!
              </Text>
            )}
          </ProgressWrapper>

          <Paragraph type="secondary" style={{ fontSize: '12px' }}>
            <Text strong>Note:</Text> We have protection against fraudulent activities. 
            Accounts engaging in fraud will be banned and forfeit all earnings.
          </Paragraph>

          {/* IconsSection for social media icons */}
          <IconsSection>
            <IconWrapper href="https://tiktok.com" target="_blank">
              <TikTokIcon src={tiktokIcon} alt="TikTok" />
            </IconWrapper>
            <IconWrapper href="https://discord.com" target="_blank">
              <DiscordIcon src={discordIcon} alt="Discord" />
            </IconWrapper>
            <IconWrapper href="https://instagram.com" target="_blank">
              <InstagramIcon src={instagramIcon} alt="Instagram" />
            </IconWrapper>
            <IconWrapper href="https://reddit.com" target="_blank">
              <RedditIcon src={redditIcon} alt="Reddit" />
            </IconWrapper>
            <IconWrapper href="https://youtube.com" target="_blank">
              <YouTubeIcon src={youtubeIcon} alt="YouTube" />
            </IconWrapper>
            <IconWrapper href="https://x.com" target="_blank">
              <XIcon src={xIcon} alt="X" />
            </IconWrapper>
          </IconsSection>

          <Paragraph style={{ textAlign: 'center', marginTop: '20px' }}>
            <Text strong>Click to Visit👆🏻 (Read Instructions firstℹ️)</Text>
          </Paragraph>
        </Space>
      </Modal>

      <Modal
        title={<Title level={4}>How to Share Your Oniichat Affiliate Link</Title>}
        visible={isInstructionsModalOpen}
        onCancel={closeInstructionsModal}
        footer={null}
        width={600}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Paragraph>
            <Text strong>1. Share with Friends:</Text>
            <ul>
              <li>Personally message your friends who might be interested in anime and AI chatbots.</li>
              <li>Explain how Oniichat offers a unique experience to chat with AI anime waifus.</li>
              <li>Highlight features like customizable characters and engaging conversations.</li>
            </ul>
          </Paragraph>

          <Paragraph>
            <Text strong>2. Post on Anime Forums:</Text>
            <ul>
              <li>Find popular anime forums like MyAnimeList, Reddit's r/anime, or AnimeSuki.</li>
              <li>Create a thread or post about your experience with Oniichat.</li>
              <li>Share interesting conversations or funny moments you've had with your AI waifu.</li>
              <li>Make sure to follow forum rules about promotional content.</li>
            </ul>
          </Paragraph>

          <Paragraph>
            <Text strong>3. Share on Discord:</Text>
            <ul>
              <li>Join anime-themed Discord servers.</li>
              <li>Share your affiliate link in appropriate channels (check server rules first).</li>
              <li>Engage in conversations about AI and anime, and mention Oniichat when relevant.</li>
              <li>Consider creating your own Discord server for Oniichat enthusiasts.</li>
            </ul>
          </Paragraph>

          <Paragraph>
            <Text strong>4. Social Media Sharing:</Text>
            <ul>
              <li>Post about Oniichat on your Twitter, Facebook, or Instagram.</li>
              <li>Use anime-related hashtags to reach your target audience.</li>
              <li>Share screenshots or short videos of your interactions with your AI waifu.</li>
            </ul>
          </Paragraph>

          <Paragraph>
            <Text strong>5. Content Creation:</Text>
            <ul>
              <li>Start a blog or YouTube channel about your Oniichat experiences.</li>
              <li>Create tutorials on how to get the most out of the AI waifu chat.</li>
              <li>Showcase different characters and their unique personalities.</li>
            </ul>
          </Paragraph>

          <Paragraph type="secondary">
            Remember to always be honest about your affiliation with Oniichat and respect the platforms' rules where you're sharing. Happy sharing!
          </Paragraph>
        </Space>
      </Modal>
    </>
  );
};

export default EarnWithOniichat;
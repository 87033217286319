import gem from "../../img/gem.png";
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { AuthContext } from "../../context/AuthContext";

const GemCounter= React.memo(() => {
  const { userData } = useContext(AuthContext);

  // Ensure gemCount is a number. If userData.gems is undefined, default to 0
  const gemCount = userData.gems || 0;
  
  // Update the initial state to use the gemCount
  const [displayGemCount, setDisplayGemCount] = useState(gemCount.toString());

  const buttonRef = useRef(null);
  const textMeasurementRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current && textMeasurementRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      const textWidth = textMeasurementRef.current.offsetWidth;
      if (textWidth > buttonWidth) {
        setDisplayGemCount(gemCount.toString().slice(0, -1) + '+');
      } else {
        setDisplayGemCount(gemCount.toString());
      }
    }
  }, [gemCount]);

  const gemStyle = {
    display: 'inline-block',
    height: '20px',
  };

  const buttonStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    backdropFilter: 'blur(25px)',
    color: 'white',
    borderRadius: '8px',
    border: 'none',
    fontFamily: 'PPPangramSansRounded-Bold',
    textDecoration: 'none',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
  if (userData.gems === undefined)
  {
    return ( <></>)
  }
  return (
    <>
      <span ref={textMeasurementRef} style={{ visibility: 'hidden', position: 'absolute' }}>
      </span>
      <Button 
        ref={buttonRef}
        type="primary" 
        href="/shop" 
        style={buttonStyle}
        aria-label={`You have ${gemCount} gems`}
      >
        {displayGemCount}
        <img src={gem} alt="Gem Icon" style={gemStyle}></img>
      </Button>
    </>
  );
}, (prevProps, nextProps) => {
  // Prevent re-render unless the gem count changes
  return prevProps.userData?.gems === nextProps.userData?.gems;
});

export default GemCounter;
import React, { useState, useEffect, useContext} from 'react';
import { Modal, Form, Input, Button, Checkbox, Divider, Typography, Space, message } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from "../../firebase";
import { FaGoogle } from 'react-icons/fa';
import { logEvent, db, AuthErrors } from "../../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import styles from '../../styles/SignInOut.module.scss';
import { createChatId, containsNumbers } from '../../helpers';
import { ChatContext } from '../../context/ChatContext'; 
import { CharacterContext } from '../../context/CharacterContext'; 
import { writeBatch } from "firebase/firestore"; // Import writeBatch
import { FaGem } from 'react-icons/fa';
import gem from "../../img/gem.png"
import tracking from '../../tracking';
const { Title } = Typography;
const SignInOut = ({ isVisible, onClose, redirectAfterAuth="selectChat", initialForm , images, displayType}) => {
  const [visible, setVisible] = useState(isVisible);
  const [loading, setLoading] = useState(false);
  const [isRegister, setIsRegister] = useState(initialForm === 'register');
  const navigate = useNavigate();
  const [isAgreed, setIsAgreed] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false);
  const {characterData} = useContext(CharacterContext)
  const provider = new GoogleAuthProvider();
  const { setShowSignUp } = useContext(ChatContext);
  // Handle isVisible prop changes
  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  const switchForm = () => {
    setIsRegister(!isRegister);
    setIsAgreed(false);
  };
  const renderCustomContent = () => {
    switch (displayType) {
      case 'images':
        return (
          <div className={styles.ctaContainer}>
            <div className={styles.ctaTitle}>Unlock More Features!</div>
            <div className={styles.ctaText}>
              Enhance your journey with us! Register now to unlock new features, including exclusive waifu pictures!
            </div>
          </div>
        );
      case 'features':
        return (
          <div className={styles.ctaContainer}>
            <div className={styles.ctaTitle}>Unlock More Features!</div>
            <div className={styles.ctaText}>
              Enhance your journey with us! Register now to unlock new features, including personalized stories and a dedicated memory & diary function!
            </div>
          </div>
        );
      case 'locked':
        return (
          <div className={styles.ctaContainer}>
            <div className={styles.ctaTitle}>This Waifu Is Locked!</div>
            <div className={styles.ctaText}>
              Please register/log in to unlock the waifu.
            </div>
          </div>
        );
        case 'nsfw':
          return (
            <div className={styles.ctaContainer}>
              <div className={styles.ctaTitle}>This NSFW Message Is Locked!</div>
              <div className={styles.ctaText}>
                Please register/log in to unlock the full experience.
              </div>
            </div>
          );
          case 'voice':
            return (
              <div className={styles.ctaContainer}>
                <div className={styles.ctaTitle}>Exclusive Access to Premium Content Awaits!</div>
                <div className={styles.ctaText}>
                  Discover the full, unfiltered experience with our NSFW voice memos. Register or log in now to unlock a world of exclusive content tailored just for you.
                </div>
              </div>
            );
            case 'imageGen':
              return (
                <div className={styles.ctaContainer}>
                  <div className={styles.ctaTitle}>You have hit your image generation limit!</div>
                  <div className={styles.ctaText}>
                    Looks like you have hit a limit! Don't worry, join as a member today and receive a
                    <span className={styles.ctaBonus}> 100-gem sign-up bonus!</span>
                    <img src={gem} className={styles.iconBonus} /> 
                  </div>
                </div>
              );
      default:
        return (
          <div className={styles.ctaContainer}>
            <div className={styles.ctaTitle}>You have run out of gems!</div>
            <div className={styles.ctaText}>
              Looks like you have hit a limit! Don't worry, join as a member today and receive a
              <span className={styles.ctaBonus}> 100-gem sign-up bonus!</span>
              <img src={gem} className={styles.iconBonus} /> 
            </div>
          </div>
        );
    }
  };
  const retrieveChatsBeforeSignUp = async (tempSessionId) => {
    if (!tempSessionId) {
      console.error('No tempSessionId found in localStorage');
      return null;
    }
  
    try {
      const userDocRef = doc(db, "users", tempSessionId);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.error('Temporary user document does not exist');
        return null;
      }
  
      const chat_ids = userDoc.data().chats || [];
      const chats = [];
  
      for (const chatId of chat_ids) {
        const chatDocRef = doc(db, "chats", chatId);
        const chatDoc = await getDoc(chatDocRef);
  
        if (chatDoc.exists()) {
          chats.push({ id: chatId, data: chatDoc.data() });
        }
      }
  
      return chats;
    } catch (error) {
      console.error('Error retrieving chats:', error);
      return null;
    }
  };
  
  const takeOverChats = async (temporaryChats) => {
    console.log('Starting takeOverChats function');
    const tempSessionId = localStorage.getItem('tempSessionId');
    if (!tempSessionId) {
      console.error('No tempSessionId found in localStorage');
      return;
    }
  
    // Initialize a batch write to update all chats
    const batch = writeBatch(db);
  
    // Process each chat
    for (const { id, data } of temporaryChats) {
      const messages = data.messages || [];
      console.log(`Processing ${messages.length} messages for chat ID: ${id}`);
  
      // Update messages with the new user's UID
      const updatedMessages = messages.map(message => ({
        ...message,
        senderId: message.senderId === tempSessionId ? auth.currentUser.uid : message.senderId,
      }));
  
      // Create a new chat ID and new chat document
      const newChatId = createChatId(auth.currentUser.uid, id.replace(tempSessionId, ""));
      const newChatDocRef = doc(db, "chats", newChatId);
      batch.set(newChatDocRef, { messages: updatedMessages });
      console.log(`Chat ID ${id} processed and added to batch`);
    }
  
    // Commit the batch write
    await batch.commit();
    console.log('Completed processing chats in takeOverChats function');
  };
  const signInWithGoogle = async () => {
  setLoading(true);
  const batch = writeBatch(db);
  try {
    const tempSessionId = auth.currentUser.uid;
    const temporaryChats = await retrieveChatsBeforeSignUp(tempSessionId);
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    logEvent('sign_in_with_google');

    // Fetch existing data
    const existingUserDoc = await getDoc(doc(db, "users", tempSessionId));
    let { xp, maxXP, level } = existingUserDoc.exists() ? existingUserDoc.data() : { xp: 0, maxXP: 0, level: 0 };

    xp = xp != null ? xp : 0;
    maxXP = maxXP != null ? maxXP : 100;
    level = level != null ? level : 0;

    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (!userDoc.exists()) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        gems: 100,  
        ...tracking.getTrackingParams(),
        subscription: "None",
        email: user.email,
        xp: xp,
        maxXP: maxXP,
        level: level
      });

      if(localStorage.getItem('tempSessionId')){
        await takeOverChats(temporaryChats);
        localStorage.removeItem('tempSessionId');
        localStorage.removeItem("messagesCount");
      }
    }

    message.success('Logged in successfully');
    setShowSignUp(false);
    onClose();
    setLoading(false);
  } catch (error) {
    logEvent('sign_in_with_google_failed', { error_message: error.message });
    message.error(AuthErrors[error.code] || error.message);
    setLoading(false);
  }
};
  

  const handleSendPasswordResetEmail = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        message.success('Password reset email sent');
        setIsForgotPasswordVisible(false);
      })
      .catch((error) => {
        message.error('Error sending password reset email:', error);
      });
  }

  const openForgotPasswordModal = () => {
    setIsForgotPasswordVisible(true);
  }

  const handleOk = async (values) => {
    setLoading(true);
    const { email, password } = values;
  
    try {
      const batch = writeBatch(db); // Initialize a write batch
  
      if (isRegister) {
        if (password.length < 6) {
          message.error('Password should be at least 6 characters');
          setLoading(false);
          return;
        }
        const tempSessionId = auth.currentUser.uid;
        const temporaryChats = await retrieveChatsBeforeSignUp(tempSessionId);
        localStorage.setItem('tempSessionId', auth.currentUser.uid);
        
        // Fetch existing data before creating a new account
        const existingUserDoc = await getDoc(doc(db, "users", tempSessionId));
        const { xp, maxXP, level } = existingUserDoc.exists() ? existingUserDoc.data() : { xp: 0, maxXP: 100, level: 1 };
  
        await createUserWithEmailAndPassword(auth, email, password);
  
        const userDocRef = doc(db, "users", auth.currentUser.uid);
        batch.set(userDocRef, {
          uid: auth.currentUser.uid,
          gems: 100, 
          ...tracking.getTrackingParams(),
          subscription: "None",
          email: email,
          xp: xp || 0,
          maxXP: maxXP || 100,
          level: level || 1
        });
  
        if(localStorage.getItem('tempSessionId')){
          await takeOverChats(temporaryChats);
          localStorage.removeItem('tempSessionId');
          localStorage.removeItem("messagesCount");
        }
        logEvent('register_successful');
        message.success('Account created successfully');
        onClose();
  
      } else {
        await signInWithEmailAndPassword(auth, email, password);
  
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (!userDoc.exists()) {
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          batch.set(userDocRef, {
            uid: auth.currentUser.uid,
            gems: 100,  
            ...tracking.getTrackingParams(),
            subscription: "None",
            email: email
          });
        }
  
        logEvent('login_successful');
        message.success('Logged in successfully');
        onClose();
      }
  
      await batch.commit(); // Commit the batch
      navigate("/multistepform?char=");
  
    } catch (error) {
      logEvent(isRegister ? 'register_failed' : 'login_failed', { error_message: error.message });
      message.error(AuthErrors[error.code] || error.message);
    }
  
    setLoading(false);
  };  

  const handleAgreement = () => {
    setIsAgreed(!isAgreed);
  };

  return (
    <div className={styles.signInOut}>
      <Modal
        visible={visible}
        title={isRegister ? 'Register' : 'Login'}
        onCancel={onClose}
        footer={null}
      >
        <div className={styles.modalContent}>
                  {/* Display the custom text if provided */}
                  {renderCustomContent()}
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Button className={styles.iconButton} onClick={signInWithGoogle} loading={loading}>
              <FaGoogle className={styles.googleIcon} /> Continue with Google
            </Button>
            <Divider>Or continue with</Divider>
            <Form onFinish={handleOk} className={styles.formContainer}>
              <Title level={5}>Email</Title>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'The input is not valid E-mail!' }
                ]}
              >
                <Input className={styles.inputField} placeholder="Email" />
              </Form.Item>
              <Title level={5}>Password</Title>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password className={styles.inputField} placeholder="Password" />
              </Form.Item>
              {isRegister && (
                <Form.Item>
                  <Checkbox onChange={handleAgreement}>I agree to the <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">terms of use</a> and <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a></Checkbox>
                </Form.Item>
              )}
              <Form.Item>
                <Button className={styles.submitButton} type="primary" htmlType="submit" loading={loading} disabled={isRegister ? (!isAgreed) : (false)}>
                  {isRegister ? 'Register' : 'Login'}
                </Button>
              </Form.Item>
            </Form>
            {!isRegister && (
              <div className={styles.registerLoginLink}>
                <Link onClick={openForgotPasswordModal}>Forgot Password?</Link>
              </div>
            )}
            <div className={styles.registerLoginLink}>
              {isRegister ? 'Already have an account? ' : "Don't have an account? "}
              <Link onClick={switchForm}>{isRegister ? 'Login' : 'Register'}</Link>
            </div>
          </Space>
        </div>
      </Modal>
      <Modal
        title="Forgot Password"
        visible={isForgotPasswordVisible}
        onCancel={() => setIsForgotPasswordVisible(false)}
        footer={null}
      >
        <Form onFinish={() => handleSendPasswordResetEmail(forgotPasswordEmail)}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'The input is not valid E-mail!' }
            ]}
          >
            <Input
              placeholder="Email"
              value={forgotPasswordEmail}
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Send Password Reset Email
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SignInOut;

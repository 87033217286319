import React, { useState, useEffect, useContext } from 'react';
import { collection, doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { db, logEvent } from '../../firebase';
import { AuthContext } from "../../context/AuthContext";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import multipleGems from "../../img/multipleGems.png"
import { ChatContext } from "../../context/ChatContext";
import twitter from "../../img/shopPromo/Logo_of_Twitter.svg.png"
import discordLogo from "../../img/shopPromo/Logo_of_Discord.svg"

import huggingFaceLogo from "../../img/shopPromo/huggingface_logo-noborder.svg"; // Update the path as needed
import "../../styles/DailyRewards.scss"
const rewards = [
    { day: 1, gems: 10 },
    { day: 2, gems: 20 },
    { day: 3, gems: 20 },
    { day: 4, gems: 30 },
    { day: 5, gems: 50 },
    { day: 6, gems: 70 },
];

function formatTimeRemaining(date) {
    const remainingTime = (new Date(date.getTime() + 24 * 60 * 60 * 1000)) - new Date();
    const remainingHours = Math.floor(remainingTime / (60 * 60 * 1000));
    const remainingMinutes = Math.floor((remainingTime - remainingHours * 60 * 60 * 1000) / (60 * 1000));
    return `${padZero(remainingHours)}:${padZero(remainingMinutes)} h`;
}

function padZero(number) {
    return number.toString().padStart(2, '0');
}

const DayDifference = (date1, date2) => {
    const differenceInMilliseconds = Math.abs(date1 - date2);
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays;
}

function DailyRewards({show, onShow, onClose}) {
    const { currentUser, userData } = useContext(AuthContext);
    const [postLink, setPostLink] = useState(true);

    const GemItem = ({ gems, day, onClick }) => (
        <div className="rewardItem gemItemDaily">
            <span>Day {day}</span>
            <img src={multipleGems} alt="" />
            <div className="gems">{gems}</div>
            {userData?.day + 1 === day && userData?.lastClaimed && DayDifference(userData?.lastClaimed.toDate(), new Date()) > 0.99 ? (
                <div className="rewardButton" onClick={onClick}>
                    Claim
                </div>
            ) : (
                userData?.day === 0 && day === 1 ? (<div className="rewardButton" onClick={onClick}>
                    Claim
                </div>) : (userData?.day + 1 > day ? (<div>Claimed</div>) : (userData?.day + 1 === day ? (DayDifference(userData?.lastClaimed.toDate(), new Date()) > 0.99 ? (<div>Claim Reward</div>) : (<div>Reward in {formatTimeRemaining(userData?.lastClaimed.toDate())}</div>)) : (<div></div>)))
            )}
        </div>
    );

    const QuestItem = ({ title, gems, description, onClick, instructions, link, questName, logo}) => {
        const [inputValue, setInputValue] = useState('');

        const handleClaimClick = () => {
            onClick(inputValue);
        };

        const handleInputChange = (e) => {
            setInputValue(e.target.value);
        };

        const isQuestClaimedForCurrentPost = questName === 'LikeQuest'
            ? (userData?.questsClaimed && userData?.questsClaimed.includes(questName) && userData?.lastClaimedPost === link)
            : (userData?.questsClaimed && userData?.questsClaimed.includes(questName));

        return (
            <div className="rewardItem quest">
            <span>{title}</span>
            <img src={multipleGems} alt="" />
            <div className="gems">{gems}</div>
            <div className="questDescription">{description}</div>
            <div className="instructions">{instructions}</div>
            <input
                type="text"
                placeholder="Please paste your URL..."
                value={inputValue}
                onChange={handleInputChange}
            />
            <div className="logoWrapper" onClick={() => (window.open(link, '_blank'))}>
                <img style={{ width: "35px", height: "30px", cursor: 'pointer' }} src={logo} alt="" />
                <span className="clickHereIndicator">Click here</span>
            </div>
            {isQuestClaimedForCurrentPost ? (<div>Claimed</div>) : (<div className="rewardButton" onClick={handleClaimClick}>Claim</div>)}
        </div>
        );
    };

    useEffect(() => {
        async function loadProgress() {
            if (!userData?.uid) return;

            if (!userData?.day) {
                await updateDoc(doc(db, 'users', userData?.uid), { day: 0 });
            }

            if (userData?.lastClaimed) {
                const daysDifference = DayDifference(userData?.lastClaimed.toDate(), new Date());

                if (daysDifference > 1.99) {
                    await updateDoc(doc(db, 'users', userData?.uid), { day: 0 });
                } else if (daysDifference > 0.99) {
                    onShow();
                }
            } else {
                onShow();
            }

            const postLinkDoc = await getDoc(doc(db, 'quests', 'TwitterQuests'));
            if (postLinkDoc.exists()) {
                setPostLink((postLinkDoc.data()).postLink);
            }
        }
        loadProgress();
    }, [userData]);

    async function claimReward(day, gems) {
        var rewardDay = day
        const addedGems = userData?.gems + gems
        if (rewardDay === 6) {
            rewardDay = 0
        }
        logEvent("reward claimed day: " + rewardDay)
        await updateDoc(doc(db, 'users', currentUser.uid), { day: rewardDay, gems: addedGems, lastClaimed: new Date() });
    }

    async function claimQuestReward(inputLink, link, gems, questName) {
        if (!inputLink.includes(link)) {
            alert("You have inserted an invalid link");
            return;
        }
        const questsClaimed = userData?.questsClaimed && userData?.questsClaimed[userData?.questsClaimed.indexOf(questName)] 
            ? [...userData?.questsClaimed] 
            : userData?.questsClaimed 
            ? [...userData?.questsClaimed, questName] 
            : [questName];
        const addedGems = userData?.gems + gems;

        logEvent(questName + " quest claimed");

        if (questName === 'LikeQuest') {
            await updateDoc(doc(db, 'users', currentUser.uid), { gems: addedGems, questsClaimed, lastClaimedPost: link });
        } else {
            await updateDoc(doc(db, 'users', currentUser.uid), { gems: addedGems, questsClaimed });
        }
    }

    return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" contentClassName="reward-window" show={show} onHide={() => onClose()}>
            <Modal.Header className='header' closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Your daily reward
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <span>Check back daily to claim rewards and keep the streak going.</span>
                <Container>
                    <div className='grid'>
                        {rewards.map(({ gems, day }) => (
                            <GemItem gems={gems} day={day} key={day} onClick={() => claimReward(day, gems)} />
                        ))}
                    </div>
                </Container>
                <Container className='quests'>
                    <h2>Quests</h2>
                    <div className='grid'>
                        <QuestItem 
                            title="Follower Bonus" 
                            gems={40} 
                            description="Follow our new Twitter for a free gem bonus! Remember, unfollowing will result in the loss of the reward." 
                            onClick={(inputLink) => claimQuestReward(inputLink, "https://twitter.com/oniichat?trackId=ufd435zd8d8632", 40, "FollowQuest")} 
                            instructions='Copy the URL after you followed.' 
                            link="https://twitter.com/oniichat?trackId=ufd435zd8d8632" 
                            questName="FollowQuest"
                            logo={twitter}
                        />
                        <QuestItem 
                            title="Join Discord" 
                            gems={80} 
                            description="Join our Discord for a free gem bonus! Remember, Leaving the server will result in the loss of the reward." 
                            onClick={(inputLink) => claimQuestReward(inputLink, "https://discord.com/invite/Se96PwTVm5", 80, "FollowQuestDiscord")} 
                            instructions='Copy the URL after you joined.' 
                            link="https://discord.com/invite/Se96PwTVm5" 
                            questName="FollowQuestDiscord"
                            logo={discordLogo}
                        />
                        <QuestItem 
                            title="Interact!" 
                            gems={30} 
                            description="Retweet and Like our recent post and receive a gem reward! Note that unliking our post will result in the loss of your gem reward." 
                            onClick={(inputLink) => claimQuestReward(inputLink, postLink + "?trackId=75767fg7t8z8h", 30, "LikeQuest")} 
                            instructions='Copy the URL after you liked.' 
                            link={postLink + "?trackId=75767fg7t8z8h"} 
                            questName="LikeQuest" 
                            logo={twitter}
                        />
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default DailyRewards;
import React, { useEffect, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import "../styles/UnlockedCharacters.scss"; // Make sure your CSS matches your design aesthetics
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';
import { faLock, faStar} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
const UnlockedFeature = ({ visibility, onClose }) => {
    const navigate = useNavigate()
    const popupAnimation = useSpring({
        to: {
            opacity: visibility ? 1 : 0,
            transform: visibility ? 'translateY(0)' : 'translateY(-100px)'
        },
        from: {
            opacity: 0,
            transform: 'translateY(-100px)'
        }
    });

    const handleClose = () => {
        onClose();
      //  navigate("/yourWaifu")
    };

    return (
<animated.div style={popupAnimation} className={`level-unlock-overlay ${visibility ? 'visible' : 'hidden'}`}>
    <div className="level-info">
        <h1 className='unlock-title'>Your Waifu Unlocked!</h1>
        <p className="unlock-description">
            <span className="congrats">Congratulations!</span> 
            <span className="feature-text">You've unlocked the <strong>Your Waifu</strong> feature. </span>
            <span className="personalize-text">Now, personalize and interact with your unique waifu. </span>
            <span className="dive-in">Dive into a world of customization to craft your perfect digital companion.</span>
        </p>
    </div>
    <div className="character-icon">
        <FontAwesomeIcon icon={faStar} className='star-icon' />
    </div>
    <button className="continue-button" onClick={handleClose}>Continue</button>
</animated.div>
    );
};

export default UnlockedFeature;

import { Modal, Button } from 'react-bootstrap';
import multipleGems from "../../img/multipleGems.png"
import { AuthContext } from "../../context/AuthContext";
import { useState, useContext } from 'react';
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  FieldValue                               
} from "firebase/firestore";
import {logEvent } from "../../firebase";
import { useNavigate,  Redirect } from "react-router-dom";
import { ChatContext } from '../../context/ChatContext';
const AdvancedAIModal = ({ show, handleClose, character }) => {
  const navigate = useNavigate();
    const { currentUser, userData } = useContext(AuthContext);
    const {setDisplayType, setShowSignUp} = useContext(ChatContext)
    const BuyOffer = async (productName, productPrice, type="product", productID="") => {
        logEvent(`Clicked to checkout through popup ${productName} ${productPrice}`)
        if(currentUser === null)
        {
          localStorage.setItem("targetPage", "shop");
          window.location.href = "/login"
        }
        else
        {
          navigate(`/checkout/${productName}/${productPrice}/${type}/${productID}`)
    }
    }
    const handleClickWithoutLogin = () => {
      setDisplayType("features")
      setShowSignUp(true)
  }
  return (
    <Modal show={show} onHide={handleClose} size="lg" contentClassName="subscription-window">
      <Modal.Header closeButton>
        <Modal.Title><span>Advanced AI Oniichat Pro</span></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <video
              src="https://1637693156.rsc.cdn77.org/web/video/paid_features_advanced_ai/paid_features_advanced_ai.mp4"
              alt=""
              style={{ width: '100%', borderRadius: "24px" }}
              autoPlay
              loop
            />
          </div>
          <div className="col-md-6">
            <h4><span>Interact with {character && character.displayName} on a new level with the help of an advanced language model.</span></h4>
            <div className='subscriptions'>
    <div className="subscriptionItem">
    <div className='banner'><span className='bannerText'>Highest value</span></div>
      <div className='product'><span>Ultimate Plan</span></div>
      <div className="price">9.99$/month</div>
      <div className="priceButton" onClick={async () => navigate("/shop")}>
      Continue
      </div>
    </div>
    </div>
    <div className='learnMore'><a href="/shop">Learn more</a></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AdvancedAIModal;
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal, Upload, Form, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styles from '../../../styles/StoryStore.module.scss';
import gem from "../../../img/gem.png"
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db } from "../../../firebase";
const { TextArea } = Input;
/**
 * A form for user to create their custom story.
 */
function CustomStoryForm({ onSubmit, visible, setVisible, initialValues, isEditMode  }) {
  const [form] = Form.useForm();
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const buttonText = isEditMode ? 'Save Changes' : 'Create';
  const gemCost = isEditMode ? '200' : '5000';
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if (isEditMode && initialValues) {
      setImageUrl(initialValues.img);  // <- set image URL instead of fetching image

      // Set form fields
      form.setFieldsValue({
        title: initialValues.title,
        description: initialValues.description,
      });
    } else {
      setImageUrl(null);  // <- reset image URL in 'create' mode
    } 
  }, [isEditMode, initialValues, form]);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    form.validateFields().then(values => {
      if (!isEditMode) { // 'create' mode
        if (imageFile && !uploadError) {
          const storage = getStorage();
          const storageRef = ref(storage, 'stories/' + imageFile.name);
          const uploadTask = uploadBytesResumable(storageRef, imageFile);
  
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            }, 
            (error) => {
              console.error('Upload failed:', error);
              message.error('There was an error uploading your image. Please try again.');
              setIsLoading(false);
            }, 
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                onSubmit({ ...values, img: downloadURL }, null, parseInt(gemCost))
                  .then(() => {
                    form.resetFields();
                    setImageFile(null);
                    setImageUrl(null);
                    setVisible(false);
                  })
                  .catch(() => {
                    message.error('There was an error submitting your story. Please try again.');
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              });
            }
          );
        }
      } else { // 'edit' mode
        if (imageFile && !uploadError) { // image has been replaced
          const storage = getStorage();
          const storageRef = ref(storage, 'stories/' + imageFile.name);
          const uploadTask = uploadBytesResumable(storageRef, imageFile);
  
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            }, 
            (error) => {
              console.error('Upload failed:', error);
              message.error('There was an error uploading your image. Please try again.');
              setIsLoading(false);
            }, 
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                onSubmit({ ...values, img: downloadURL }, initialValues.id, gemCost)
                  .then(() => {
                    form.resetFields();
                    setImageFile(null);
                    setImageUrl(null);
                    setVisible(false);
                  })
                  .catch(() => {
                    message.error('There was an error updating your story. Please try again.');
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              });
            }
          );
        } else { // no image change
          onSubmit({ ...values, img: imageUrl }, initialValues.id, gemCost)
            .then(() => {
              form.resetFields();
              setImageFile(null);
              setImageUrl(null);
              setVisible(false);
            })
            .catch((error) => {
              console.log('Caught an error:', error);
              message.error('There was an error updating your story. Please try again.');
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });
  }, [uploadError, form, onSubmit, imageFile, imageUrl, initialValues, setVisible, isEditMode, gemCost]);
  
  const handleImageUpload = useCallback((file) => {
    // We do not need to check the file status here because we handle the file manually
    setImageFile(file);
    form.setFieldsValue({ imageName: file.name });
    message.success('Image uploaded successfully!');
  }, [form]);
  

  const beforeImageUpload = useCallback((file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
      setUploadError(true);
    } else {
      // Manually handle the file
      handleImageUpload(file);
      setUploadError(false);
    }
    // Prevent Upload from automatically uploading the file
    return false;
  }, [handleImageUpload]);

  return (
    <Modal visible={visible} closeable={true} footer={null} className={styles.customStoryModal} onCancel={() =>{setVisible(false);}}>
      <div className={styles.customStoryForm}>
      <h3>{isEditMode ? "Edit Custom Story" : "Create Custom Story"}</h3>
        <Form form={form} layout="vertical">
          <Form.Item
            name="title"
            rules={[{ required: true, message: 'Please input your story title!' }]}
          >
            <Input placeholder="Title" className={styles.customStoryInput} />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true, message: 'Please input your story description!' }]}
          >
            <TextArea
              placeholder="Description (Provide a brief description of your story for the AI)"
              className={styles.customStoryTextArea}
              autoSize={{ minRows: 4, maxRows: 8 }}
            />
          </Form.Item>
          <div className={styles.customStoryFileUpload}>
          {imageUrl && !imageFile && <img src={imageUrl} alt="preview" className={styles.previewImage} />}  {/* <- display current image */}
          <Upload
            accept="image/*"
            beforeUpload={beforeImageUpload}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />} className={styles.customStoryUploadButton}>
              Upload Image
            </Button>
          </Upload>
          {imageFile && <span>{imageFile.name}</span>}
          </div>
          <div className={styles.customStoryButtons}>
            <Button onClick={() => setVisible(false)} className={styles.customStoryCancelButton}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} className={styles.customStorySubmitButton} loading={isLoading}>
              <div>{gemCost}<img src={gem} alt="" className='gem'></img></div><div>{buttonText}</div>
            </Button>
          </div>
        </Form>
        <p className={styles.customStoryNote}>
          Note: By creating a custom story, you can unleash your imagination and bring your unique narrative to life!
        </p>
      </div>
    </Modal>
  );
  
}

CustomStoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isEditMode: PropTypes.bool
};

export default CustomStoryForm;

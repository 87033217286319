import React, { useEffect, useContext } from 'react';
import { useSpring, animated, config } from 'react-spring';
import "../styles/UnlockedCharacters.scss";
import gemIcon from "../img/gem.png"
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';
const UnlockedLevel = ({ level, characters, visibility, onClose }) => {
    const {currentUser, userData} = useContext(AuthContext)
    const {setDisplayType, setShowSignUp} = useContext(ChatContext)
  const popupAnimation = useSpring({
    opacity: visibility ? 1 : 0,
    transform: visibility ? 'translateY(0)' : 'translateY(-100px)',
    display: visibility ? 'flex' : 'none', // Add this line
    config: config.gentle,
    onRest: () => !visibility && onClose(), // Invoke onClose when animation finishes and visibility is false
  });

  // Effect to handle the visibility and unmount logic
  useEffect(() => {
    if (!visibility) {
      popupAnimation.opacity.start(0);
      popupAnimation.transform.start('translateY(-100px)');
      // Delay the 'none' display to allow the animation to complete
      setTimeout(() => popupAnimation.display.start('none'), 500);
    }
  }, [visibility, popupAnimation]);
const handleClose = () => {
    if(currentUser.isAnonymous)
    {
        setDisplayType("")
        setShowSignUp(true)
    }
    else{
onClose()
    }
}
  return (
    <animated.div style={popupAnimation} className={`level-unlock-overlay ${visibility ? 'visible' : ''}`}>
      <div className="level-info">
        <h1>New Level Unlocked!</h1>
        <h2>Level {level}</h2>
        <div className="gems-earned">
          <img src={gemIcon} alt="Gem" className="gem-icon" />
          <span>{userData.level === 2 && currentUser?.isAnonymous ? "+100 Gems": "+10 Gems"}</span>
        </div>
      </div>
      <div className="characters-row">
        {characters.slice(0, 3).map(character => ( // Display only up to 3 characters
          <div key={character.id} className="character-card">
            <div className="character-image">
              <img src={character.image} alt={character.name} className="character-avatar-image"/>
            </div>
            <h3 className="character-image-name">{character.name}</h3>
          </div>
        ))}
      </div>
      <button className="continue-button" onClick={handleClose}>Continue</button>
    </animated.div>
  );
};

export default UnlockedLevel;

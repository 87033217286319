import React, {useContext, useState, useEffect} from 'react';
import { AuthContext } from '../context/AuthContext.js';
// Assuming you have the `SubscriptionPanel` component imported or defined elsewhere in your project
import { signOut, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, GithubAuthProvider,reauthenticateWithPopup, linkWithPopup, EmailAuthProvider, fetchSignInMethodsForEmail, reauthenticateWithCredential, deleteUser} from "firebase/auth";
import { auth  } from "../firebase";
import {SubscriptionPanel, getPlanPrice, getFeaturesBySubscription} from '../components/SubscriptionPanel.jsx';
import styles from '../styles/FirstCancelWarning.module.css';
import { useNavigate, Link } from "react-router-dom";
import {AuthErrors} from "../firebase"
import { logEvent, db } from "../firebase";
import { Form, Input, Button, Alert, Modal, Card, Divider, message, Row, Col} from 'antd';
import { 
  doc, 
  setDoc, 
  getDoc, 
  serverTimestamp, 
  Timestamp, 
  updateDoc, 
  collection, 
  addDoc, 
  deleteDoc
} from "firebase/firestore";
const featuresForBasic = [
  { text: '375 gems/month', available: true },
  { text: 'Unlock all images, including NSFW content, for free.', available: true },
  { text: 'Unlock blurred NSFW messages for free.', available: true },
  { text: "20 Your Waifu Generations", available: true },
  { text: 'Experience lightning-fast loading times.', available: false },
  { text: 'Advanced AI language Model', available: false }
];

const featuresForPlus = [
  { text: '600 gems/month', available: true },
  { text: 'Unlock all images, including NSFW content, for free.', available: true },
  { text: 'Unlock blurred NSFW messages for free.', available: true },
  { text: "20 Your Waifu Generations", available: true },
  { text: 'Experience lightning-fast loading times.', available: false },
  { text: 'Advanced AI language Model', available: false }
];

const featuresForUltimate = [
  { text: '750 gems/month', available: true },
  { text: 'Unlock all images, including NSFW content, for free.', available: true },
  { text: 'Unlock blurred NSFW messages for free.', available: true },
  { text: 'Infinite free Messages', available: true },
  { text: 'Experience lightning-fast loading times.', available: true },
  { text: 'Advanced AI language Model', available: true }
];
const SecondCancelWarning = () => {
  const [showAbuseWarningModal, setShowAbuseWarningModal] = useState(false);
    const [saleValue, setSaleValue] = useState(0); // Replace with your sale value
    const [isBestValue, setIsBestValue] = useState(false); // Replace with your logic or variable
    const {userData, currentUser} = useContext(AuthContext)
    const [calculatedFeatures, setCalculatedFeatures] = useState(null)
    const [showReAuthModalSubscription, setShowReAuthModalSubscription] = useState(false);
    const navigate = useNavigate();
    const ReAuthModal = ({ visible, onSubmit, onCancel, title, message }) => {
      const [password, setPassword] = useState('');
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(null);
      const [failedAttempts, setFailedAttempts] = useState(
        parseInt(localStorage.getItem('failedAttempts') || '0')
      );
      const [nextAttemptTime, setNextAttemptTime] = useState(
        parseInt(localStorage.getItem('nextAttemptTime') || '0')
      );
      
      useEffect(() => {
        localStorage.setItem('failedAttempts', failedAttempts.toString());
        localStorage.setItem('nextAttemptTime', nextAttemptTime.toString());
      }, [failedAttempts, nextAttemptTime]);
    
      const handleSubmit = async () => {
        const currentTime = Date.now();
        if (currentTime < nextAttemptTime) {
          setError('Too many login attempts. Please try again later.');
          return;
        }
    
        setError(null); // Clear the previous error message
        if(!password || password.trim() === '') {
          setError('Password cannot be empty');
          return;
        }
        setLoading(true);
        try {
          const authProvider = EmailAuthProvider.credential(currentUser.email, password);
          await reauthenticateWithCredential(currentUser, authProvider);
          await onSubmit(); // Call onSubmit prop after successful re-authentication
          onCancel(); // Call onCancel prop to hide the modal
          setFailedAttempts(0); // Reset the failed attempts
        } catch (error) {
          console.error(error);
          let errorMessage;
          if (error.code === 'auth/wrong-password') {
            errorMessage = 'Incorrect password. Please try again in half an hour.';
            setFailedAttempts(failedAttempts + 1);
            setNextAttemptTime(currentTime + 1800000); // 30 minutes from now
          } else {
            errorMessage = AuthErrors[error.code] || 'An error occurred while re-authenticating. Please try again later.';
          }
          setError(errorMessage);
        } finally {
          setLoading(false);
        }
      };
      const handleGoogleSignIn = async () => {
        const currentTime = Date.now();
        if (currentTime < nextAttemptTime) {
          setError('Too many login attempts. Please try again later.');
          return;
        }
        setError(null);
        setLoading(true);
        try {
          const provider = new GoogleAuthProvider();
          await reauthenticateWithPopup(currentUser, provider)
            .then((result) => {
              console.log(result.user);
            })
            .catch((error) => {
              console.error(error);
              throw error; // Throw the error so it can be caught in the catch block below
            });
          await onSubmit(); // Call onSubmit prop after successful re-authentication
          onCancel(); // Call onCancel prop to hide the modal
        } catch (error) {
          console.error(error);
          let errorMessage = AuthErrors[error.code] || 'An error occurred while re-authenticating with Google. Please try again later.';
          setError(errorMessage);
        } finally {
          setLoading(false);
        }
      };
      return (
        <Modal
          title={title || "Re-authenticate"}
          visible={visible}
          onOk={handleSubmit}
          onCancel={onCancel}
          okText="Submit"
          cancelText="Cancel"
          confirmLoading={loading}
        >
          {message && <Alert message={message} type="info" showIcon />}
          {error && <Alert message={error} type="error" showIcon />}
          <Form layout="vertical">
            <Row gutter={16}>
              <Col xs={24} sm={24}>
                <Form.Item label="Password" required>
                  <Input.Password 
                    value={password} 
                    onChange={e => setPassword(e.target.value)} 
                    placeholder="Please enter your password for verification" 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24}>
                <Button type="primary" onClick={handleGoogleSignIn}>Re-authenticate with Google</Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      );
    };
    const cancelSubscription = async () => {
  
      if (!currentUser.isAnonymous) {
        throw new Error("You must be logged in to cancel your subscription");
      }
      const userDoc = await getDoc(doc(db, "users", currentUser.uid));
      const userData = userDoc.data();
      
      if (!userData || userData.subscription === "None") {
        throw new Error("You don't have an active subscription");
      }
      
      const data = {
        subscription_id: userData.subscription_id,
        uid: currentUser.uid
      };
      
      const res = await fetch('https://fathomless-gorge-94679.herokuapp.com/api/cancelsubscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) 
      });
    
      if (!res.ok) {
        const error = await res.text();
        throw new Error(error);
      }
      console.log("response", res)
    };
    const onProceed = async () => {
      try {
        await cancelSubscription();
      } catch (error) {
        message.error('Failed to cancel subscription: ' + error.message);
        return; // If the subscription cancellation fails, return early without deleting the account
      }
    }
    useEffect(() => {
        if(userData)
        {
        const { features, saleValue, isBestValue } = getFeaturesBySubscription(userData.subscription);
        setSaleValue(saleValue)
        setIsBestValue(isBestValue)
        setCalculatedFeatures(features)
        }
    }, [userData]);
    const AbuseWarningModal = ({ visible, onConfirm }) => {
      return (
        <Modal
          title="IMPORTANT WARNING"
          visible={visible}
          onOk={onConfirm}
          okText="I Understand"
          okButtonProps={{ style: { backgroundColor: 'red', borderColor: 'red' } }}
          cancelButtonProps={{ disabled: true }}
        >
          <div style={{ color: 'red', fontSize: '24px', fontWeight: 'bold' }}>
          Unusual activity detected on your Oniichat account! Please avoid frequent subscription changes! Repurchases of the subscription after cancellation are allowed once every 10 days! An exception permits you to repurchase your subscription now. Further violations may lead to a PERMANENT BAN.
          </div>
        </Modal>
      );
    };
    
    return (
        (userData && calculatedFeatures) && (
      <div className={styles.container}>
                <ReAuthModal 
          visible={showReAuthModalSubscription} 
          onSubmit={onProceed}  
          onCancel={() => {setShowReAuthModalSubscription(false);}}
          title="Confirm Subscription Cancellation"
          message="For your security, please re-enter your password to confirm cancellation of your subscription."
        />
        <h1 className={styles.heading}>STOP {userData.FormData.name}! STAY WITH US and enjoy the full oniichat experience FOR EVERY PRICE RANGE </h1>
  
    <div className="row">
    <div className="col-lg-4">
  <SubscriptionPanel 
    planName="Basic Plan" 
    planPrice="$4.99" 
    currentPlan={userData.subscription}
    onUpgradeClick={() => navigate("/shop")}
    features={featuresForBasic}
    saleValue="4x"
    isBestValue={false}
  />
</div>
<div className="col-lg-4">
  <SubscriptionPanel 
    planName="Plus Plan" 
    planPrice="$7.99"  
    currentPlan={userData.subscription}
    onUpgradeClick={() => navigate("/shop")}
    features={featuresForPlus}
    saleValue="7x"
    isBestValue={false}
  />
</div>
<div className="col-lg-4">
  <SubscriptionPanel 
    planName="Ultimate Plan" 
    planPrice="$9.99" 
    currentPlan={userData.subscription}
    onUpgradeClick={() => navigate("/shop")}
    features={featuresForUltimate}
    saleValue="11x"
    isBestValue={true}
  />
</div>
    </div>

        <p className={styles.supportLink}>
        If you have any questions or problems please contact our 24/7 support team at support@oniichat.com
        </p>
        <button className="button" onClick={() => navigate("/shop")}>EXPLORE</button>
        <div className={styles.cancelLink}>
          <a onClick={() => navigate("/shop")}>PROCEED WITH STAYING</a>
        </div>
        <AbuseWarningModal 
        visible={showAbuseWarningModal} 
        onConfirm={() => {setShowAbuseWarningModal(false); setShowReAuthModalSubscription(true);}} 
      />
        <div className={styles.cancelLink}>
        <a onClick={() => {
          setShowAbuseWarningModal(true); 
        }}>INITIATE CANCELLATION</a>
        </div>
      </div>)
  );
}

export default SecondCancelWarning;
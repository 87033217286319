
import React, { useRef, useContext, useEffect, useState } from "react";

const FAQ = () => {

  useEffect(() => {
  });

  return (
    <div className="faq">
<div className="faq-header">Oniichat Gems FAQ</div>

<div className="faq-content">
  <div className="faq-question">
    <input id="q1" type="checkbox" className="panel"/>

    <label for="q1" className="panel-title">What are Gems?</label>
    <div className="panel-content">Oniichat Gems are a premium currency that can be used to purchase items on Oniichat that enhance the adult chatroom experience. They can be purchased from the Shop using PayPal or credit card.</div>
  </div>
  
  <div className="faq-question">
    <input id="q2" type="checkbox" className="panel"/>

    <label for="q2" className="panel-title">What can I buy with Gems?</label>
    <div className="panel-content">Gems serve as a versatile currency, allowing you to send messages, unlock exclusive content such as pictures and messages, access new chat features, and even boost waifu submissions.</div>
  </div>
  
  <div className="faq-question">
    <input id="q3" type="checkbox" className="panel"/>

    <label for="q3" className="panel-title">I bought Gems, how can I tell how much I have?</label>
    <div className="panel-content">Oniichat Gems are displayed on the header menu (next to profile), as well as within the chat (next to the Waifu-profile) of the Oniichat website.</div>
  </div>
  <div className="faq-question">
    <input id="q4" type="checkbox" className="panel"/>

    <label for="q4" className="panel-title">Can I get Gems for Free?</label>
    <div className="panel-content">Yes! By signing up you'll instantly receive 200 gems. You can also earn gems through twitter quests and daily rewards.</div>
  </div>
  <div className="faq-question">
    <input id="q5" type="checkbox" className="panel"/>

    <label for="q5" className="panel-title">Why is my purchase pending?</label>
    <div className="panel-content">Occasionally when trying to purchase Oniichat Gems, your transaction may not be completed right away due to many factors that are outside of our control, such as the payment method used or current problems with your financial institution/account.

You will only receive your purchased item (Subscription or Oniichat Gems) once your purchase is successful. Please note that pending purchases currently can’t be canceled, and are only available for the purchase of Oniichat Gems and Subscriptions.

If you believe that your purchase is pending for too long, we recommend that you first reach out to your financial institution for clarifications. If they are unable to provide you assistance or clarifications, feel free to contact our customer support at support@oniichat.com.</div>
  </div>
</div>
<div className="faq-header">Oniichat Subscription FAQ</div>

<div className="faq-content">
  <div className="faq-question">
    <input id="q6" type="checkbox" className="panel"/>

    <label for="q6" className="panel-title">What are the differences between the subscription plans?</label>
    <div className="panel-content">The Oniichat Subscription program offers three different plans: Basic, Plus, and Ultimate. Each plan has a different cost and provides varying amounts of bonus Gems and benefits. The higher cost plans offer more bonus Gems and benefits compared to the lower cost plans.</div>
  </div>
  <div className="faq-question">
    <input id="q7" type="checkbox" className="panel"/>

    <label for="q7" className="panel-title">How can I pay for my subscription?</label>
    <div className="panel-content">You can purchase subscriptions from the shop using paypal, or credit card.</div>
  </div>
  
  <div className="faq-question">
    <input id="q8" type="checkbox" className="panel"/>

    <label for="q8" className="panel-title">When do I receive my monthly subscription Gems?</label>
    <div className="panel-content">When subscribing, you will receive all of your monthly Gems right away. After that, you will receive monthly Gem deposits every 30 days from the date of your initial subscription, as long as you maintain your subscription.</div>
  </div>
  <div className="faq-question">
    <input id="q9" type="checkbox" className="panel"/>

    <label for="q9" className="panel-title">When do I receive my subscription benefits?</label>
    <div className="panel-content">When subscribing, you will receive all of the benefits of your subscription plan as long as you maintain it. If you cancel your subscription plan, you will lose access to these benefits.</div>
  </div>
  <div className="faq-question">
    <input id="q10" type="checkbox" className="panel"/>

    <label for="q10" className="panel-title">When do I get billed for my subscription?</label>
    <div className="panel-content">You will be automatically charged for your Gem rewards associated with your subscription:
When subscribing, you will receive all of your monthly Gem amount right away. After that, you will receive monthly Gem deposits every 30 days from the date of your initial subscription, as long as you maintain your subscription.</div>
  </div>
  <div className="faq-question">
    <input id="q11" type="checkbox" className="panel"/>

    <label for="q11" className="panel-title">Can I change my subscription plan?</label>
    <div className="panel-content">You can change your current subscription plan at any time, either upgrading to a higher cost plan or downgrading to a lower cost one. The Gems received from any subscription plan are immediately available for use without any delay.</div>
  </div>
  <div className="faq-question">
    <input id="q12" type="checkbox" className="panel"/>

    <label for="q12" className="panel-title">Can I subscribe for more than a single plan?</label>
    <div className="panel-content">No. You can only subscribe to a single plan at any given time.</div>
  </div>
  <div className="faq-question">
    <input id="q13" type="checkbox" className="panel"/>

    <label for="q13" className="panel-title">How do I cancel my subscription plan?</label>
    <div className="panel-content">To cancel your subscription, please follow these steps: 1. Log in to your account and navigate to your profile. 2. Initiate the service termination of your subscription. 3. Follow the on-screen instructions to proceed. 4. When prompted, enter your account-password to confirm your identity. Once you've successfully verified your identity, your subscription will be canceled immediately. Please note that after cancelling your subscription, you will not be charged any further and will not receive the scheduled Gem deposit/billing. Furthermore you will lose all of your benefits immediately.</div>
  </div>
  <div className="faq-question">
    <input id="q14" type="checkbox" className="panel"/>

    <label for="q14" className="panel-title">If I change subscriptions, when will I start receiving the benefits of my new one?</label>
    <div className="panel-content">Any changes you make to your subscription will take no effect on your new subscription, which means that you you will receive the entirety of your subscription benefits, which are lasting while you continue being subscribed. You are also getting the immediate access to your amount of gems, which is included within your subscription. You only loose the benefits by cancelling your subscription plan.</div>
  </div>
  <div className="faq-question">
    <input id="q15" type="checkbox" className="panel"/>

    <label for="q15" className="panel-title">Do I need to stay subscribed for an amount of time?</label>
    <div className="panel-content">Subscriptions are charged on a month-by-month basis. Upon being billed, you receive the immediate rewards associated with your subscription and any ongoing rewards for the rest of the month. There is no minimum number of months that users are required to subscribe to. You can stay for as long as you wish.</div>
  </div>
  <div className="faq-question">
    <input id="q16" type="checkbox" className="panel"/>

    <label for="q16" className="panel-title">My subscription didn’t renew automatically! What should I do?</label>
    <div className="panel-content">If you are currently subscribed to any plan, you will be automatically billed on your scheduled Gem deposit day.
If you are having problems paying for your subscription, we recommend that you first contact your bank/financial institution. If you are struggling to pay due to a problem on the website, please contact our Account Support team at support@oniichat.com.
If you are unable to successfully update your payment method, your subscription will be automatically cancelled at the end of the time period, and you will lose access to all your ongoing benefits. You will need to manually start a new subscription, which will have new scheduled Gem deposit/billing dates.</div>
  </div>
  <div className="faq-question">
    <input id="q17" type="checkbox" className="panel"/>

    <label for="q17" className="panel-title">What should I do if I have a technical problem with my subscription plan?</label>
    <div className="panel-content">Please contact our Account Support at support@oniichat.com if you are having problems with the purchase of your subscription plan or if you are having any other problems with your existing subscription.</div>
  </div>
  <div className="faq-question">
    <input id="q18" type="checkbox" className="panel"/>

    <label for="q18" className="panel-title">I have been banned/suspended. What will happen to my subscription?</label>
    <div className="panel-content">If your account has been banned or suspended while you have an active subscription, your subscription will be automatically cancelled. In other words, your subscription will not be renewed and you will not be charged on your next scheduled billing date. Additionally, you will lose access to all other ongoing benefits.</div>
  </div>
  <div className="faq-question">
    <input id="q19" type="checkbox" className="panel"/>

    <label for="q19" className="panel-title">Can I transfer my subscription Gems or plan to another account?</label>
    <div className="panel-content">No. Your current subscription and your subscription Gems cannot be transferred to another user's account.</div>
  </div>

</div>

                    </div>
  );
};

export default FAQ;

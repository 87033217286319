import { useState, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const CountdownComponent = ({ onEnd }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = (deadline) => {
    const now = new Date().getTime();
    const distance = deadline - now;

    return {
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.round((distance % (1000 * 60)) / 1000),
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      const deadlineDoc = doc(db, 'deadlines', 'waifuContest');
      const deadlineSnapshot = await getDoc(deadlineDoc);
      const deadlineTimestamp = deadlineSnapshot.data().deadline.toDate();

      setTimeLeft(calculateTimeLeft(deadlineTimestamp));

      const timer = setInterval(() => {
        const newTimeLeft = calculateTimeLeft(deadlineTimestamp);
        if (newTimeLeft.days <= 0 && newTimeLeft.hours <= 0 && newTimeLeft.minutes <= 0 && newTimeLeft.seconds <= 0) {
          clearInterval(timer); // stop timer
          onEnd(); // countdown ended, inform the parent
        } else {
          setTimeLeft(newTimeLeft);
        }
      }, 1000);
  

      return () => clearInterval(timer); // cleanup timer
    };
    
    fetchData();
  }, []);

  if(timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0){
    return (
      <div className="countdown">
        <div>Countdown is over</div>
      </div>
    );
  } else {
  return (
    <div className="countdown">
      <div>
        <span>{timeLeft.days}</span>
        <label>Days</label>
      </div>
      <div>
        <span>{timeLeft.hours}</span>
        <label>Hours</label>
      </div>
      <div>
        <span>{timeLeft.minutes}</span>
        <label>Minutes</label>
      </div>
      <div>
        <span>{timeLeft.seconds}</span>
        <label>Seconds</label>
      </div>
    </div>
  );
  }
};

export default CountdownComponent;

import React, {useEffect} from 'react';
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
import { initializeAnalytics } from '../firebase'; // Make sure this path is correct
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
const EU_TIMEZONES = [
    'Europe/Vienna',
    'Europe/Brussels',
    'Europe/Sofia',
    'Europe/Zagreb',
    'Asia/Famagusta',
    'Asia/Nicosia',
    'Europe/Prague',
    'Europe/Copenhagen',
    'Europe/Tallinn',
    'Europe/Helsinki',
    'Europe/Paris',
    'Europe/Berlin',
    'Europe/Busingen',
    'Europe/Athens',
    'Europe/Budapest',
    'Europe/Dublin',
    'Europe/Rome',
    'Europe/Riga',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Malta',
    'Europe/Amsterdam',
    'Europe/Warsaw',
    'Atlantic/Azores',
    'Atlantic/Madeira',
    'Europe/Lisbon',
    'Europe/Bucharest',
    'Europe/Bratislava',
    'Europe/Ljubljana',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Madrid',
    'Europe/Stockholm'
  ];
  // Extend dayjs with timezone functionality
dayjs.extend(utc);
dayjs.extend(timezone);

// Check if user's timezone is in EU
const isConsentRequired = () => {
  return EU_TIMEZONES.includes(dayjs.tz.guess());
};
const CookieConsentComponent = () => {
  const handleAccept = () => {
    initializeAnalytics();
  };

  React.useEffect(() => {
    const consent = getCookieConsentValue("userConsent");
    if (consent === "true") {
      console.log("initialize analytics")
      initializeAnalytics();
    }
  }, []);
  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="userConsent"
      style={{ background: "linear-gradient(180deg, #5c75cc, #203378)" }}
      buttonStyle={{
        zIndex: 1,
        cursor: 'pointer',
        border: 'none',
        borderRadius: '24px',
        alignSelf: 'center',
        fontFamily: 'PPPangramSansRounded-Bold, moderat',
        lineHeight: '20px',
        fontSize: '15px',
        textAlign: 'center',
        padding: '15px 20px',
        background: 'linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%), rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 0px 15px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8)',
        color: '#5e5e5e',
        opacity: 1,
        transition: 'box-shadow 0.3s ease'
      }}
      declineButtonStyle={{
    zIndex: 1,
    cursor: 'pointer',
    border: 'none',
    borderRadius: '24px',
    alignSelf: 'center',
    fontFamily: 'PPPangramSansRounded-Bold, moderat',
    lineHeight: '20px',
    fontSize: '15px',
    textAlign: 'center',
    padding: '15px 20px',
    background: 'linear-gradient(180deg, rgba(255, 224, 224, 0) 0%, #d99898 100%), rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 0px 15px rgba(255, 204, 204, 0.9), inset 0px 0px 5px rgba(255, 204, 204, 0.8)',
    color: '#5e5e5e',
    opacity: 1,
    transition: 'box-shadow 0.3s ease'
  }}
      onDecline={() => {
        console.log('Cookies declined');
      }}
      enableDeclineButton={isConsentRequired()} 
      expires={150}
      onAccept={handleAccept}
    >
  We use cookies for various purposes including analytics. By continuing to use the service, you agree to our use of cookies as described in the 
      <a href="https://oniichat.com/privacy-policy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>. 
      If you wish to change your cookie preferences in the future, please contact us at 
      <a href="mailto:support@oniichat.com"> support@oniichat.com</a>.
    </CookieConsent>
  );
};
{/* isConsentRequired()*/}
export default CookieConsentComponent;

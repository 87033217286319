import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import MainNav from '../../components/Common/MainNav';
import WinnerCard from "../../components/Voting/Winnercard";
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import bannerImage from "../../img/shopPromo/banner.png"; // Import the banner image
import laurelWreathGif from "../../img/shopPromo/Laurelwreath.gif"; // Import the laurel wreath GIF

const HallOfFame = () => {
  const [winners, setWinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        const winnersQuery = query(collection(db, 'winners'), orderBy('winDate', 'desc'));
        const winnersSnapshot = await getDocs(winnersQuery);
        const winnersList = winnersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log("Fetched winners:", winnersList);
        setWinners(winnersList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching winners:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchWinners();
  }, []);

  const handleBackToVoting = () => {
    navigate('/community');
  };

  if (loading) {
    return (
      <div style={{ justifyContent: "center", display: "flex", marginTop: "3rem" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="mainWrapper">
      <MainNav />
      <div className="hof-hallOfFameWrapper">
        {/* Left banner image with laurel wreath */}
        <div className="hof-sideBanner hof-left">
          <img src={bannerImage} alt="Left Banner" />
          <img src={laurelWreathGif} alt="Laurel Wreath" className="hof-laurelWreath" />
        </div>

        {/* Main content */}
        <div className="hof-contentWrapper">
          <h1 className="headline">Hall of Fame</h1>
          <h2 className="subheadline">Celebrating our past winners!</h2>
          <button className="hof-backToVotingBtn" onClick={handleBackToVoting}>
  Back to Voting
</button>

          {error && <p style={{ color: 'red' }}>{error}</p>}
          {winners.length === 0 ? (
            <div style={{ justifyContent: "center", display: "flex", marginTop: "3rem", color: "white" }}>
              <p>No winners found.</p>
            </div>
          ) : (
            winners.map((winner) => (
              <WinnerCard key={winner.id} winner={winner} />
            ))
          )}
        </div>

        {/* Right banner image with laurel wreath */}
        <div className="hof-sideBanner hof-right">
          <img src={bannerImage} alt="Right Banner" />
          <img src={laurelWreathGif} alt="Laurel Wreath" className="hof-laurelWreath" />
        </div>
      </div>
    </div>
  );
};

export default HallOfFame;
import React, { useContext, useEffect, useRef, useState} from "react";
import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import gem from "../../img/gem.png"
import { Tooltip } from 'antd';
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  FieldValue,
  addDoc                              
} from "firebase/firestore";
import { db} from "../../firebase";
import { Button } from 'antd';
import { arrayRemove, arrayUnion, } from 'firebase/firestore';
import { handleAIResponse, handleAISend } from "../../AIConnector";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpand, faCompress, faRedo } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import AudioPlayer from "../AudioPlayer";
import "../../styles/message.scss"
import {message as antdMessage} from 'antd';
const Message = ({ message, chatID, character, isLastMessage}) => {
  const { currentUser, userData} = useContext(AuthContext);
  const {setShowSignUp, selectedStory,showXpMessageAnimWithId, setFullScreenImageSrc, setShowModal, setSelectedImage, setShowVerification, setShowMessageModal, setSelectedMessage, characterData} = useContext(ChatContext);
  const { charId } = useParams(); 
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState('');
  const [saving, setSaving] = useState(false)
  const {setDisplayType,  requesting, setRequesting, setShowErr, setShowWarning, lastPrompt, setLastPrompt, chatData} = useContext(ChatContext);
  // Use tempSessionId if currentUser and userData are not available
  const sessionId = currentUser.uid;
  const handleEditClick = () => {
    setEditText(message.text);
    setIsEditing(true);
  };
  const handleShowSignUp = () => {
    setDisplayType("images")
    setShowSignUp(true)
  }
  useEffect(() => {
    if(isEditing) {
      const textarea = document.querySelector(".editInput");
      if (textarea) {
        // Create a new 'resize' event
        var resizeEvent = new Event('resize');
    
        // Function to resize the textarea
        const resizeTextarea = () => {
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight}px`;
        };
    
        // Resize the textarea initially
        resizeTextarea();
    
        // Resize the textarea whenever its content changes
        const observer = new MutationObserver(resizeTextarea);
        observer.observe(textarea, { childList: true });
    
        // Cleanup function to stop observing changes when component unmounts
        return () => observer.disconnect();
      }
    }
  }, [editText, isEditing]);
  const handleSaveClick = async () => {
    if(saving)
    {      
      antdMessage.warning('Your request is already being processed. Please wait.')
      return
    }
    if(requesting){
      antdMessage.warning('Your request is already being processed. Please wait.')
      return;
    }
    try {
    if (userData.gems > 4) {

    }
    else {
      setShowWarning(true)
      return;
    }
    setSaving(true)
    const chatRef = doc(db, 'chats', chatID);
  
    // Get the existing document
    const chatDoc = await getDoc(chatRef);
    if (chatDoc.exists()) {
      let messages = chatDoc.data().messages;
      // Find the message with the provided id
      const msgIndex = messages.findIndex(m => m.id === message.id);
      if (msgIndex !== -1) {
        // Modify the message
        messages[msgIndex].text = editText;
        messages[msgIndex].locked = false;
        // Remove messages after the edited message
        messages = messages.slice(0, msgIndex + 1);
        // Update the entire messages array in Firestore
        await updateDoc(chatRef, {
          messages: messages
        }, { merge: true });
        await updateDoc(doc(db, "users", currentUser.uid), {
          gems: userData.gems - 5
        }, { merge: true });
        setIsEditing(false);
        console.log("char data", characterData)
        console.log("story data", selectedStory)
        await handleAISend({ chatId: chatID, character: characterData, currentUser: currentUser, userData: userData, setRequesting: setRequesting,setLastPrompt: setLastPrompt, setShowErr: setShowErr, story: selectedStory?.storyPrompt
        })
        
      }
      setSaving(false)
    }
  } catch (error) {
    console.error("Error saving message:", error);
    setSaving(false)
    setShowErr("An error occurred while saving the message. Please try again.");
  }
  };
  const handleRegenerateClick = async () => {
    try {
      if (userData.gems <= 0) {
        setShowWarning(true);
        return;
      }
  
      const chatRef = doc(db, 'chats', chatID);
  
      // Get the existing document
      const chatDoc = await getDoc(chatRef);
      if (chatDoc.exists()) {
        let messages = chatDoc.data().messages;
        // Check if there is only one message in the array
        if (messages.length <= 1) {
          // Set messages to an empty array
          messages = [];
        } else {
          // Find the message with the provided id and remove it
          const msgIndex = messages.findIndex(m => m.id === message.id);
          if (msgIndex !== -1) {
            messages.splice(msgIndex, 1); // Remove the message
          }
        }
  
        // Update the entire messages array in Firestore
        await updateDoc(chatRef, {
          messages: messages
        }, { merge: true });
  
        // Update user's gems
        await updateDoc(doc(db, "users", currentUser.uid), {
          gems: userData.gems - 1
        }, { merge: true });
  
        setIsEditing(false);
        console.log("char data", characterData);
        console.log("story data", selectedStory);
        await handleAISend({ 
          chatId: chatID, 
          character: characterData, 
          currentUser: currentUser, 
          userData: userData, 
          setLastPrompt: setLastPrompt,
          setRequesting: setRequesting, 
          setShowErr: setShowErr, 
          story: selectedStory?.storyPrompt
        });
      }
    } catch (error) {
      console.error("Error in message deletion:", error);
      setShowErr("An error occurred while deleting the message. Please try again.");
    }
  };
  
  const ref = useRef();

  const showImage = (imageMessage, requireSubscription = false) => {
    try {
      if (requireSubscription && (userData?.subscription === "None" || userData?.subscription === undefined)) {
        setShowMessageModal(true)
        return;
      }
      setSelectedImage(imageMessage);
      setShowModal(true);
    } catch (error) {
      console.error("Error showing image:", error);
      setShowErr("An error occurred while showing the image. Please try again.");
    }
  };

  const showBuyImageModal = (imageMessage) => showImage(imageMessage, true);
  const showBuyImage = (imageMessage) => showImage(imageMessage);
  const showBuyMessageModal = async(message) => {
    setSelectedMessage(message)
    setShowMessageModal(true)
  }
  const prevMessageId = useRef(null);

  useEffect(() => {
    // Check if the message ID has changed
    if (prevMessageId.current !== message.id) {
      // Scroll into view
      ref.current?.scrollIntoView({ behavior: "smooth" });
      
      // Update the previous message ID
      prevMessageId.current = message.id;
    }
  }, [message.id]);  // Notice we're only watching message.id now, not the entire message object
  const allowVideoControls = message.nsfw || message.first;
   // Ref for the video element
   const videoRef = useRef(null);

   // Enforce mute on video
   useEffect(() => {
    if(!allowVideoControls)
    {
     const enforceMute = () => {
       if (videoRef.current && !videoRef.current.muted) {
         videoRef.current.muted = true;
       }
     };
 
     if (videoRef.current) {
       enforceMute();
       videoRef.current.addEventListener('volumechange', enforceMute);
     }
 
     // Cleanup function
     return () => {
       if (videoRef.current) {
         videoRef.current.removeEventListener('volumechange', enforceMute);
       }
     };
    }
   }, [message.id]); // Re-run this effect if message.id changes
   const [showXP, setShowXP] = useState(false);

   const triggerXPAnimation = () => {
     setShowXP(true);
     console.log("triggered")
     setTimeout(() => {
       setShowXP(false);
     }, 2000); // 2 seconds to match the CSS animation duration
   };
   useEffect(() => {
    if(showXpMessageAnimWithId === message.id)
    {
      console.log("showXpMessageAnimWithId", showXpMessageAnimWithId, message.id)
    triggerXPAnimation()
    }
   },[showXpMessageAnimWithId])
   const handleThumbsUpClick = async (text) => {
    try {
      // Document structure for good feedback
      const feedbackDoc = {
        prompt: lastPrompt, // the AI prompt
        response: text, // the AI response
        character_name: character,
        messages: chatData.messages,
        timestamp: new Date() // current timestamp
      };
  
      // Add document to 'goodFeedback' collection
      await addDoc(collection(db, "goodFeedback"), feedbackDoc);
      console.log("Feedback saved successfully: Thumbs Up");
      setLastPrompt(null)
    } catch (error) {
      console.error("Error saving good feedback:", error);
    }
  };
  
  const handleThumbsDownClick = async (text) => {
    try {
      // Document structure for bad feedback
      const feedbackDoc = {
        prompt: lastPrompt, // the AI prompt
        response: text, // the AI response
        character_name: character,
        messages: chatData.messages,
        timestamp: new Date() // current timestamp
      };
  
      // Add document to 'badFeedback' collection
      await addDoc(collection(db, "badFeedback"), feedbackDoc);
      console.log("Feedback saved successfully: Thumbs Down");
      setLastPrompt(null)
    } catch (error) {
      console.error("Error saving bad feedback:", error);
    }
  };
  function modifyText(originalText) {
    let visibleCharCount = 0;
    let modifiedText = '';

    for (let i = 0; i < originalText.length; i++) {
        if (visibleCharCount >= 20) {
            // Replace the character with 'x' if it's beyond the 10th visible character
            modifiedText += (originalText[i].match(/\s/) ? originalText[i] : 'x');
        } else if (originalText[i].match(/[^\s]/)) {
            // Increment visibleCharCount only for non-whitespace characters
            visibleCharCount++;
            modifiedText += originalText[i];
        } else {
            // Keep the whitespace characters as is
            modifiedText += originalText[i];
        }

        // Break the loop if the modified text reaches 500 characters
        if (modifiedText.length >= 180) {
            break;
        }
    }

    return modifiedText;
}
function splitText(originalText) {
  let totalVisibleChars = Array.from(originalText).filter(char => char.match(/[^\s]/)).length;
  let visibleCharsLimit = Math.floor(totalVisibleChars * 0.12); // 20% of visible characters
  let visibleCharCount = 0;
  let firstPart = '';
  let blurredPart = '';

  for (let i = 0; i < originalText.length; i++) {
      if (visibleCharCount < visibleCharsLimit && originalText[i].match(/[^\s]/)) {
          // Add visible character to the first part
          firstPart += originalText[i];
          visibleCharCount++;
      } else if (originalText[i].match(/\s/)) {
          // Preserve whitespace characters in both parts
          firstPart += originalText[i];
          blurredPart += originalText[i];
      } else {
          // Replace non-whitespace characters with 'x' in the blurred part
          blurredPart += 'x';
      }
  }

  return { firstPart, blurredPart };
}

let splitMessage = splitText(message.text);
function handleMessageClick() {
  if(currentUser.isAnonymous === false)
  {
  showBuyMessageModal(message)
  }
  else{
    setDisplayType("nsfw")
    setShowSignUp(true)
  }
}
  return (
    <div ref={ref} className={`message ${sessionId && message.senderId === sessionId && "owner"}`}>
      <div className="messageContent">
        {!isEditing ? (
          <>
            {!message.img && !message.voice && !message.video ? (
              <>
                {(message.locked === false || message.violation|| message.senderId === sessionId || (userData.subscription !== "None") || currentUser.isAnonymous === true) ? (
                  <>
                    <p>{message.text}</p>
                    {(message.senderId === sessionId && currentUser && currentUser.isAnonymous === false)&&        <Tooltip title="Edit message (costs 5 gems)"><Button type="primary" shape="round" onClick={handleEditClick}>
  Edit
</Button></Tooltip>}
                  </>
                ) : (
                  <p>
  <div>
    <span id="visiblePart">{splitMessage.firstPart}</span>
    <span id="blurredPart" className="blurred">{splitMessage.blurredPart}</span>
  </div>
  <span onClick={handleMessageClick} className="displayText">Click to show message</span>
</p>

                )}
              </>
            ) : (
              <div></div>
            )}
            {message.voice && (
              <div className="voice">
<AudioPlayer audioUrl={message.voice} isLocked={message.locked && userData.subscription === "None"}/>
              </div>
            )}
            {message.video && (
              <div className="video">
    <video 
        ref={videoRef}
        id="myVideo"
        controls={true} 
        controlsList="nodownload noplaybackrate"
        muted={!allowVideoControls}
    >
        <source src={message.video} type="video/mp4" />
        Your browser does not support the video tag.
    </video>
</div>
              )}
            {((message?.unlocked) || (userData?.subscription && userData.subscription !== "None")) ? (
  <div className="image">
    {message.img && (<><img src={message.img} alt="" />
     <FontAwesomeIcon 
      icon={faExpand} 
      className="image-resize-icon"
      onClick={(e) => {
        e.stopPropagation(); // Prevent triggering any other click events
        setFullScreenImageSrc(message.img);
      }} 
    /></>)}
  </div>
            ) : (
              <div>
              {currentUser.isAnonymous === false ? (
  message.paid ? (
    <div className="image">
      {message.img && (
        <div className="unlockedImgWrapper" onClick={() => showBuyImageModal(message)}>
          <div className="text">
            <h5>NSFW</h5>
            <button className="button">Unlock</button>
          </div>
          <img className="unlockedImg" src={message.img} alt="" />
        </div>
      )}
    </div>
  ) : (
    <div className="image">
      {message.img && (
        <div className="unlockedImgWrapper" onClick={() => showBuyImage(message)}>
          <div className="text">
            <img src={gem} alt="" /> 5
          </div>
          <img className="unlockedImg" src={message.img} alt="" />
        </div>
      )}
    </div>
  )
) : (
  <div className="image">
    {message.img && (
      <div className="unlockedImgWrapper" onClick={handleShowSignUp}>
      <div className="text">
            <button className="button">Unlock for free</button>
          </div>
        <img className="unlockedImg" src={message.img} alt="" />
      </div>
    )}
  </div>
)}
{showXP && message.senderId !== sessionId && <div className="xpPopup">+10 XP</div>}
              </div>
            )}
          </>
        ) : (
          <>
<textarea
  className="editInput"
  value={editText}
  onChange={(e) => setEditText(e.target.value)}
/>
<Button type="primary" onClick={handleSaveClick}>
  Save
</Button>
<Button type="default" onClick={() => setIsEditing(false)}>
  Cancel
</Button>
          </>
        )}
        {sessionId && message.senderId !== sessionId && isLastMessage && !requesting && message.text && lastPrompt && (!message.locked || userData.subscription !== "None") && (
          <div className="message-icons">
      <div className="thumbs-container">
        <span className="icon-button thumbs-up" onClick={() => handleThumbsUpClick(message.text)}>👍</span>
        <span className="icon-button thumbs-down" onClick={() => handleThumbsDownClick(message.text)}>👎</span>
      </div>
      <span className="icon-button regenerate-button" onClick={handleRegenerateClick}>
        <FontAwesomeIcon icon={faRedo} />
      </span>
    </div>


  )}
      </div>
    </div>
  );
};

export default Message;

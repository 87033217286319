// TwitterUtil.js

const TWITTER_PIXEL_ID = 'omnbv'; // Replace with your actual Twitter Pixel ID

// Twitter tracking initialization
const initTwitter = () => {
  const head = document.querySelector('head');
  const addTwitterScript = () => {
    const twitterScript = document.createElement('script');
    twitterScript.type = 'text/javascript';
    twitterScript.async = true;
    twitterScript.src = 'https://static.ads-twitter.com/uwt.js';
    head.appendChild(twitterScript);
  };

  const initializeTwq = (windowObj, documentObj, elementType) => {
    if (!windowObj.twq) {
      const twitterEventTracker = (windowObj.twq = (...args) => {
        twitterEventTracker.exe
          ? twitterEventTracker.exe.apply(twitterEventTracker, args)
          : twitterEventTracker.queue.push(args);
      });
      twitterEventTracker.version = '1.1';
      twitterEventTracker.queue = [];
      const twitterScriptElement = documentObj.createElement(elementType);
      twitterScriptElement.async = true;
      twitterScriptElement.src = 'https://static.ads-twitter.com/uwt.js';
      const a = documentObj.getElementsByTagName(elementType)[0];
      a.parentNode.insertBefore(twitterScriptElement, a);
    }
  };

  addTwitterScript();
  initializeTwq(window, document, 'script');
  window.twq('config', TWITTER_PIXEL_ID);
};

// Initialize Twitter tracking
initTwitter();

// Array of Twitter events
const twitterEvents = [
  { name: 'signup', id: 'tw-omnbv-omypr' },
  { name: 'purchaseSubscription', id: 'tw-omnbv-omypt' },
  { name: 'openChat', id: 'tw-omnbv-omypv' },
  { name: 'purchaseGems', id: 'tw-omnbv-omypu' },
  { name: 'checkoutStart', id: 'tw-omnbv-omypw' },
];

// Helper function to send Twitter events
const sendTwitterEvent = (eventId, params = {}) => {
  if (window.twq) {
    window.twq('event', eventId, params);
    console.log(`Twitter event sent: ${eventId}`, params);
  } else {
    console.error('Twitter Error: twq function not found');
  }
};

// Main tracking function
const trackTwitterConversion = (eventName, value) => {
    const event = twitterEvents.find(e => e.name === eventName);
    if (event) {
      const params = value !== undefined ? { value, currency: 'USD' } : {};
      sendTwitterEvent(event.id, params);
      console.error(`Successfully tracked:  ${eventName}, ${params}`);
    } else {
      console.error(`Twitter Error: Unknown event name: ${eventName}`);
    }
  };

// Attach the tracking function to the window object
window.trackTwitterConversion = trackTwitterConversion;
export class Tracker {
  
  /**
   * @returns {string}
   */
  static get trackerId() {
    throw new Error("Not implemented");
  };
  
  /**
   * @returns {string[]}
   */
  static get trackingKeys() {
    throw new Error("Not implemented");
  }

  get trackerId() {
    return this.constructor.trackerId;
  }
 
  get identifierKey() {
    return this.trackingKeys[0];
  }

  get trackingKeys() {
    return this.constructor.trackingKeys;
  }

  /**
   * Extracts the parameters from the URL and saves them in local storage.
   *
   * @param {URLSearchParams} params
   * @returns {URLSearchParams | undefined} Return filtered params if the parameters were extracted, false otherwise.
   */
  extractTrackingParams(params) {
    if (!params.get(this.identifierKey)) {
      return undefined;
    }
    const keys = this.trackingKeys;
    const trackingParams = {};
    for (const key of keys) {
      const value = params.get(key);
      if (value) {
        trackingParams[key] = value;
        params.delete(key);
      }
    }
    this.saveTrackingParams(trackingParams);
    return params;
  }

  saveTrackingParams(params) {
    if (!params) {
      localStorage.removeItem(this.trackerId);
      return;
    }
    localStorage.setItem(this.trackerId, JSON.stringify(params));
  }

  /**
   * Gets the tracking parameters from local storage.
   *
   * @returns {Record<string, string> | undefined} The tracking parameters.
   */
  getTrackingParams() {
    const trackingParams = localStorage.getItem(this.trackerId);
    return trackingParams ? JSON.parse(trackingParams) : undefined;
  }

  getNamedTrackingParams() {
    const trackingParams = this.getTrackingParams();
    if (!trackingParams) {
      return {};
    }
    return {
      [this.trackerId]: trackingParams
    }
  }
}

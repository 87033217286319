import React, { useState, useEffect, useContext, useCallback} from 'react';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, query, where, getDoc} from 'firebase/firestore';
import { db, functions, httpsCallable, auth} from "../firebase";
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faSave, faTimes, faSpinner, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from "../context/AuthContext";
import MainNav from '../components/Common/MainNav';

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Styled Components
const Page = styled.div`
  background: linear-gradient(135deg, #8a2be2, #4169e1);
  min-height: 100vh;
  padding: 40px 20px;
  font-family: 'Arial, sans-serif';
  color: white;
  animation: ${fadeIn} 0.5s ease-in-out;
`;
const VoteIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;


const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  overflow-y: auto;
  height: calc(100vh - 80px); // Adjust height to allow scrolling within the content area
`;

const Container = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff;
`;

const Form = styled.form`
  display: flex;
  margin-bottom: 30px;
  border-radius: 16px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
`;

const Input = styled.input`
  flex: 1;
  padding: 15px;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  &::placeholder {
    color: #ddd;
  }
`;

const SubmitButton = styled.button`
  padding: 15px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0056b3;
  }
`;

const InfoMessage = styled.p`
  color: #ffdd57;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FeatureCard = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const FeatureTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff;
`;

const VoteCount = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
  color: #ffffff;
`;

const VoteButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const VoteButton = styled.button`
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  &:hover {
    transform: scale(1.05);
  }
`;

const UpvoteButton = styled(VoteButton)`
  background-color: #28a745;
  &:hover {
    background-color: #218838;
  }
`;

const DownvoteButton = styled(VoteButton)`
  background-color: #dc3545;
  &:hover {
    background-color: #c82333;
  }
`;

const EditButtons = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.2);
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 48px;
`;
const UserMessage = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
`;
function FeatureUpvotingPage() {
  const [features, setFeatures] = useState([]);
  const [newFeature, setNewFeature] = useState('');
  const [editingFeature, setEditingFeature] = useState(null);
  const [editText, setEditText] = useState('');
  const [userFeature, setUserFeature] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [userVotes, setUserVotes] = useState({ votedFeatures: {} });
  const [remainingVotes, setRemainingVotes] = useState(null);
  const [loading, setLoading] = useState(true);
  const voteFeatureFunction = httpsCallable(functions, 'voteFeature');

  const addFeature = async (e) => {
    e.preventDefault();
    if (!newFeature.trim()) {
      alert("Please enter a feature before submitting.");
      return;
    }
    if (userFeature) {
      alert("You can only submit one feature request.");
      return;
    }
    setLoading(true);
    try {
      const docRef = await addDoc(collection(db, 'features'), { title: newFeature.trim(), votes: 0, userId: currentUserId });
      setNewFeature('');
      const newFeatureObj = { id: docRef.id, title: newFeature.trim(), votes: 0, userId: currentUserId };
      setUserFeature(newFeatureObj);
      setFeatures(prevFeatures => [...prevFeatures, newFeatureObj].sort((a, b) => b.votes - a.votes));
    } finally {
      setLoading(false);
    }
  };
  const fetchUserFeature = useCallback(async (userId) => {
    try {
      const userFeatureQuery = query(collection(db, 'features'), where('userId', '==', userId));
      const querySnapshot = await getDocs(userFeatureQuery);
      if (!querySnapshot.empty) {
        const userFeatureDoc = querySnapshot.docs[0];
        setUserFeature({ id: userFeatureDoc.id, ...userFeatureDoc.data() });
      } else {
        setUserFeature(null);
      }
    } catch (error) {
      console.error("Error fetching user feature:", error);
    }
  }, []);

  const fetchUserVotes = useCallback(async (userId) => {
    try {
      const userVotesDoc = await getDoc(doc(db, 'userVotes', userId));
      if (userVotesDoc.exists()) {
        const data = userVotesDoc.data();
        setUserVotes({
          votedFeatures: data.votedFeatures || {}
        });
        setRemainingVotes(3 - Object.keys(data.votedFeatures || {}).length);
      } else {
        setUserVotes({ votedFeatures: {} });
        setRemainingVotes(3);
      }
    } catch (error) {
      console.error("Error fetching user votes:", error);
    }
  }, []);

  const fetchFeatures = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'features'));
      const fetchedFeatures = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Sort features by votes in descending order
      const sortedFeatures = fetchedFeatures.sort((a, b) => b.votes - a.votes);
      setFeatures(sortedFeatures);
    } catch (error) {
      console.error("Error fetching features:", error);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setLoading(true);
      if (user) {
        setCurrentUserId(user.uid);
        await Promise.all([
          fetchUserFeature(user.uid),
          fetchUserVotes(user.uid),
          fetchFeatures()
        ]);
      } else {
        setCurrentUserId(null);
        setUserFeature(null);
        setUserVotes({ votedFeatures: {} });
        await fetchFeatures();
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [fetchUserFeature, fetchUserVotes, fetchFeatures]);

  const voteFeature = async (id, voteType) => {
    setLoading(true);
    try {
      const result = await voteFeatureFunction({ featureId: id, voteType });
      if (result.data && result.data.success) {
        setFeatures(prevFeatures => {
          const updatedFeatures = prevFeatures.map(feature => 
            feature.id === id ? { ...feature, votes: result.data.newVoteCount } : feature
          );
          // Sort the updated features
          return updatedFeatures.sort((a, b) => b.votes - a.votes);
        });
        setUserVotes(result.data.userVotes);
        setRemainingVotes(result.data.remainingVotes);
      } else {
        throw new Error('Voting failed');
      }
    } catch (error) {
      console.error('Error voting:', error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };


  const startEditing = (feature) => {
    setEditingFeature(feature.id);
    setEditText(feature.title);
  };

  const saveEdit = async (id) => {
    setLoading(true);
    try {
      const featureRef = doc(db, 'features', id);
      await updateDoc(featureRef, { 
        title: editText,
        userId: currentUserId 
      });
      setEditingFeature(null);
      setFeatures(prevFeatures => 
        prevFeatures.map(feature => 
          feature.id === id ? { ...feature, title: editText } : feature
        ).sort((a, b) => b.votes - a.votes)
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteFeature = async (id) => {
    setLoading(true);
    try {
      await deleteDoc(doc(db, 'features', id));
      setFeatures(prevFeatures => 
        prevFeatures.filter(feature => feature.id !== id)
      );
      setUserFeature(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>    <MainNav/>
    <Page>
      {loading && (
        <LoadingOverlay>
          <SpinnerIcon icon={faSpinner} spin />
        </LoadingOverlay>
      )}
      <Content>
        <Container>
          <Header>Feature Requests</Header>
          {currentUserId ? (
            userFeature ? (
              <UserMessage>
                You have already submitted a feature request. You can edit or delete it below.
              </UserMessage>
            ) : (
              <Form onSubmit={addFeature}>
                <Input
                  type="text"
                  value={newFeature}
                  onChange={(e) => setNewFeature(e.target.value)}
                  placeholder="Suggest a new feature"
                  disabled={loading}
                />
                <SubmitButton type="submit" disabled={loading}>Submit</SubmitButton>
              </Form>
            )
          ) : (
            <UserMessage>Please log in to submit a feature request or vote.</UserMessage>
          )}
          <FeatureList>
            {features.map(feature => (
              <FeatureCard key={feature.id}>
                {editingFeature === feature.id ? (
                  <>
                    <Input
                      type="text"
                      value={editText}
                      onChange={(e) => setEditText(e.target.value)}
                      disabled={loading}
                    />
                    <EditButtons>
                      <IconButton onClick={() => saveEdit(feature.id)} disabled={loading}>
                        <FontAwesomeIcon icon={faSave} />
                      </IconButton>
                      <IconButton onClick={() => setEditingFeature(null)} disabled={loading}>
                        <FontAwesomeIcon icon={faTimes} />
                      </IconButton>
                    </EditButtons>
                  </>
                ) : (
                  <>
                    <FeatureTitle>{feature.title}</FeatureTitle>
                    <VoteCount>{feature.votes} votes</VoteCount>
                    <VoteButtons>
  <UpvoteButton 
    onClick={() => voteFeature(feature.id, 'upvote')}
    disabled={loading || !currentUserId || (remainingVotes === 0 && !userVotes.votedFeatures[feature.id])}
  >
    <VoteIcon icon={faThumbsUp} color={userVotes.votedFeatures[feature.id] === 'upvote' ? '#4CAF50' : 'inherit'} />
    {userVotes.votedFeatures[feature.id] === 'upvote' ? 'Remove Upvote' : 'Upvote'}
  </UpvoteButton>
  <DownvoteButton 
    onClick={() => voteFeature(feature.id, 'downvote')}
    disabled={loading || !currentUserId || (remainingVotes === 0 && !userVotes.votedFeatures[feature.id])}
  >
    <VoteIcon icon={faThumbsDown} color={userVotes.votedFeatures[feature.id] === 'downvote' ? '#F44336' : 'inherit'} />
    {userVotes.votedFeatures[feature.id] === 'downvote' ? 'Remove Downvote' : 'Downvote'}
  </DownvoteButton>
</VoteButtons>
                    {feature.userId === currentUserId && (
                      <EditButtons>
                        <IconButton onClick={() => startEditing(feature)} disabled={loading}>
                          <FontAwesomeIcon icon={faEdit} />
                        </IconButton>
                        <IconButton onClick={() => deleteFeature(feature.id)} disabled={loading}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </IconButton>
                      </EditButtons>
                    )}
                  </>
                )}
              </FeatureCard>
            ))}
          </FeatureList>
          {currentUserId && (
  <InfoMessage>You have {remainingVotes} votes remaining.</InfoMessage>
)}
        </Container>
      </Content>
    </Page>
    </>
  );
}

export default FeatureUpvotingPage;
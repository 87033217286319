import React, { useState, useEffect, useRef, useContext } from 'react';
import '../styles/AudioPlayer.scss';
import { Spin } from 'antd'; // Import Spin component from antd
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';
const AudioPlayer = ({ audioUrl, isLocked=false}) => {
  const {currentUser, userData} = useContext(AuthContext)
  const {setDisplayType, setShowSignUp, setShowMessageModal} = useContext(ChatContext)
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const audioRef = useRef(null);
  const lockPoint = 1; // Playback limit in percentage
  const [progress, setProgress] = useState(0);
  const uniqueId = useRef(Math.random().toString(36).substr(2, 9));
  // Function to update progress

  const startPlaying = () => {
    audioRef.current.play();
    setIsPlaying(true);
  };

  const pausePlaying = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };
  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio();
    }
    const audio = audioRef.current;
    audio.src = audioUrl;
    audio.preload = 'auto';

    const handleCanPlayThrough = () => setIsLoading(false);
    const handleTimeUpdate = () => {
      const duration = audioRef.current.duration;
      const currentTime = audioRef.current.currentTime;
      const newProgress = (currentTime / duration) * 100;
      setProgress(newProgress);
    
      if (isLocked && newProgress >= lockPoint) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset the audio to the beginning
        setIsPlaying(false);
        if(currentUser.isAnonymous)
        {
          setShowSignUp(true)
          setDisplayType("voice")
        }
        else{
          setShowMessageModal(true)
        }
        setProgress(0); // Reset the progress bar
      }
    };
    const handleLoadError = () => {
      console.error('Error loading audio');
      setIsLoading(false); // Stop loading spinner on error
    };

    const loadingTimeout = setTimeout(() => {
      setIsLoading(false); // Stop loading spinner after a timeout
    }, 10000); // 10 seconds timeout
    const handleAudioEnd = () => {
      setIsPlaying(false);
      setProgress(0);
    };

    audio.addEventListener('canplaythrough', handleCanPlayThrough);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleAudioEnd);
    audio.addEventListener('error', handleLoadError);
    audio.addEventListener('loadedmetadata', handleCanPlayThrough);
    audio.addEventListener('loadeddata', handleCanPlayThrough);
    return () => {
      clearTimeout(loadingTimeout);
      audio.removeEventListener('canplaythrough', handleCanPlayThrough);
      audio.removeEventListener('loadedmetadata', handleCanPlayThrough);
      audio.removeEventListener('loadeddata', handleCanPlayThrough);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleAudioEnd);
      audio.pause();
    };
  }, [audioUrl]);



  const maskId = `svg-mask-${uniqueId.current}`;
  const gradientId = `svg-gradient-${uniqueId.current}`;
  return (
    <>
            <div className="voice-memo-player">
    {isLoading ? (
      <div className="loading-indicator">
        <Spin size="large" />
      </div>
    ) : (
      <>
      {/* Waveform SVG */}
      <div className={`sound-wave`}>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 1440 560">
  <g mask={`url(#${maskId})`} fill="none">
      <rect fill="#0e2a47"></rect>
      <g transform="translate(0, 0)" strokeLinecap="round" stroke={`url(#${gradientId})`}>
        <path d="M375 202.15 L375 357.85" stroke-width="17.25" className={`bar-scale2 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M398 155.33 L398 404.67" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M421 196.44 L421 363.56" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M444 259.91 L444 300.09" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M467 208.25 L467 351.75" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M490 184.8 L490 375.2" stroke-width="17.25" className={`bar-scale2 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M513 249.28 L513 310.72" stroke-width="17.25" className={`bar-scale2 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M536 220.75 L536 339.25" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M559 254.8 L559 305.2" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M582 186.77 L582 373.23" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M605 210.13 L605 349.87" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M628 234.45 L628 325.55" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M651 241.1 L651 318.89" stroke-width="17.25" className={`bar-scale2 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M674 202.95 L674 357.05" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M697 165.81 L697 394.19" stroke-width="17.25" className={`bar-scale2 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M720 224.51 L720 335.49" stroke-width="17.25" className={`bar-scale2 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M743 157.59 L743 402.4" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M766 164.98 L766 395.02" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M789 158.93 L789 401.07" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M812 224.24 L812 335.76" stroke-width="17.25" className={`bar-scale2 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M835 171.73 L835 388.27" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M858 264.89 L858 295.11" stroke-width="17.25" className={`bar-scale2 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M881 175.14 L881 384.86" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M904 248.17 L904 311.83" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M927 185.4 L927 374.6" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M950 234.82 L950 325.18" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M973 229.9 L973 330.1" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M996 194.25 L996 365.75" stroke-width="17.25" className={`bar-scale2 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M1019 162.47 L1019 397.53" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M1042 205.06 L1042 354.94" stroke-width="17.25" className={`bar-scale3 ${isPlaying ? '' : 'stop-animation'}`}></path>
        <path d="M1065 240.52 L1065 319.48" stroke-width="17.25" className={`bar-scale1 ${isPlaying ? '' : 'stop-animation'}`}></path>
      </g>
    </g>
    <defs>
    <mask id={maskId}>
        <rect width="1440" height="560" fill="#ffffff"></rect>
      </mask>
      <linearGradient x1="360" y1="280" x2="1080" y2="280" gradientUnits="userSpaceOnUse" id={gradientId}>
        <stop stop-color="#3a7cc3" offset="0"></stop>
        <stop stop-color="#dd1133" offset="1"></stop>
      </linearGradient>
    </defs>
  </svg>
  </div>

      {/* Progress Bar */}
      <div className="audio-buttons">
      {isPlaying ? (
    <div className="pause-button" onClick={pausePlaying}>
      <svg height="30" viewBox="0 0 48 48" width="30" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 38h8V10h-8v28zm16-28v28h8V10h-8z" fill="#ffffff" />
        <path d="M0 0h48v48H0z" fill="none" />
      </svg>
    </div>
  ) : (
    <div className="play-button" onClick={startPlaying}>
      <svg height="30" viewBox="0 0 48 48" width="30" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0h48v48H0z" fill="none" />
        <path d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm-4 29V15l12 9-12 9z" fill="#ffffff" />
      </svg>
    </div>
  )}
      </div>
      <div className="progress-bar-container">
    <div className="progress-bar">
      <div className="progress-color" style={{ width: `${progress}%` }}></div>
    </div>
    {isLocked && (
      <>
      <div className="lock-icon">
        <FontAwesomeIcon icon={faLock} />
      </div>
      <div>NSFW</div>
      </>
    )}
  </div>
</>
    )}
</div>
    </>
  );
};

export default AudioPlayer;

import React, { useState, useContext } from "react";
import { useForm } from "./useForm";
import { useNavigate } from "react-router-dom";
import { logEvent, db, storage } from "../../firebase";
import {updateDoc, doc, collection, addDoc, writeBatch,query, where,getDocs, arrayUnion, getDoc} from "firebase/firestore";
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { createChatId } from '../../helpers';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormGroup,
  FormControl as Input,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";
import styles from "../../styles/MultiStepForm.module.scss";
import { ThemeProvider, createTheme } from '@mui/system';
import { message } from "antd";
import { useLocation} from "react-router-dom";
import { ChatContext } from "../../context/ChatContext";
const MultiStepForm = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const searchParams = new URLSearchParams(location.search);
    let source = searchParams.get('source')
    let subid = searchParams.get('subid')
  const char = queryParams.get("char");
  const { currentUser, userData } = useContext(AuthContext);
  const {dispatch} = useContext(ChatContext)
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false) 
  const navigate = useNavigate();
  const isUserAtLeast18 = () => {
    const birthday = new Date(formState.birthday);
    const today = new Date();
    var age = today.getFullYear() - birthday.getFullYear();
    const monthDifference = today.getMonth() - birthday.getMonth();
  
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
  
    return age >= 18;
  };
  const StartChat = async (characterUID) => {
      
    setLoading(true);
    // Fetch sessionId; it will be either currentUser's UID or tempSessionId
    console.log(localStorage.getItem('tempSessionId'))
    const sessionId = currentUser.uid;
    console.log("start chat session id", sessionId)
    // Initialize the Firestore batch
    const batch = writeBatch(db);
    
    // Only log analytics if a currentUser exists
    if (currentUser) {
      logEvent(`clicked_character_${characterUID}`);
    }
    
    try {
      // Fetch character from Firestore
      var character = null;
      const q = query(
        collection(db, "characters"),
        where("uid", "==", characterUID)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        character = doc.data();
      });
    
      // Create or update the chat group
      const combinedId = createChatId(currentUser.uid, character.uid);
      const userDocRef = doc(db, "users", sessionId);
      if (!userData?.chats?.includes(combinedId)) {
        batch.update(userDocRef, {
          chats: arrayUnion(combinedId)
        });
      }
  
      // Only update Firestore documents if a currentUser exists
      if (currentUser.isAnonymous === false) {
        const charactersLocked = [...userData.charactersLocked];
        const index = charactersLocked.findIndex(character => character.uid === characterUID);
        if (index !== -1) {
          charactersLocked[index] = { ...charactersLocked[index], newMessages: 0 };
          batch.update(userDocRef, { charactersLocked: charactersLocked });
        }
      }
  
      const chatDocRef = doc(db, "chats", combinedId);
      const chatDocSnapshot = await getDoc(chatDocRef);
      if (!chatDocSnapshot.exists() || chatDocSnapshot.data().messages === null) {
        batch.set(chatDocRef, { messages: [], id: combinedId});
      }
  
      // Commit the batch
      await batch.commit();
  
      // Dispatch the character to the Redux store and navigate to the chat page
      dispatch({ type: "CHANGE_USER", payload: character });
      setLoading(false);
      navigate(`/chat?char=${character.uid}`);
    } catch (error) {
      setLoading(false);  // Ensure loading state is reset even on error
      console.error(error);
    }
  };
  const pronounsList = ["He/Him", "She/Her", "They/Them"]; 
  const preferencesList = [
    "Furry",
    "Trans",
    "Male",
    "Female",
    "Femboy",
  ];
  const maxPreferences = 20;
  const [selectedPronouns, setSelectedPronouns] = useState("");
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const eighteenYearsAgo = dayjs().subtract(18, 'year');
  const handlePronounsSelection = (pronouns) => {
    setSelectedPronouns(pronouns);
    handleChange({ target: { name: "pronouns", value: pronouns } });
  };
  const handlePreferenceSelection = (preference) => {
    if (selectedPreferences.includes(preference)) {
      setSelectedPreferences((prevState) => {
        const updatedState = prevState.filter((p) => p !== preference);
        handleChange({ target: { name: "preferences", value: updatedState } });
        return updatedState;
      });
    } else {
      setSelectedPreferences((prevState) => {
        const updatedState = [...prevState, preference];
        handleChange({ target: { name: "preferences", value: updatedState } });
        return updatedState;
      });
    }
  };
  const isFieldEmpty = () => {
    const currentStepKey = steps[step].key;
  
    if (currentStepKey === "birthday" && !isUserAtLeast18()) return true;
  
    if (!formState[currentStepKey]) return true;
    if (typeof formState[currentStepKey] === "string" && formState[currentStepKey].trim() === "") return true;
    
    return false;
  };
  const steps = [
    {
      label: "Name",
      key: "name",
      description: 'Let your waifu know what to call you.',
      component: (formState, handleChange) => (
        <FormGroup>
          <Input
            type="text"
            id="name"
            name="name"
            value={formState.name}
            onChange={handleChange}
            required
          />
        </FormGroup>
      ),
    },
    {
      label: "Your pronouns",
      key: "pronouns",
      component: (formState, handleChange) => (
        <FormGroup>
          <div className={styles.pronouns}>
            {pronounsList.map((pronouns) => (
              <button
                key={pronouns}
                type="button"
                className={`${
                  selectedPronouns === pronouns ? styles.optionButtonSelected : styles.optionButton
                }`}
                onClick={() => handlePronounsSelection(pronouns)}
              >
                {pronouns}
              </button>
            ))}
          </div>
        </FormGroup>
      ),
    },
    {
      label: 'Your date of birth',
      key: 'birthday',
      description: 'Let your waifu know how old you are. You must be at least 18 years old.',
      component: (formState, handleChange) => (
        <FormGroup className={styles.datePicker}>
<DatePicker
  label="Your date of birth"
  value={formState.birthday ? dayjs(formState.birthday) : null}
  onChange={(date) => 
    handleChange({
      target: {
        name: "birthday",
        value: date ? date.toDate() : null, // Convert to JavaScript Date object
      },
    })
  }
  sx={{
    '.MuiTouchRipple-root': {
      display: 'none',
    },
    '.MuiFormLabel-root': {
      fontFamily: 'PPPangramSansRounded-Bold',
    },
    '.MuiInputBase-input': {
      fontFamily: 'PPPangramSansRounded-Bold',
      borderBottom: 'none',
    },
  }}
  maxDate={eighteenYearsAgo}
  openTo="year"
  views={["year", "month", "day"]}
/>
        </FormGroup>
      ),
    },
    {
      label: "Your preferences",
      key: "preferences",
      description: "Based on your answer, waifus are recommended to you. You need to select at least two.",
      component: (formState, handleChange) => (
        <FormGroup>
          <div className={styles.interestContainer}>
            {preferencesList.map((preference) => (
              <button
                key={preference}
                type="button"
                className={`${
                  selectedPreferences.includes(preference)
                    ? styles.optionButtonSelected
                    : styles.optionButton
                }`}
                onClick={() => handlePreferenceSelection(preference)}
                disabled={selectedPreferences.length >= maxPreferences && !selectedPreferences.includes(preference)}
              >
                {preference}
              </button>
            ))}
          </div>
        </FormGroup>
      ),
    },
  ];

  const { formState, handleChange, handleSubmit, errors } = useForm(
    steps,
    onSubmit,
  );

  function onSubmit() {
    console.log(formState);
logEvent('SignUp');
  window.trackExoClickConversion('signup');
  window.trackTwitterConversion("signup")
  logEvent('form_completed', {
    name: formState.name,
    pronouns: formState.pronouns,
    birthday: formState.birthday,
    interests: formState.interests,
    preferences: formState.preferences,
  });
  }

  const handleBack = () => {
    // You can use 'navigate' from 'react-router-dom' if you're using routing
    navigate(-1);
    console.log('Back button clicked');
  };
  return(
    <>
    {loading  ? (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    ) : (
    <div className={styles.wrapper}>
    <button onClick={handleBack} className="back-button">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>{steps[step].label}</h2>
              <span className={styles.description}>{steps[step].description}</span>
            </div>
            {steps[step].component(formState, handleChange)}
            <button
    className={styles.button}
    type={step === steps.length - 1 ? "submit" : "button"}
    onClick={async () => {
        if (step < steps.length - 1) {
            setStep(step + 1);
        } else {
            try {
                await updateDoc(doc(db, "users", currentUser.uid), {
                    created_date: Timestamp.fromDate(new Date()),
                    FormData: {
                        name: formState.name,
                        pronouns: formState.pronouns,
                        birthday: Timestamp.fromDate(new Date(formState.birthday)),
                        interests: formState.interests,
                        preferences: formState.preferences,
                        source: source ? source : 'none',
                        subid: subid ? subid: 'none',
                        created_date: Timestamp.fromDate(new Date())
                    },
                }, {merge: true});

                console.log("Document successfully written!");

                // Log the SignUp event
                logEvent('SignUp');

                if(char) {
                    StartChat(char);
                } else {
                    navigate("/selectchat");
                }
            } catch (error) {
                message.warning("Error writing document: ", error, "Please contact support.");
                console.error("Error writing document: ", error);
            }
        }
    }}
    disabled={isFieldEmpty()}
>
    {step === steps.length - 1 ? "Submit" : "Next"}
</button>
          </form>
        </Col>
      </Row>
    </Container>
  </div>
    )}
  </>
  );
};

export default MultiStepForm;
import React, { useState, useEffect, useContext} from 'react';
import { logEvent, db } from '../../firebase'; // import your firebase configuration
import { AuthContext } from "../../context/AuthContext";
import { Container, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // for navigation
import { collection, query, where, getDocs, doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { ChatContext } from "../../context/ChatContext";
function ChatPage() {
  const [chats, setChats] = useState([]);
  const { currentUser, userData} = useContext(AuthContext);
  const navigate = useNavigate();
  const { dispatch, data, setShowReward, showReward  } = useContext(ChatContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchChats = async () => {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const userChats = userDocSnapshot.data().chats;
        const chatDocsPromises = userChats.map(async chatId => {
          const chatDocRef = doc(db, 'chats', chatId);
          const chatDocSnapshot = await getDoc(chatDocRef);
          const characterId = chatId.replace(currentUser.uid, "");
          const characterDocRef = doc(db, 'characters', characterId);
          const characterDocSnapshot = await getDoc(characterDocRef);
          const photoURL = characterDocSnapshot.exists() ? characterDocSnapshot.data().photoURL : null;
  
          // Check if chatDocSnapshot is null or doesn't exist
          if (!chatDocSnapshot.exists() || !characterDocSnapshot.exists()) {
            return null;
          }
  
          const chatData = chatDocSnapshot.data();
          const lastMessage = chatData.messages && chatData.messages.length > 0 ? chatData.messages[chatData.messages.length - 1].text : "";
          return { ...chatData, lastMessage, id: chatId, photoURL };
        });
  
        const chatsData = await Promise.all(chatDocsPromises);
  
        // Filter out the null chats before setting the state
        const filteredChatsData = chatsData.filter(chat => chat !== null);
        setChats(filteredChatsData);
      }
    };
  
    fetchChats();
  }, [currentUser]);
  const StartChat = async (characterUID, custom = false) => {
    try {
      console.log(userData.characters);
      logEvent(`clicked_character_from_chats_display${characterUID}`);
      setLoading(true);
      console.log(characterUID)
      // Query the Firestore database for the character with the given UID
      var character = null;
      if (!custom) {
        const q = query(
          collection(db, "characters"),
          where("uid", "==", characterUID)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          console.log(doc.data());
          character = doc.data();
        });
      } 
  
      // Check whether the group (chats in Firestore) exists, and create it if it doesn't
      console.log(character)
      const combinedId =
        currentUser.uid > character.uid
          ? currentUser.uid + character.uid
          : character.uid + currentUser.uid;
          const userDocRef = doc(db, "users", currentUser.uid);

          if (!userData.chats || !userData.chats.includes(combinedId)) {
            await updateDoc(userDocRef, {
              chats: arrayUnion(combinedId)
            });
          }
      const chatDocRef = doc(db, "chats", combinedId);
      console.log(combinedId);
      const chatDocSnapshot = await getDoc(chatDocRef);
      if (!chatDocSnapshot.exists() || chatDocSnapshot.data().messages === null) {
        await setDoc(chatDocRef, { messages: [], id: combinedId});
      }

      // Dispatch the character to the Redux store and navigate to the home page
      dispatch({ type: "CHANGE_USER", payload: character });
      setLoading(false);
      navigate(`/chat?char=${character.uid}`);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
            {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (
    <div className='continueChatting'>
      <Container className="chat-container">
        <h4 className="chat-heading">
          Continue Chatting
        </h4>
        <List className="chat-list">
          {chats.map(chat => (
            <React.Fragment key={chat.id}>
              <ListItem 
                alignItems="flex-start" 
                className="chat-item"
                onClick={() => StartChat(chat.id.replace(currentUser.uid, ""))}
              >
                <ListItemAvatar>
                  <Avatar src={chat.photoURL} />
                </ListItemAvatar>
                <ListItemText
                  primary={<span className="chat-name">{`${chat.id.replace(currentUser.uid, "")}`}</span>}
                  secondary={
                    <React.Fragment>
                      <span className="chat-last-message">{` — ${chat.lastMessage}`}</span>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Container>
    </div>
      )}
    </div>
  );
}

export default ChatPage;
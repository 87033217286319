import React, {useState} from 'react';
import '../styles/GiftPopup.scss'; // This assumes you have a CSS file for styling the popup
import gem from '../img/gem.png';
const luxuryGifts = [
    {
      name: "Ferrari",
      description: "A sleek, shiny red Ferrari, the epitome of automotive luxury.",
      image: require("../img/gifts/ferrari.webp"),
      price: 5000 // Example value
    },
    {
      name: "Diamond Necklace",
      description: "An exquisite diamond necklace with a sparkling pendant.",
      image: require("../img/gifts/necklace.webp"),
      price: 4000 // Example value
    },
    {
      name: "Louis Vuitton Handbag",
      description: "A high-end designer handbag from Louis Vuitton, featuring the iconic monogram.",
      image: require("../img/gifts/bag.webp"),
      price: 2500 // Example value
    },
    {
      name: "Gold Watch",
      description: "An elegant gold watch, symbolizing timeless luxury.",
      image: require("../img/gifts/watch.webp"),
      price: 3000 // Example value
    },
    {
      name: "Designer Shoes",
      description: "High-end designer shoes that pair fashion with luxury.",
      image: require("../img/gifts/shoes.webp"),
      price: 1500 // Example value
    },
    {
      name: "Luxury Yacht",
      description: "A personal luxury yacht, offering unparalleled nautical opulence.",
      image: require("../img/gifts/yacht.webp"),
      price: 4500 // Example value
    },
    {
      name: "Mansion",
      description: "An opulent mansion, representing a lavish lifestyle.",
      image: require("../img/gifts/mansion.webp"),
      price: 5000 // Example value
    },
    {
      name: "Private Plane",
      description: "A luxurious private plane for exclusive travel.",
      image: require("../img/gifts/private plan.webp"),
      price: 5000 // Example value
    }
  ];
  
  const GiftPopup = ({ onClose, isOpen }) => {
    const [purchasedItems, setPurchasedItems] = useState([]);

    const handleBuy = (giftName) => {
        // Set the item as purchased by setting its state to true
        setPurchasedItems(prevItems => ({
          ...prevItems,
          [giftName]: true
        }));
        
        // Set up a timeout to "unpurchase" the item after the animation
        setTimeout(() => {
          setPurchasedItems(prevItems => ({
            ...prevItems,
            [giftName]: false
          }));
        }, 1000); // Duration of your animation
        onClose()
      };
      if (!isOpen) {
        return null; // Don't render anything if the popup is not open
      }
      return (
        <div className="gift-popup">
          <div className="gift-popup-header">
            <span>Gifts</span>
            <button className="gift-popup-close" onClick={onClose}>×</button>
          </div>
          <div className="gift-popup-content">
            {luxuryGifts.map((gift) => (
              <div
                id={`gift-${gift.name}`}
                className={`gift-item ${purchasedItems[gift.name] ? 'gift-item-purchased' : ''}`}
                key={gift.name}
                onClick={() => handleBuy(gift.name)}
              >
                <img src={gift.image} alt={gift.name} />
                <span>{gift.name}</span>
                <span className="gift-price">
                  <img src={gem} alt="Gem" />
                  {gift.price}
                </span>
              </div>
            ))}
          </div>
        </div>
      );
    };
    
    export default GiftPopup;
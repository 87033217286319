import React from 'react';
import { Modal, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

const NoGemsModal = ({ visible, onCancel, onGetGems }) => {
  const navigate = useNavigate();

  const handleGetGems = () => {
    if (onGetGems) {
      onGetGems();
      onCancel()
    } else {
      navigate('/shop');
    }
  };

  return (
    <Modal
      title="Need More Gems?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="buyGems" type="primary" onClick={handleGetGems}>
          Get more
        </Button>,
      ]}
      className="no-gems-modal"
    >
      <p>You don't have enough gems to proceed.</p>
      <p>
        <Link to="/shop">Click here to recharge your gems.</Link>
      </p>
    </Modal>
  );
};

export default NoGemsModal;
import React, { useState, useEffect, createContext, useContext, useCallback, useRef, useMemo, memo} from 'react';
import { AuthContext } from "./AuthContext";
import {
    collection,
    query,
    where,
    getDocs,
    setDoc,
    doc,
    updateDoc,
    serverTimestamp,
    getDoc,
    onSnapshot,
    arrayUnion,
    Timestamp,
  } from "firebase/firestore";
  import { db, httpsCallable, functions } from "../firebase";
  import { writeBatch } from "firebase/firestore";  // Import writeBatch
import { message } from 'antd';
import { ChatContext } from './ChatContext';
export const CharacterContext = createContext();
export const CharacterProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState("recommended")
  const [charactersData, setCharactersData] = useState([]);
  const [charactersLoading, setCharactersLoading] = useState(true)
  const { currentUser, userData } = useContext(AuthContext)
  const [hasFetchedCharacters, setHasFetchedCharacters] = useState(false);
  const [filteredContentState, setFilteredContentState] = useState([]);
  let allCharacters = [];
  // I'm assuming that fetchCharacters function is defined within the provider
  // You may need to adjust this part based on your actual implementation
  const categories = [
    'recommended', 'giantess', 'saviorfagging', 'depressed', 'special', 
    'anime', 'male', 'yandere', 'schoolgirl', 'furry', 'dominant', 
    'private video waifus', 'picture waifus', 'femboy', 'cute', 
    'slave', 'virgin', 'sadistic', 'realistic', 'dark fantasy', 
    'anime game', 'vtuber', 'girlfriend', 'bully', 'romance', 
    'scenario', 'action', 'love', 'pony', 'multiple characters'
  ];
  useEffect(() => {
    // Function to update the locked status of a character in charactersData
    const updateLockedStatusInCharactersData = (newCharactersLocked) => {
      setCharactersData(currentCharacters => {
        return currentCharacters.map(character => {
          const lockedCharacter = newCharactersLocked.find(c => c.uid === character.uid);
          return lockedCharacter 
            ? { ...character, locked: lockedCharacter.locked } 
            : character;
        });
      });
    };

    if (userData && userData.charactersLocked) {
      updateLockedStatusInCharactersData(userData.charactersLocked);
    }
  }, [userData?.charactersLocked]);
  // useEffect to update filtered content when charactersData or selectedCategory changes
  const filteredContent = useMemo(() => {
    if(!charactersData || charactersData.length === 0)
    {
      return []
    }
    const filterCharacters = (characters, category) => {
      const maxTotalCharacters = 500;
      let content = [];
    
      if (!Array.isArray(characters)) {
        console.log("filtered content");
        return [];
      }
    
      const sortedCharacters = [...characters].sort((a, b) => 
        b.messagesCount - a.messagesCount
      );
    
      for (const character of sortedCharacters) {
        if (content.length >= maxTotalCharacters) {
          break;
        }
        if (category.toLowerCase() === 'favorites') {
          console.log("selectedCategory", userData.favorites);
          if (userData.favorites.includes(character.uid)) {
            content.push(character);
          }
        } else if (category.toLowerCase() === 'all' || (character.category && Array.isArray(character.category) && character.category.some(cat => cat.toLowerCase().trim() === category.toLowerCase().trim()))) {
          content.push(character);
        }
      }
      return content;
    };
    
  
    return filterCharacters(charactersData, selectedCategory);
  }, [charactersData, selectedCategory]);
  useEffect(() => {
    if (filteredContent) {
      setFilteredContentState(filteredContent);
      console.log("filtered content", filteredContent);
    }
  }, [filteredContent]);
  useEffect(() => { 
    // Function to fetch characters from the database
    const fetchCharacters = async () => {
      if (!userData || !userData.uid || charactersData.length > 0) return;
  console.log("fetching now")
      setCharactersLoading(true);
    
      try {
        const charactersFromDB = userData.charactersLocked ?
          await updateCharacters(currentUser.uid) : // 10 seconds timeout
          await updateCharactersNoUser(); // 10 seconds timeout
        
        console.log("set charactersData", charactersFromDB);
        setCharactersData(charactersFromDB);
        setCharactersLoading(false);
        
      } catch (error) {
        console.error("Error fetching characters or operation timed out:", error);
        message.error("Error fetching characters or operation timed out:", error);
        // Additional logging or handling.
      }
    }
  
    // Function to update characters locked state
  
    // Main logic to decide which function to call
  // Fetch characters only if userData changes and we haven't fetched them already
  if (userData && !hasFetchedCharacters) {
    console.log("Fetching characters now");
    fetchCharacters();
    setHasFetchedCharacters(true); // Set flag to true after fetching
  }

  }, [userData]);
useEffect(() => {
  const updateCharactersLockedState = async (characters) => {
    setCharactersLoading(true);
    try {
      const updates = characters
        .map(character => ({
          uid: character.uid,
          locked: character.locked || false,
          lockAmount: character.lockAmount || 0,
          level: character.level || 1,
        }))
        .filter(character => character.uid);
console.log("updates", updates)
      if (updates.length > 0) {
        const userDoc = doc(db, "users", currentUser.uid);
        await updateDoc(userDoc, { charactersLocked: updates });
        console.log("Locked characters updated successfully.");
      }
    } catch (error) {
      console.error("Error updating locked characters:", error);
    } finally {
      setCharactersLoading(false);
    }
  };

  if (userData && userData.uid &&!userData.charactersLocked && charactersData.length > 0) {
    updateCharactersLockedState(charactersData);
  }
}, [userData, charactersData])

  const fetchAndCombineCharactersAll = useCallback(async () => {
    const combineCharactersAll = httpsCallable(functions, 'combineCharactersAll');
    try {
        const startTime = performance.now(); // Start time before function execution

        const result = await combineCharactersAll();

        const endTime = performance.now(); // End time after result is fetched
        const totalTime = endTime - startTime; // Total time including execution and fetching

        console.log(`Total Execution Time: ${totalTime} ms`);

        return result.data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
}, []);

const timeout = (promise, ms) => {
  let timeoutId;
  const timeoutPromise = new Promise((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(new Error("Operation timed out"));
    }, ms);
  });
  return Promise.race([promise, timeoutPromise]).finally(() => {
    clearTimeout(timeoutId);
  });
};



  
  
const updateCharacters = async (UID, category) => {
  const querySnapshot = await fetchAndCombineCharactersAll();
  const charactersFromDB = [];
  const charactersLockedUpdates = [];

  querySnapshot.forEach((docData) => {
    // Check if docData is valid
    if (!docData || typeof docData !== 'object') {
      console.error('Invalid docData encountered:', docData);
      return;
    }

    // Skip characters in the "community" category
    if (docData.category && docData.category.includes("community")) {
      return;
    }

    let character = UID ? userData.charactersLocked.find(
        (c) => c.uid === docData.uid
    ) : null;

    if (UID && !character) {
      // Ensure that no undefined values are pushed
      charactersLockedUpdates.push({
        uid: docData.uid || 'defaultUID',
        locked: docData.locked || false,
        lockAmount: docData.lockAmount || 0,
        level: docData.level || 1 // Changed from false to 0 assuming level is a number
      });
    }

    charactersFromDB.push({
      name: docData.displayName || 'Unknown Name',
      image: docData.photoURL || 'defaultImageURL',
      description: docData.description || 'No description available',
      category: docData.category ? docData.category.split(",") : [],
      messagesCount: docData.messagesCount || 0,
      locked: character?.locked || false,
      lockAmount: docData.lockAmount || 0,
      level: docData.level || 1,
      uid: docData.uid,
      newMessages: character?.newMessages || 0,
      withPictures: docData.withPictures || false,
      withVideos: docData.video || false,
      looks: docData.looks
    });
  });

  if (UID && charactersLockedUpdates.length > 0) {
    const userDoc = doc(db, "users", UID);
    const batch = writeBatch(db);

    // Update only if charactersLockedUpdates array is free of undefined values
    batch.update(userDoc, { charactersLocked: [...userData.charactersLocked, ...charactersLockedUpdates.filter(item => item.uid)] });
    await batch.commit();
  }
  setCharactersLoading(false);
  charactersFromDB.sort((a, b) => b.messagesCount - a.messagesCount);
  return charactersFromDB;
};

  const updateCharactersNoUser = async (category) => {
    const querySnapshot = await fetchAndCombineCharactersAll();
    const charactersFromDB = [];
  
    querySnapshot.forEach((docData) => {
      if (docData.category.includes("community")) {
        return;
      }

      charactersFromDB.push({
        name: docData.displayName,
        image: docData.photoURL,
        description: docData.description,
        category: docData.category.split(","),
        messagesCount: docData.messagesCount,
        locked: docData.locked ? docData.locked : false,
        lockAmount: docData.lockAmount ? docData.lockAmount : 0,
        level: docData.level ? docData.level : 1,
        uid: docData.uid,
        newMessages: 0,
        withPictures: docData.withPictures ? docData.withPictures : false,
        withVideos: docData.video ? docData.video : false,
        looks: docData.looks
      });

    });
    charactersFromDB.sort((a, b) => b.messagesCount - a.messagesCount);
    return charactersFromDB;
  };


  return (
    <CharacterContext.Provider value={{ selectedCategory, setSelectedCategory, charactersData, charactersLoading, filteredContentState }}>
      {children}
    </CharacterContext.Provider>
  );
};
import React from 'react';
import { Modal, Button } from 'antd';
// Custom styles for the waifu theme
const waifuModalStyle = {
  backgroundColor: '#ffccf9', // Light pink background
  color: '#4a4a4a', // Dark text for contrast
  borderRadius: '10px', // Rounded corners
  fontFamily: '"Comic Sans MS", cursive, sans-serif', // Playful font
  textAlign: 'center', // Centered text
};

const waifuButtonStyle = {
  backgroundColor: '#ff69b4', // Vibrant pink button
  borderColor: '#ff69b4', // Matching border color
  color: 'white', // White text for readability
  fontWeight: 'bold', // Bold text
  borderRadius: '5px', // Slightly rounded button edges
};

// Function component with props
const VoiceLimitModal = ({ isOpen, onClose }) => {
  const handleButtonClick = () => {
    window.location.href = '/shop'; // Replace with your shop URL
  };

  return (
    <div>
      {/* Waifu-themed modal */}
      <Modal
        title="Oh no! Daily Limit Reached"
        visible={isOpen}
        onCancel={onClose}
        footer={null}
        bodyStyle={waifuModalStyle}
      >
        <p>You've listened to all your voice messages for today!</p>
        <p>Subscribe for unlimited access and more NSFW content!</p>
        <Button style={waifuButtonStyle} onClick={handleButtonClick}>
          Visit the Shop
        </Button>
      </Modal>
    </div>
  );
};

export default VoiceLimitModal;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import styles from '../../../styles/MemorySidebar.module.scss';
import { db } from "../../../firebase";
import { Modal, Input, Button, Popconfirm } from 'antd';
import { setDoc, doc, getDoc } from "firebase/firestore";
import InfoButton from '../../Common/InfoButton';
function MemorySidebar({isOpen, handleClose, user}) {
  const [userMemories, setUserMemories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newMemory, setNewMemory] = useState('');
  const [editMemoryIndex, setEditMemoryIndex] = useState(null);

  const addMemory = async () => {
    if(newMemory) {
      const userDoc = doc(db, 'users', user.uid);
      const userMemories = user?.memories || [];
      userMemories.push({
        content: newMemory,
        timestamp: new Date()
      });
      await setDoc(userDoc, { memories: userMemories }, { merge: true })
      .then(() => {
        setIsModalVisible(false);
        setNewMemory('');
      });
    }
  };

  const editMemoryContent = async () => {
    if(editMemoryIndex !== null) {
      const userDoc = doc(db, 'users', user.uid);
      const userMemories = user?.memories || [];
      userMemories[editMemoryIndex].content = newMemory;
      await setDoc(userDoc, { memories: userMemories }, { merge: true });
      setIsModalVisible(false);
      setNewMemory('');
      setEditMemoryIndex(null);
    }
  };
  useEffect(() => {
    console.log('User object:', user);
    if (user.memories) {
      setUserMemories(user.memories)
    }
  
  }, [user]);
  const deleteMemory = async (index) => {
    const userDoc = doc(db, 'users', user.uid);
    const userMemories = user?.memories || [];
    userMemories.splice(index, 1);
    await setDoc(userDoc, { memories: userMemories }, { merge: true });
  };

  const showModal = (index) => {
    if(index !== null) {
      setEditMemoryIndex(index);
      setNewMemory(userMemories[index].content);
    }
    setIsModalVisible(true);
  };


  return (
    <div className={`${styles['memory-sidebar']} ${isOpen ? styles.open : ""}`}>
      <button className={styles.close} onClick={handleClose}>x</button>
      <InfoButton 
        title="Info" 
        content={[`Improve your waifu's understanding with the "Memory" feature. Specify how you want your waifu to perceive you by adding facts. Elevate your connection to a whole new level.`]} 
      />
      <h1 className={styles.headline}>Memory</h1>
      <h2 className={styles.subHeadline}>FACTS ABOUT YOU</h2>
      <button className={styles.addButton} onClick={() => showModal(null)}><FontAwesomeIcon icon={faPlus} /></button>
      <div className={styles.memoryStore}>
        <div className={styles.memoryGrid}>
          {userMemories.map((memory, index) => (
            <div key={index} className={styles.memory}>
              <p>{memory.content}</p>
              <div className={styles.memoryActions}>
                <button className={styles.editButton} onClick={() => showModal(index)}><FontAwesomeIcon icon={faPencilAlt} /></button>
                <Popconfirm
                  title="Are you sure you want to delete this memory?"
                  onConfirm={() => deleteMemory(index)}
                  okText="Yes"
                  cancelText="No"
                >
                  <button className={styles.deleteButton}><FontAwesomeIcon icon={faTrash} /></button>
                </Popconfirm>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        title={editMemoryIndex !== null ? "Edit Memory" : "Add New Memory"}
        visible={isModalVisible}
        onOk={editMemoryIndex !== null ? editMemoryContent : addMemory}
        onCancel={() => {
          setIsModalVisible(false);
          setNewMemory('');
          setEditMemoryIndex(null);
        }}
      >
        <Input 
          placeholder="Enter your memory"
          value={newMemory}
          onChange={e => setNewMemory(e.target.value)}
        />
      </Modal>
    </div>
  );
}

export default MemorySidebar;
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Card, CardContent, CardActions, Typography, IconButton, createTheme, ThemeProvider, CircularProgress, TextField, Button} from '@mui/material';
import { ArrowUpward, ArrowDownward, Delete, Edit } from '@mui/icons-material';
import { doc, getDoc, deleteDoc, onSnapshot } from '@firebase/firestore';
import { db, logEvent, functions, httpsCallable } from '../../firebase';
import { AuthContext } from "../../context/AuthContext";
import BoostPopup from './BoostPopup';
import { Popconfirm } from 'antd';
import {updateDoc} from "firebase/firestore"
import ImageUpload from "./ImageUpload.jsx"
const theme = createTheme({
  typography: {
    fontFamily: 'PPPangramSansRounded-Bold',
  },
});

const Character = ({ character, rank }) => {
  const { currentUser, userData } = useContext(AuthContext);
  const [userVote, setUserVote] = useState(null);
  const [upvotes, setUpvotes] = useState(character.votes.upvotes || 0);
  const [downvotes, setDownvotes] = useState(character.votes.downvotes || 0);
  const rankEmojis = ['🥇🔥', '🥈', '🥉'];
  const [isVoting, setIsVoting] = useState(false); // State to track voting status
  const [votingType, setVotingType] = useState(null); // State to track which vote type is being processed
  const [isEditing, setIsEditing] = useState(false);
  const [newImageUrl, setNewImageUrl] = useState(character.imageUrl);

  useEffect(() => {
    // Firestore listener for character vote updates
    const charRef = doc(db, 'votingCharacters', character.id);
    const unsubscribe = onSnapshot(charRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setUpvotes(data.votes.upvotes || 0);
        setDownvotes(data.votes.downvotes || 0);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [character.id]);
  const handleVote = useCallback(async (voteType) => {
    if (isVoting) return;
    setIsVoting(true);
    setVotingType(voteType);

    const castVote = httpsCallable(functions, 'castVote');
    try {
      // Determine if the vote is being toggled off
      const isVoteToggle = userVote === voteType;
      const newVoteType = isVoteToggle ? null : voteType;

      const { data } = await castVote({ characterId: character.id, voteType: newVoteType });
      if (data) {
        setUserVote(isVoteToggle ? null : newVoteType);
        setUpvotes(data.upvotes);
        setDownvotes(data.downvotes);
      }
    } catch (error) {
      console.error("Vote error:", error);
    } finally {
      setIsVoting(false);
      setVotingType(null);
    }
}, [character.id, isVoting, userVote]);


  const handleDelete = useCallback(async () => {
    const characterRef = doc(db, 'votingCharacters', character.id);
    await deleteDoc(characterRef);
    logEvent('character_deleted', { characterId: character.id, userId: currentUser.uid });
  }, [character.id, currentUser.uid]);

  const netVotes = useMemo(() => upvotes - downvotes, [upvotes, downvotes]);

  return (
    <ThemeProvider theme={theme}>
      <Card className="card" sx={{ borderRadius: '20px' }}>
        <CardContent className="card-content">
        {isEditing ? (
  <div className="upload-container">
    <ImageUpload onImageUpload={(newUrl) => setNewImageUrl(newUrl)} />
  </div>
) : (
  <img src={character.imageUrl} alt={character.name} className="character-image" />
)}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h4" component="div" className="character-name">
              {rank < 3 ? rankEmojis[rank] : `#${rank + 1}`} {character.name}
            </Typography>
            {currentUser?.email === "admin@test.com" && (
              <Popconfirm title="Are you sure you want to delete this character?"
                          onConfirm={handleDelete}
                          okText="Yes" cancelText="No">
                <IconButton>
                  <Delete />
                </IconButton>
              </Popconfirm>
            )}
          </div>
          <Typography variant="body1" color="text.secondary" className="character-description">
            {character.description}
          </Typography>
          <Typography variant="body2" color="text.secondary" className="character-user">
            @{character.userName}
          </Typography>
        </CardContent>
        <CardActions className="card-actions">
          <div className="actions-container">
          <IconButton disabled={isVoting} color={userVote === 'upvotes' ? "primary" : "default"}
                onClick={() => handleVote('upvotes')} className="upvote-button">
      {isVoting && votingType === 'upvotes' ? <CircularProgress size={24} /> : <ArrowUpward />}
    </IconButton>
            <Typography className="net-votes">{netVotes}</Typography>
            <IconButton disabled={isVoting} color={userVote === 'downvotes' ? "secondary" : "default"}
                        onClick={() => handleVote('downvotes')} className="downvote-button">
 {isVoting && votingType === 'downvotes' ? <CircularProgress size={24} /> : <ArrowDownward />}
            </IconButton>
          </div>
          <div className="actions-container">
            {userData && <BoostPopup character={character} user={userData} authUser={currentUser}/>}
          </div>
          {currentUser?.uid === character.createdBy && (
            <Button 
              startIcon={<Edit />} 
              onClick={() => setIsEditing(!isEditing)}
            >
              {isEditing ? "Done" : "Edit Picture"}
            </Button>
          )}
        </CardActions>
      </Card>
    </ThemeProvider>
  );
};

export default Character;
// ExoClickUtil.js

import axios from 'axios';
import tracking from './tracking';

const FUNCTION_URL = 'https://us-central1-oniichat-2c310.cloudfunctions.net/trackExoClickConversion';

const trackExoClickConversion = async (eventType, conversionValue = null) => {
  const conversionId = tracking.getExoConversionId();
  if (!conversionId) {
    console.error('ExoClick Error: No conversion tracking ID found in localStorage');
    return;
  }

  try {
    const response = await axios.post(FUNCTION_URL, {
      eventType,
      conversionId,
      conversionValue
    });

    console.log(`ExoClick: ${response.data.message}`);
    return response.data;
  } catch (error) {
    console.error(`ExoClick Error: ${error.response?.data?.error || error.message}`);
  }
};

// Expose the tracking function globally
window.trackExoClickConversion = trackExoClickConversion;

import React, { useRef, useContext, useEffect, useState } from "react";
import Footer from "../../components/Common/Footer"
import Nav from "../../components/Common/MainNav"

const PrivacyPolicy = () => {

  useEffect(() => {
  });

  return (
    <div>
<Nav/>
    
    <div className='simpleContent'>
<div className="content">
<div>

<p><b><h3>Privacy
Policy</h3></b></p>

<p><b><i><span >Last
Modified: February 20th, 2023</span></i></b></p>

<p><b><span >Introduction</span></b></p>

<p>Oniichat.com is a website operated solely by M.T.Kashi (“Oniichat”). As a sole proprietorship, Oniichat's responsibilities and operations are managed exclusively by M.T.Kashi. References to “<b><i>we</i></b>,” “<b><i>us</i></b>,” or “<b><i>our</i></b>” on the <a href="https://www.oniichat.com/">Oniichat.com</a> website and its associated properties solely pertain to M.T.Kashi, in the capacity of the sole proprietor and operator of Oniichat.</p>

<p><span >Please read
this privacy policy carefully, as your access to and use of our Website
signifies that you have read, understand, and agree to all terms within this
privacy policy. If you do not agree with any part of this privacy policy or our
terms, please do not access or continue to use our Website or otherwise submit your
personal data. We respect your privacy and are committed to protecting your
personal data.</span></p>

<p><span >If you have any
questions about our privacy practices, please see “<i>Contact Information</i>”
below for information on how to contact us.</span></p>

<p><span >We collect,
process, and retain personal data to the extent it is necessary to provide
users with our service. This privacy policy applies to information we collect:</span></p>

<ul>
 <li><span >on this
     Website,</span></li>
 <li><span >in e-mail,
     text and other communications between you and this Website,</span></li>
 <li><span >through
     mobile applications you download from this Website, which provide
     dedicated non-browser-based interaction between you and this Website, or</span></li>
 <li><span >when you
     interact with our advertising and applications on third-party websites and
     services if those applications or advertising include links to this
     privacy policy.</span></li>
</ul>

<p><span >It does not
apply to information collected by:</span></p>

<ul>
 <li><span >us offline or
     through any other means, including on any other website operated by us or
     any third party (including our affiliates and subsidiaries); or</span></li>
 <li><span >any third
     party (including our affiliates and subsidiaries), including through any
     application or content (including advertising)&nbsp;that may link to or be
     accessible from or on the Website. Clicking on those links or enabling
     those connections may allow third parties to collect or share data about
     you. We do not control these third-party websites and are not responsible
     for their privacy statements.</span></li>
</ul>

<p><b><span >The Data We
Collect About You</span></b></p>

<p><span >Personal data,
or personal information, means any information about an individual from which
that person can be identified by (“<b><i>Personal Information</i></b>”). It
does not include data that has been anonymized or pseudonymized.</span></p>

<p><span >By agreeing to
the terms of service of our website, you acknowledge and consent that we may
collect, use, and disclose the information and data you provide to us in
accordance with our privacy policy. This includes, but is not limited to, your
name, email address, and any other information you provide when creating an
account or submitting a form on our website. We may use this information to improve
our services, provide you with personalized content, and send you marketing and
promotional materials. We may also share your information with third-party
service providers who assist us in providing and improving our services. By
submitting your information to us, you agree to our collection, use, and
disclosure of your information as described in this policy. If you do not agree
to these terms, please do not use our website or submit any personal
information to us.</span></p>

<p><span >We may collect,
use, store and transfer different kinds of personal data about you, which we
have grouped together as follows:</span></p>

<ul>
 <li><b><i><span >Identity
     Data</span></i></b><span >&nbsp;includes
     first name, maiden name, last name, username or similar identifier,
     pictures of YOU (which are send in the chatrooms by YOU) marital status,
     title, date of birth and gender.</span></li>
 <li><b><i><span >Contact
     Data</span></i></b><span >&nbsp;includes
     billing address, email address and telephone numbers.</span></li>
 <li><b><i><span >Financial
     Data</span></i></b><span >&nbsp;includes
     bank account and payment card details.</span></li>
 <li><b><i><span >Transaction
     Data</span></i></b><span >&nbsp;includes
     details about payments to and from you and other details of products and
     services you have purchased or received from us.</span></li>
 <li><b><i><span >Technical
     Data</span></i></b><span >&nbsp;includes
     internet protocol (IP) address, your login data, browser type and version,
     time zone setting and location, browser plug-in types and versions,
     operating system and platform and other technology on the devices you use
     to access this Website.</span></li>
 <li><b><i><span >Profile
     Data</span></i></b><span >&nbsp;includes
     your username and password, purchases or orders made by you, your
     interests, preferences, feedback and survey responses.</span></li>
 <li><b><i><span >Usage
     Data</span></i></b><span >&nbsp;includes
     information about how you use our Website, products and services.</span></li>
 <li><b><i><span >Marketing
     and Communications Data</span></i></b><span >&nbsp;includes
     your preferences in receiving marketing from us and our third parties and
     your communication preferences.</span></li>
</ul>

<p><span >We may also
collect, use and share your data to produce and share aggregated insights that
do not identify you. Aggregated data may be derived from your personal data but
is not considered personal data as this data does not directly or indirectly
reveal your identity. We may also aggregate your usage data to calculate the
percentage of users accessing a specific Website feature. For example we may
use your data to generate statistics about our users, to calculate the
percentage of users accessing a specific Website feature, to calculate ad
impressions served or clicked on, or to publish visitor demographics.</span></p>

<p><span >We do collect
special categories of Personal Information about you (this includes details
about your race or ethnicity, religious or philosophical beliefs, political
opinions, trade union membership, or information about your health). Depending
on how you use our Website and services, your Personal Information may include
biometric information, or information that allows drawing conclusions about
your sex life or sexual orientation (“<b><i>Sensitive Personal Information</i></b>”).
The collection, use and disclosure of such Sensitive Personal Information is
necessary to provide some of our service to you.</span></p>

<p><b><span >How is Your
Personal Information Collected?</span></b></p>

<p><span >We use
different methods to collect data from and about you including through:</span></p>

<ul>
 <li><b><i><span >Direct
     interactions.&nbsp;</span></i></b><span >Information
     that you provide when chatting within the Chatrooms, carrying out search
     queries on our Website or by filling in forms on our Website, in
     particular at the time of registering to use our Website, subscribing to
     our service, posting material, taking part in surveys, entering a contest
     or promotion sponsored by us, when reporting a problem with our Website.
     or requesting further services.</span></li>
 <li><b><i><span >Automated
     technologies or interactions</span></i></b><span >.
     See “<i>Third-party Use of Cookies and Other Tracking Technologies</i>”
     for details of how we may automatically collect your personal data.</span></li>
 <li><b><i><span >Third
     parties or publicly available sources</span></i></b><span
     >. We may receive personal data
     about you from various third parties, for example, other websites
     controlled by us or members of our corporate group, our business partners
     or other third parties and public sources.</span></li>
</ul>

<p><b><span >Information
Collected Through Automatic Data Collection Technologies</span></b></p>

<p><span >As you navigate
through and interact with our Website, we use automatic data collection
technologies to collect certain information about your equipment, browsing
actions and patterns, including information such as your IP address, browser
type, operating system, the referring web page, pages visited, location, your
mobile carrier, device information (including device and application IDs),
search terms, and cookie information.</span></p>

<p><span >We also use
these technologies to collect information about your online activities over
time and across third-party websites or other online services (“interest-based
advertising”). See “<i>Choices About How We Collect, Use and Disclose Your
Personal Information</i>” below for information on how you can opt out of
behavioral tracking on this Website and how we respond to web browser signals
and other mechanisms that enable consumers to exercise choice about behavioral
tracking. The technologies we use for this automatic data collection may
include:</span></p>

<ul>
 <li><b><i><span >Cookies
     (or browser cookies)</span></i></b><span >.
     Cookies are small text files that are stored in your web browser or
     downloaded to your device when you visit a website. Cookies are then sent
     back to the originating website on each subsequent visit, or to another
     website that recognizes that cookie, and allow a website to recognize a
     user’s device.</span></li>
</ul>

<p><span >We currently
use the following types of cookies:</span></p>

<ul>
 <ul>
  <li><i><span >Cookies
      that are strictly necessary</span></i><span >:
      These are cookies that are required for the operation of our Website.
      These include, for example, cookies that enable a user to log in to our
      Website and to check if a user is allowed access to a particular service
      or content.</span></li>
  <li><i><span >Analytical
      cookies</span></i><span >: These
      cookies allow us to recognize and count the number of users and to see
      how users use and explore our Website. These cookies help us to improve
      our Website, for example by ensuring that all users are able to find what
      they are looking for easily.</span></li>
  <li><i><span >Functionality
      cookies</span></i><span >: These
      cookies are not essential, but help us to personalize and enhance your
      online experience on our Website. This type of cookies allows us to
      recognize you when you return to our Website and to remember, for
      example, your choice of language.</span></li>
  <li><i><span >Targeting
      cookies</span></i><span >: These
      cookies record the visit of a user on our Website, the pages a user has
      visited and the links a user has followed in order to enable us to make
      our Website more relevant to the users' interests.</span></li>
  <li><span >We do not
      require that you accept cookies and you may withdraw your consent to our
      use of cookies at any time by adjusting your browser’s privacy settings.
      However, if you decline to accept cookies, some functionality on our
      Website may be disabled and you may be unable to access certain parts of
      our Website. Unless you have adjusted your browser setting so that it
      will refuse cookies, our system will issue cookies when you direct your
      browser to our Website. Cookies can be either session cookies or
      persistent cookies. A session cookie expires automatically when you close
      your browser. A persistent cookie will remain until it expires or you
      delete your cookies. Expiration dates are set in the cookies themselves;
      some may expire after a few minutes while others may expire after
      multiple year</span></li>
 </ul>
</ul>

<ul>
 <li><b><i><span >Flash
     Cookies</span></i></b><span >.
     Certain features of our Website may use local stored objects (or Flash
     cookies)&nbsp;to collect and store information about your preferences and
     navigation to, from and on our Website. Flash cookies are not managed by
     the same browser settings as are used for browser cookies. For information
     about managing your privacy and security settings for Flash cookies, see “<i>Choices
     About How We Collect, Use and Disclose Your Personal Information</i>”.</span></li>
 <li><b><i><span >Web
     Beacons</span></i></b><span >. Pages
     of our Website and our e-mails may contain small electronic files known as
     web beacons (also known as clear gifs, pixel tags, single-pixel gifs and
     web bugs)&nbsp;that are tiny graphics with a unique identifier, similar in
     function to cookies, and are used to track the online movements of web
     users or to access cookies. Unlike cookies, which are stored on the user’s
     computer hard drive, web beacons are embedded invisibly on the web pages
     (or in an email)&nbsp;and are about the size of the period at the end of
     this sentence. Web beacons may be used to deliver or communicate with
     cookies, to count users who have visited certain pages or opened an
     e-mail, to understand usage patterns and for other related website
     statistics (for example, recording the popularity of certain website
     content and verifying system and server integrity). We also may receive an
     anonymous identification number if you come to our site from an online
     advertisement displayed on a third-party website.</span></li>
 <li><b><i><span >Analytics</span></i></b><span
     >. We use third party analytics and
     advertising tools and technologies, in particular Google Analytics and
     DoubleClick provided by Google, Inc., USA (“Google”). These tools and
     technologies collect and analyze certain types of information, including
     IP addresses, device and software identifiers, referring and exit URLs,
     onsite behavior and usage information, feature use metrics and statistics,
     usage and purchase history, media access control address (MAC Address),
     mobile unique device identifiers, and other similar information via the
     use of cookies. The information generated by Google Analytics and DoubleClick
     about your use of the Website (including your IP address)&nbsp;may be
     transmitted to and stored by Google on servers in the United States.
     Because we activated IP anonymization for Google Analytics and Double
     Click, Google will anonymize the last octet of a particular IP address.
     Only in exceptional cases, the full IP address is sent to and shortened by
     Google servers in the USA. Google will use this information for the
     purpose of evaluating your use of the Website, compiling reports on
     Website activity and managing advertising content. To learn how you can
     opt out from this information collection by Google see “<i>Choices About
     How We Collect, Use and Disclose Your Personal Information</i>” below.</span></li>
</ul>

<p><b><span >How We Use
Your Personal Data</span></b></p>

<p><span >We will only
use your personal data when the applicable local law allows us to. Most
commonly, we will use your personal data in the following circumstances:</span></p>

<ul>
 <li><span >For purposes
     of providing the services, customer management and functionality and
     security as is necessary to perform the services provided to you under our
     term and conditions and any other contract that you have with us.</span></li>
 <li><span >Biometric
     information and identifiers are processed based upon your consent for us
     to do so.</span></li>
 <li><span >Where it is
     necessary for our legitimate interests (or those of a third party) and
     your interests and fundamental rights do not override those interests.</span></li>
 <li><span >Where we need
     to comply with a legal or regulatory obligation.</span></li>
</ul>

<p><span >Generally we do
not rely on consent as a legal basis for processing your personal data other than
in relation to sending third party direct marketing communications to you via
email or text message.</span></p>

<p><span >Note that we
may process your personal data for more than one lawful ground depending on the
specific purpose for which we are using your data.</span></p>

<p><b><span >Purposes for
Which We Use Your Personal Information</span></b></p>

<p><span >In general, we
use information that we collect about you or that you provide to us, including
Personal Information and Sensitive Personal Information, for following
purposes:</span></p>

<ul>
 <li><b><i><span >Provision
     of services</span></i></b><span >:
     to present our Website and its contents to you, including any interactive
     features on our Website, and to provide you with information, products or
     services that you request from us; we also collect and use Personal
     Information to verify your eligibility and deliver prizes in connection
     with contests and sweepstakes;</span></li>
 <li><b><i><span >Customer
     management</span></i></b><span >: to
     manage a registered users’ account, to provide customer support and
     notices to the registered user about his account or subscription,
     including expiration and renewal notices, and notices about changes to our
     Website or any products or services we offer or provide through it;</span></li>
 <li><b><i><span >Advertising</span></i></b><span
     >: to communicate with you about
     products or services that may be of interest to you either from us, our
     affiliates or other third parties (For more information, see “<i>Choices
     About How We Collect, Use and Disclose Your Personal Information</i>”);</span></li>
 <li><b><i><span >Analytics</span></i></b><span
     >: to determine whether users of the
     Website are unique, or whether the same user is using the Website on
     multiple occasions, and to monitor aggregate metrics such as total number
     of visitors, pages viewed, demographic patterns;</span></li>
 <li><b><i><span >Functionality
     and security</span></i></b><span >:
     to diagnose or fix technology problems, and to detect, prevent, and
     respond to actual or potential fraud, illegal activities, or intellectual
     property infringement;</span></li>
 <li><b><i><span >Compliance</span></i></b><span
     >: to enforce our terms and
     conditions and to comply with our legal obligations;</span></li>
 <li><span >in any other
     way we may describe when you provide the information; or for any other
     purpose with your consent provided separately from this privacy policy.</span></li>
</ul>

<p><b><span >Disclosure
of Your Personal Information</span></b></p>

<p><span >We do not
disclose your Personal Information except in the limited circumstances
described here.</span></p>

<ul>
 <li><span >We may
     disclose your Personal Information to members of our corporate group (that
     is, entities that control, are controlled by, or are under common control
     with us) to the extent this is necessary for the purposes of provision of
     services, customer management, advertising,
     analytics, verifications, functionality and security, and compliance.</span></li>
 <li><b><i><span >Service
     providers.&nbsp;</span></i></b><span >To
     our authorized service providers that perform certain services on our
     behalf. These services may include fulfilling orders, processing credit
     card payments, risk and fraud detection and mitigation, providing customer
     service, performing business and sales analysis, analytics, security, supporting our Website functionality, and supporting
     contests, sweepstakes, surveys and other features offered through our
     Website. These service providers may have access to Personal Information
     needed to perform their functions but are not permitted to share or use
     such information for any other purposes.</span></li>
 <li><b><i><span >Legal
     successors</span></i></b><span >. To
     a buyer or other successor in the event of a merger, divestiture,
     restructuring, reorganization, dissolution or other sale or transfer of
     some or all of our assets, whether as a going concern or as part of
     bankruptcy, liquidation or similar proceeding, in which personal
     information held by us about our Website users is among the assets
     transferred. Should such a sale or transfer occur, we will use reasonable
     efforts to try to ensure that the entity to which we transfer your
     personal information uses it in a manner that is consistent with this
     privacy policy.</span></li>
</ul>

<p><span >We access,
preserve and share your Personal Information with regulators, law enforcement
or others where we reasonably believe such disclosure is needed to
(a)&nbsp;satisfy any applicable law, regulation, legal process, or governmental
request, (b)&nbsp;enforce applicable terms of use, including investigation of
potential violations thereof, (c)&nbsp;detect, prevent, or otherwise address
illegal or suspected illegal activities, security or technical issues,
(d)&nbsp;protect against harm to the rights, property or safety of our company,
our users, our employees, or others; or (e)&nbsp;to maintain and protect the
security and integrity of our Website or infrastructure. In such cases, we may
raise or waive any legal objection or right available to us, in our sole
discretion.</span></p>

<p><span >We may disclose
aggregated information about our users, and information that does not identify
any individual, without restriction. We also may share aggregated information
with third parties for conducting general business analysis. This information
does not contain any Personal Information and may be used to develop content
and services that we hope you and other users will find of interest and to
target content and advertising.</span></p>

<p><b><span >Financial
Information</span></b></p>

<p><span >Financial
information (including Personal Information) that you have provided to us will
only be shared with our third party processors in order to initiate and
complete any orders placed on your account. All credit card transactions and
such are processed with industry standard encryption through third party
processors who only use your financial information and Personal Information for
that purpose. All financial data and related Personal Information will not be
shared by us with third parties except with your authorization or when
necessary to carry out all and any transactions requested by you with the
understanding that such transactions may be subject to rules, terms, conditions
and policies of a third party. All such information provided to a third party
is subject to their terms and conditions.</span></p>

<p><b><span >Third Party
Login Functionality</span></b></p>

<p><span >We offer the convenience of using third-party services for account registration and login. This includes, but is not limited to, services like social networking, search, sharing, and other platforms provided by third parties. When you opt to register or log in using an authorized third-party service, you can use your existing credentials from that service to access your account and our website’s content.

However, it is important to note that our website upholds strict privacy standards, which may not align with the practices of these third-party services. These services operate under their own terms of service and privacy policies, which we strongly advise you to review thoroughly before utilizing their login functionality to access our website.

To ensure compliance with our standards and the requirements of our payment provider, we monitor our data regularly. This is to maintain a safe, legal, and user-friendly environment. We are committed to protecting your privacy and ensuring the integrity of the content on our website, in alignment with legal obligations and our ethical standards.</span></p>

<p><span >By using any
third party services to log in to your account or access our Website, you
permit us to access, store and use all information related to your third party
account that may be accessible to us through the third party service, including
through its application programming interface (API), pursuant to this privacy
policy.</span></p>

<p><b><span >Transfers of
Your Personal Information to Other Countries</span></b></p>

<p><span >Whenever in the
course of sharing information we transfer Personal Information to countries
outside of the European Economic Area and other regions with comprehensive data
protection laws, we will ensure that the information is transferred in
accordance with this privacy policy and as permitted by the applicable laws on
data protection.</span></p>

<p><span >By using the
Website you consent to the transfer of information that we collect about you,
including personal information, to any country in which we, members of our
corporate group (that is, entities that control, are controlled by, or are
under common control with us) or our service providers are located.</span></p>

<p><b><span >Retention of
Personal Information</span></b></p>

<p><span >We will only
retain your Personal Data for as long as necessary to fulfil the purposes we
collected it for, including for the purposes of satisfying any legal,
accounting, or reporting requirements.</span></p>

<p><span >To determine
the appropriate retention period for personal data, we consider the amount,
nature, and sensitivity of the personal data, the potential risk of harm from
unauthorized use or disclosure of your personal data, the purposes for which we
process your personal data and whether we can achieve those purposes through
other means, and the applicable legal requirements.</span></p>

<p><span >Where we no
longer need to process your Personal Data for the purposes set out in this
Privacy Policy, we will delete your Personal Data from our systems.</span></p>

<p><span >Where
permissible, we will also delete your Personal Data upon your request.
Information on how to make a deletion request can be found under “<i>Your
Rights Related to Your Personal Information</i>”.</span></p>

<p><span >If you have
questions about our data retention practices, please send us an e-mail at&nbsp;</span><a
href="mailto:support@oniichat.com"><span >support@Oniichat.com</span></a><span
>.</span></p>

<p><span >The period for
which we keep your Personal Information that is necessary for compliance and
legal enforcement purposes varies and depend on the nature of our legal
obligations and claims in the individual case.</span></p>

<p><b><span >How We
Protect the Security of Your Personal Information</span></b></p>

<p><span >We take
appropriate security measures (including physical, electronic and procedural
measures)&nbsp;to safeguard your Personal Information from unauthorized access
and disclosure. For example, only authorized employees are permitted to access
Personal Information, and they may do so only for permitted business functions.
In addition, we use encryption in the transmission of your Personal Information
between your system and ours, and we use firewalls to help prevent unauthorized
persons from gaining access to your Personal Information. Please be advised,
however, that we cannot fully eliminate security risks associated with the
storage and transmission of Personal Data.</span></p>

<p><span >You are
responsible for maintaining the secrecy of your unique password and account
information at all times. We are not responsible for circumventions of any
privacy settings or security measures contained on the Website..</span></p>

<p><b><span >Choices About
How We Collect, Use and Disclose Your Personal Information</span></b></p>

<p><span >We strive to
provide you with choices regarding the Personal Information you provide to us.</span></p>

<ul>
 <li><span >You can
     choose not to provide us with certain Personal Information, but that may
     result in you being unable to use certain features of our Website because
     such information may be required in order for you to register as a member;
     purchase products or services; participate in a contest, promotion,
     survey, or sweepstakes; ask a question; or initiate other transactions on
     our Website.</span></li>
 <li><span >You can set
     your browser to refuse all or some browser cookies, or to alert you when
     cookies are being sent. To learn how you can manage your Flash cookie
     settings, visit the Flash player settings page on Adobe’s website. If you
     disable or refuse cookies, please note that some parts of the Website may
     then be inaccessible or not function properly.</span></li>
 <li><span >When you
     register on our Website, you may be given a choice as to whether you want
     to receive email messages, newsletters or both about product updates,
     improvements, special offers, or containing special distributions of
     content by us. If you no longer want to receive commercial or promotional
     emails or newsletters from us, you will need to avail yourself of the
     unsubscribe mechanism set out in the applicable communication. It may take
     up to seven days for us to process an opt-out request. We may send you
     other types of transactional and relationship e-mail communications, such
     as service announcements, administrative notices, and surveys, without
     offering you the opportunity to opt out of receiving them. Please note
     that opting out of receipt of promotional email communications will only
     affect future activities or communications from us. If we have already
     provided your information to a third party before you have changed your
     preferences or updated your information, you may have to change you
     preferences directly with that third party.</span></li>
 <li><span >Users have the option to opt out of interest-based advertising on mobile devices. This can be done by activating the “Limit Ad Tracking” or a similar feature available on your mobile device. When activated, this feature sends a signal to us and third-party advertisers indicating that your data should not be used for interest-based advertising purposes.</span></li>
 <li><span >If you choose to submit Personal Information to our platform, you retain the right to delete your account at any time. Upon account deletion, all Personal Information and associated data, including user profile information, shared data, and any content linked to your account, will become inaccessible to you.
Please note that deleting your account leads to permanent removal of all associated data. Should you decide to use our services in the future, a new account registration will be required, as previously provided data and account settings will not be retrievable.</span></li>
</ul>

<p><b><span >Your Rights
Related to Your Personal Information</span></b></p>

<p><span >In accordance with applicable local laws, you possess certain rights concerning the Personal Information we collect, use, and disclose. These rights include:</span></p>

<ul>
 <li><span >to request information about the Personal Information we hold on you, including details on how your Personal Information is processed and utilized.</span></li>
 <li><span >to rectify
     inaccurate Personal Information concerning you (right to data
     rectification);</span></li>
 <li><span >to
     delete/erase your Personal Information (right to erasure/deletion, “right
     to be forgotten”);</span></li>
 <li><span >to receive
     the Personal Information provided by you in a structured, commonly used
     and machine-readable format and to transmit those Personal Information to
     another data controller (right to data portability)</span></li>
 <li><span >to object to
     the use of your Personal Information where such use is based on our
     legitimate interests or on public interests (right to object); and</span></li>
 <li><span >in some
     cases, to restrict our use of your Personal Information (right to
     restriction of processing).</span></li>
</ul>

<p><span >If we ask for
your consent to use your Personal Information, you can withdraw your consent at
any time. Note that in case of a withdrawal of your consent you may no longer
able to use several functionalities of our Website and our services.</span></p>

<p><span >You may, at any
time, send us an e-mail at&nbsp;</span><a href="mailto:support@oniichat.com"><span
>support@Oniichat.com</span></a><span
>&nbsp;to exercise your above rights in
accordance with the applicable legal requirements and limitations. If you are
located in the European Economic Area, you have a right to lodge a complaint
with your local data protection authority.</span></p>

<p><span >Note that some
requests to delete certain Personal Information will require the deletion of
your user account as the provision of user accounts are inextricable linked to
the use of certain Personal Information (<i>e.g.</i>, your e-mail address).
Also note that it is possible that we require additional information from you
in order to verify your authorization to make the request and to honor your
request.</span></p>

<p><b><span >Changes to
Our Privacy Policy</span></b></p>

<p><span >We may modify
or revise our privacy policy from time to time. Although we may attempt to
notify you when major changes are made to this privacy policy, you are expected
to periodically review the most up-to-date version found at&nbsp;</span><a
href="https://www.Oniichat.com"><span >https://www.Oniichat.com</span></a><span
>&nbsp;so you are aware of any changes,
as they are binding on you.</span></p>

<p><span >If we change
anything in our privacy policy, the date of change will be reflected in the
“last modified date”. You agree that you will periodically review this privacy
policy and refresh the page when doing so. You agree to note the date of the
last revision to our privacy policy. If the “last modified” date is unchanged
from the last time you reviewed our privacy policy, then it is unchanged. On
the other hand, if the date has changed, then there have been changes, and you
agree to re-review our privacy policy, and you agree to the new ones. By
continuing to use the Website subsequent to us making available an amended version
of our privacy policy in a way that you can easily take notice of it, you
thereby consent to such amendment.</span></p>

<p><b><span >Enforcement;
Cooperation</span></b></p>

<p><span >We regularly
review our compliance with this privacy policy. Please feel free to direct any
questions or concerns regarding this privacy policy or our treatment of
Personal Information by contacting us through this Website at&nbsp;</span><a
href="mailto:support@oniichat.com"><span >support@Oniichat.com</span></a><span
>. When we receive a formal written
complaint, it is our policy to contact the complaining party regarding his or
her concerns. We will cooperate with the appropriate regulatory authorities,
including local data protection authorities, to resolve any complaints
regarding the collection, use and disclosure of Personal Information that
cannot be resolved by an individual and us.</span></p>

<p><b><span >No Rights of
Third Parties</span></b></p>

<p><span >This privacy
policy does not create rights enforceable by third parties or require
disclosure of any Personal Information relating to users of the Website.</span></p>

<p><b><span >Our Policy
Towards Minors</span></b></p>

<p><span >Our Website is
not directed to persons under the age of 18 or the applicable age of majority
in the jurisdiction from which the Website is accessed and we do not knowingly
collect Personal Information from minors. If you become aware that your child
has provided us with Personal Information, please contact us at&nbsp;</span><a
href="mailto:support@oniichat.com"><span >support@Oniichat.com</span></a><span
>. If we become aware that a minor has
provided us with Personal Information, we take steps to remove such information
and terminate that person’s account.</span></p>

<p><b><span >No Error
Free Performance</span></b></p>

<p><span >We do not
guarantee error-free performance under this privacy policy. We will use
reasonable efforts to comply with this privacy policy and will take prompt
corrective action when we learn of any failure to comply with our privacy
policy. We shall not be liable for any incidental, consequential or punitive
damages relating to this privacy policy.</span></p>

<p><b><span >Contact
Information</span></b></p>

<p><span >If you have any
questions about this privacy policy or our information-handling practices,
please contact us at&nbsp;</span><a href="mailto:support@Oniichat.com"><span
>support@Oniichat.com</span></a><span
>.</span></p>

</div>
   <Footer/>
    </div>
</div>
</div>
  );
};

export default PrivacyPolicy;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { functions, httpsCallable } from '../firebase'; // Adjust the path to your firebase config file

const TimeFilters = ['24h', '7d', 'All time'];

const ServiceDetails = React.memo(({ service }) => {
  return (
    <div style={{ marginTop: 20, maxHeight: '200px', overflowY: 'auto' }}>
      <h3>Details for {service.name}</h3>
      <ul>
        {Object.entries(service.items).map(([itemName, amount], idx) => (
          <li key={idx}>{itemName}: {amount} gems</li>
        ))}
      </ul>
    </div>
  );
});
const Dashboard = () => {
  const [gemData, setGemData] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('24h');
  const [error, setError] = useState('');
  const fetchGemData = useCallback(async (timeFilter) => {
    setLoading(true);
    setError('');
   // Initialize Firebase Functions
  
    try {
      const aggregateGemData = httpsCallable(functions, 'aggregateGemData');
      const response = await aggregateGemData({ timeFilter });
  
      if (response.data) {
        setGemData(response.data);
        console.log("contentData", response.data);
      } else {
        throw new Error('No data returned from the function');
      }
    } catch (error) {
      console.error('Error fetching gem data:', error);
      setError('Failed to fetch gem data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchGemData(selectedTimeFilter);
  }, [selectedTimeFilter, fetchGemData]);

  const handlePieClick = useCallback((data, index) => {
    setSelectedService(gemData[index]);
  }, [gemData]);

  const handleTimeFilterChange = useCallback((e) => {
    setSelectedTimeFilter(e.target.value);
  }, []);

  const COLORS = useMemo(() => ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'], []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
<select onChange={handleTimeFilterChange} value={selectedTimeFilter}>
  {TimeFilters.map((filter) => (
    <option key={filter} value={filter}>{filter}</option>
  ))}
</select>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            dataKey="amount"
            nameKey="name"
            data={gemData}
            cx="50%"
            cy="50%"
            outerRadius={100}
            label={(entry) => `${entry.name}: ${entry.amount}`}
            onClick={handlePieClick}
          >
            {gemData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
      {selectedService && <ServiceDetails service={selectedService} />}
    </div>
  );
};

export default Dashboard;
